<template>
  <CRow class="justify-content-center m-0 mt-3">
    <CCol sm="12">
      <CTabs variant="tabs" :active-tab="0">
        <CTab :title="`BL`">
          <CCard class="card-border pt-3">
            <CRow class="m-0">
              <CCol sm="12" lg="4">
                <div class="form-group form-row">
                  <label class="text-right col-sm-12 col-lg-auto col-form-label-sm mb-0">{{`${$t('label.totalWeight')} (KGM)`}}</label>
                  <div class="input-group col-sm-12 col-lg-6 input-group-sm">
                    <money
                      size="sm"
                      v-bind="measure"
                      v-model="TotalBl.Weigth"
                      class="form-control"
                      disabled
                    >
                    </money>
                  </div>
                </div>
              </CCol>
              <CCol sm="12" lg="4">
                <div class="form-group form-row">
                  <label class="text-right col-sm-12 col-lg-auto col-form-label-sm mb-0">{{`${$t('label.TotalVolume')} (M³)`}}</label>
                  <div class="input-group col-sm-12 col-lg-6 input-group-sm mb-0">
                    <money
                      size="sm"
                      v-bind="measure" 
                      v-model="TotalBl.Volumen"
                      class="form-control"
                      disabled
                    >
                    </money>
                  </div>
                </div>
              </CCol>
              <CCol sm="12" lg="4">
                <div class="form-group form-row">
                  <label class="text-right col-sm-12 col-lg-6 col-form-label-sm mb-0">{{`${$t('label.totalQuantity')}`}}</label>
                  <div class="input-group col-sm-12 col-lg-6 input-group-sm mb-0">
                    <money
                      size="sm"
                      v-bind="measure" 
                      v-model="TotalBl.Quantity"
                      class="form-control"
                      disabled
                    >
                    </money>
                  </div>
                </div>
              </CCol>
            </CRow>
          </CCard>   
        </CTab>
      </CTabs>
    </CCol>
    <CCol sm="12">
      <dataTableExtended
        class="align-center-row-datatable"
        :items="computedListPackaging"
        :fields="packagingfields"
        :items-per-page="5"
        column-filter
        pagination
        :table-filter="tableText.tableFilterText"
        :items-per-page-select="tableText.itemsPerPageText"
        :noItemsView="tableText.noItemsViewText"
        sorter
      >
        <template #Status="{ item }">
          <td class="text-center">
            <CBadge :color="getBadge(item.Status)">
              {{ $t('label.'+item.Status) }}
            </CBadge>
          </td>
        </template>
      </dataTableExtended>
    </CCol>
  </CRow>
</template>
<script>
import ModalMixin from '@/_mixins/modal';
import GeneralMixin from '@/_mixins/general';
import GeneralCargoBlValidations from "@/_validations/planificacion-estiba/generalCargoBlValidations";
import UpperCase from '@/_validations/uppercase-directive';
import { Money } from "v-money";
import { NumberFormater } from "@/_helpers/funciones";

function data() {
  return {
    FgPackaging: false,
    TotalBl:{
      Weigth: 0,
      Volumen: 0,
      Quantity: 0,
    },
    Packaging: {
      Id: '',
      PackagingId: '',
      PackagingNameEn: '',
      PackagingNameEs: '',
      Weigth: 0, 
      Quantity: 0,
      CertifiedQuantity: 0,
      Volumen: 0,
    },
    measure: {
      decimal: ",",
      thousands: ".",
      prefix: "",
      suffix: "",
      precision: 2,
      masked: false,
      maxlength: 18,
    },
  };
}

//computed
function computedListPackaging() {
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  let List = this.ContainerItem?.DetailJson ?? [];
  return List.map((item, index) => {
    return {
      ...item,
      Nro: index+1,
      PackagingName: item.PackagingNameEs || item.PackagingNameEn ? item['PackagingName'+_lang] ?? '' : this.$t('label.BreakBulk'),
      ComputedQuantityPlanning: NumberFormater.formatNumber(item.QuantityPlanning, 2),
      Quantity: item.Quantity ?? 0,
      ComputedQuantity: NumberFormater.formatNumber(item.Quantity, 2),
      Weigth: NumberFormater.formatNumber(item.Weigth, 2),
      ComputedVolume: NumberFormater.formatNumber(item.Volumen, 2),
      Serial: item.Serial ?? '',
      SquareMeters: NumberFormater.formatNumber(item.SquareMeters, 2),
    }
  });
}

function packagingfields(){
  return [
    { key: 'Nro', label: '#', _style: 'width:3%; text-align:left', _classes: 'text-center', filter: false },
    { key: 'PackagingName', label: this.$t('label.packaging'),  _classes: 'text-uppercase text-center', _style: 'width:15%;', sorter: true, filter: true},
    { key: 'Serial', label: this.$t('label.serial'),  _classes: 'text-uppercase text-center', _style: 'width:15%;', sorter: true, filter: true},
    { key: 'ComputedQuantity', label: this.$t('label.ReceivedQuantity'), _classes: 'text-uppercase text-center', _style: 'width:18%;', sorter: true, filter: true},
    { key: 'Weigth', label: `${this.$t('label.weight')} (KGM)`, _style:'width:15%;', _classes: 'text-uppercase text-center', sorter: true, filter: true},
    { key: 'ComputedVolume', label: `${this.$t('label.volume')} (M³)`, _style:'width:15%;', _classes:"center-cell text-center", sorter: true, filter: true},
    { key: 'SquareMeters', label: 'M²',  _classes: 'text-uppercase text-center', _style: 'width:15%;', sorter: true, filter: true},
  ];
}

export default {
  name: 'packaging-tab',
  components: {
      Money
  },
  data,
  props: {
    ContainerItem: Object,
    Tab: Boolean,
  },
  mixins: [
    ModalMixin, 
    GeneralMixin
  ],
  directives: UpperCase,
  validations() {
    return GeneralCargoBlValidations(null, null, null, this.FgPackaging, this.Packaging.CertifiedQuantity);
  },
  methods: {
  },
  computed:{
    computedListPackaging,
    packagingfields,
  },
  watch: {
    Tab: function (Newval) {
      if (Newval) {
        this.TotalBl.Weigth = NumberFormater.setNum(this.ContainerItem.Weigth);
        this.TotalBl.Volumen =  NumberFormater.setNum(this.ContainerItem.Volumen);
        this.TotalBl.Quantity =  NumberFormater.setNum(this.ContainerItem.Quantity);
      }
    },
  }
};
</script>
<template>
  <div>
    <CCol sm="12" lg="4">
      <div class="col-sm-12 col-lg-auto d-flex justify-content-end mb-3">
        <div class="pr-2">{{ `${$t('label.automaticCalculation')} (M³)` }}</div>
        <CSwitch
          color="watch"
          variant="3d"
          type="checkbox"
          :checked.sync="vForm.Packaging.FgVolumen.$model"
          :disabled="isEdit"
          @update:checked="changeFgVolumen()"
        />
      </div>
    </CCol>
    <CRow class="m-0">
      <CCol sm="12" lg="4">
        <CSelect
          size="sm"
          v-uppercase
          :label="$t('label.packaging')"
          :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
          :options="PackagingOptions"
          addLabelClasses="required text-right"
          v-model="vForm.Packaging.BlCargoMasterDetailId.$model"
          :is-valid="hasError(vForm.Packaging.BlCargoMasterDetailId)"
          :invalid-feedback="errorMessage(vForm.Packaging.BlCargoMasterDetailId)"
          @change="onChangeBlCargoMasterDetailId($event.target.value)"
        />
      </CCol>
      <CCol sm="12" lg="4">
        <CSelect
          v-if="form?.Packaging?.FgSerial"
          size="sm"
          v-uppercase
          :label="$t('label.serial')"
          :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
          :options="SerialOptions"
          addLabelClasses="required text-right"
          v-model="vForm.Packaging.BlCargoMasterDetailSerialId.$model"
          :is-valid="hasError(vForm.Packaging.BlCargoMasterDetailSerialId)"
          :invalid-feedback="errorMessage(vForm.Packaging.BlCargoMasterDetailSerialId)"
          @change="onChangeBlCargoMasterDetailSerialId($event.target.value)"
        />
        <CInput
          v-else
          v-uppercase
          size="sm"
          :placeholder="$t('label.serial')"
          addLabelClasses="text-right"
          :label="$t('label.serial')"
          :horizontal="{label: 'col-sm-12 col-lg-4', input: 'col-sm-12 col-lg-8'}"
          value="N/A"
          :is-valid="hasError(vForm.Packaging.BlCargoMasterDetailSerialId)"
          :invalid-feedback="errorMessage(vForm.Packaging.BlCargoMasterDetailSerialId)"
          disabled
        />
      </CCol>
      <!-- <CCol sm="12" lg="4">
        <div class="form-group form-row">
          <label class="text-right col-sm-12 col-lg-6 m-0 required col-form-label-sm">{{`${$t('label.WeightOrigin')} (KGM)`}}</label>
          <div class="input-group col-sm-12 col-lg-6 input-group-sm">
            <money
              size="sm"
              v-bind="QuantityMeasure" 
              :class="!vForm.Packaging.WeightOrigin.$dirty ? 'form-control' : (vForm.Packaging.WeightOrigin.$error ? 'form-control is-invalid' : 'form-control is-valid')"
              v-model="vForm.Packaging.WeightOrigin.$model"
              disabled
            >
            </money>
            <div class="invalid-feedback" v-if="vForm.Packaging.WeightOrigin.$error&&!CleanInformation">
              {{ errorMessage(vForm.Packaging.WeightOrigin) }}
            </div>
          </div>
        </div>
      </CCol> -->
      <CCol sm="12" lg="4">
        <div class="form-group form-row">
          <label class="text-right col-sm-12 col-lg-4 m-0 required col-form-label-sm">{{`${$t('label.quantity')}`}}</label>
          <div class="input-group col-sm-12 col-lg-8 input-group-sm">
            <money
              size="sm"
              v-bind="QuantityMeasure" 
              :class="!vForm.Packaging.Quantity.$dirty ? 'form-control' : (vForm.Packaging.Quantity.$error ? 'form-control is-invalid' : 'form-control is-valid')"
              v-model="vForm.Packaging.Quantity.$model"
              :disabled="!form.Packaging.BlCargoMasterDetailSerialId || (form.Packaging.FgSerial && !form.Packaging.BlCargoMasterDetailSerialId)"
            >
            </money>
            <div class="invalid-feedback" v-if="vForm.Packaging.Quantity.$error&&!CleanInformation">
              {{ errorMessage(vForm.Packaging.Quantity) }}
            </div>
          </div>
        </div>
      </CCol>
      <CCol sm="12" lg="4">
        <div class="form-group form-row">
          <label class="text-right col-sm-12 col-lg-4 m-0 col-form-label-sm">{{`${$t('label.length')} (M)`}}</label>
          <div class="input-group col-sm-12 col-lg-8 input-group-sm">
            <money
              size="sm"
              v-bind="measure" 
              :class="!vForm.Packaging.Length.$dirty ? 'form-control' : (vForm.Packaging.Length.$error ? 'form-control is-invalid' : 'form-control is-valid')"
              v-model="vForm.Packaging.Length.$model"
              addLabelClasses="text-right"
              :disabled="!form.Packaging.FgVolumen"
            >
            </money>
            <div class="invalid-feedback" v-if="vForm.Packaging.Length.$error&&!CleanInformation">
              {{ errorMessage(vForm.Packaging.Length) }}
            </div>
          </div>
        </div>
      </CCol>
      <CCol sm="12" lg="4">
        <div class="form-group form-row">
          <label class="text-right col-sm-12 col-lg-4 m-0 col-form-label-sm">{{`${$t('label.width')} (M)`}}</label>
          <div class="input-group col-sm-12 col-lg-8 input-group-sm">
            <money
              size="sm"
              v-bind="measure" 
              :class="!vForm.Packaging.Width.$dirty ? 'form-control' : (vForm.Packaging.Width.$error ? 'form-control is-invalid' : 'form-control is-valid')"
              v-model="vForm.Packaging.Width.$model"
              addLabelClasses="text-right"
              :disabled="!form.Packaging.FgVolumen"
            >
            </money>
            <div class="invalid-feedback" v-if="vForm.Packaging.Width.$error&&!CleanInformation">
              {{ errorMessage(vForm.Packaging.Width) }}
            </div>
          </div>
        </div>
      </CCol>
      <CCol sm="12" lg="4">
        <div class="form-group form-row">
          <label class="text-right col-sm-12 col-lg-4 m-0 col-form-label-sm">{{`${$t('label.height')} (M)`}}</label>
          <div class="input-group col-sm-12 col-lg-8 input-group-sm">
            <money
              size="sm"
              v-bind="measure" 
              :class="!vForm.Packaging.Height.$dirty ? 'form-control' : (vForm.Packaging.Height.$error ? 'form-control is-invalid' : 'form-control is-valid')"
              v-model="vForm.Packaging.Height.$model"
              addLabelClasses="text-right"
              :disabled="!form.Packaging.FgVolumen"
            >
            </money>
            <div class="invalid-feedback" v-if="vForm.Packaging.Height.$error&&!CleanInformation">
              {{ errorMessage(vForm.Packaging.Height) }}
            </div>
          </div>
        </div>
      </CCol>
      <CCol sm="12" lg="4" >
        <div class="form-group form-row">
          <label class="text-right col-sm-12 col-lg-4 m-0 col-form-label-sm">{{`${$t('label.volume')} (M³)`}}</label>
          <div class="input-group col-sm-12 col-lg-8 input-group-sm">
            <money
              v-if="!form.Packaging.FgVolumen"
              size="sm"
              v-bind="measure"
              :class="!vForm.Packaging.Volumen.$dirty ? 'form-control' : (vForm.Packaging.Volumen.$error ? 'form-control is-invalid' : 'form-control is-valid')"
              v-model="form.Packaging.Volumen"
              addLabelClasses="required text-right"
            >
            </money>
            <money
              v-else
              size="sm"
              v-bind="measure"
              :class="!vForm.Packaging.Length.$dirty || !vForm.Packaging.Width.$dirty || !vForm.Packaging.Height.$dirty ? 'form-control' : 'form-control is-valid'"
              v-model="Meters.Volumen"
              addLabelClasses="required text-right"
              disabled
            >
            </money>
          </div>
        </div>
      </CCol>
      <CCol sm="12" class="mb-3">
        <div class="text-right">
          <CButton
            color="add"
            size="sm"
            class="mr-1"
            v-c-tooltip="{content: $t('label.add'), placement: 'top-end'}" 
            :disabled="isSubmit"
            @click="ValidateSubmit()"
          >
            <CIcon name="checkAlt"/>
          </CButton>
          <CButton
            color="wipe"
            class="justify-content-end"
            size="sm"
            v-c-tooltip="{content: $t('label.cleanInformation'), placement: 'top-end'}" 
            @click="resetForm(false)"
          >
            <CIcon name="cil-brush-alt" />
          </CButton>
        </div>
      </CCol>
    </CRow>
    <CCol sm="12">
      <dataTableExtended
        class="align-center-row-datatable"
        :items="computedDetailList"
        :fields="detailFields"
        :items-per-page="5"
        column-filter
        pagination
        :table-filter="tableText.tableFilterText"
        :items-per-page-select="tableText.itemsPerPageText"
        :noItemsView="tableText.noItemsViewText"
        sorter
      >
        <template #Quantity="{ item }">
          <td class="text-center">
            {{ formatNumber(item.Quantity, 2) }}
          </td>
        </template>
        <template #Volumen="{ item }">
          <td class="text-center">
            {{ formatNumber(item.Volumen, 2) }}
          </td>
        </template>
        <template #options="{ item }">
          <td class="text-center">
            <CButton 
              color="edit"
              square
              size="sm"
              class="mr-1"
              v-c-tooltip="{
                content: `${$t('label.edit')} ${$t('label.packaging')}`,
                placement: 'top-start'
              }"
              @click="editPackaging(item)"
            >
              <CIcon name="pencil" />
            </CButton>
            <CButton 
              color="watch"
              size="sm"
              class="mr-1"
              v-c-tooltip="{
                content: $t('label.Implement'),
                placement: 'top-start'
              }"
              @click="implementAdd(item)"
            >
              <CIcon name='cil-list' />
            </CButton>
            <CButton
              color="wipe"
              size="sm"
              v-c-tooltip="{
                content: $t('label.delete')+$t('label.packaging'),
                placement: 'top-start',
              }"
              @click="DeletePackaging(item)"
            >
              <CIcon name="cil-trash" />
            </CButton>
          </td>
        </template>
      </dataTableExtended>
      <div class="text-invalid-feedback text-center" v-if="vForm.DetailJson.$error">
        {{ $t('validation.RequiredDetail') }}
      </div>
    </CCol>
    <ModalImplement
      :form="form"
      :vForm="vForm"
      :PackagingItem="PackagingItem"
      :modal="ModalImplement"
      @submit="SubmitPackaging"
      @updateImplementOnly="updateImplementOnly"
      @updateImplementJson="updateImplementJson"
      @deleteImplement="deleteImplement"
      @close="ModalImplement = false"
    />
  </div>
</template>
<script>
import { mapState } from 'vuex';
import ModalMixin from '@/_mixins/modal';
import GeneralMixin from '@/_mixins/general';
import UpperCase from '@/_validations/uppercase-directive';
import ModalImplement from './modal/implement';
import { NumberFormater } from "@/_helpers/funciones";

function data() {
  return {
    CleanInformation: false,
    ModalImplement: false,
    PackagingList: [],
    SerialList: [],
    PackagingItem: {},
    measure: {
      decimal: ",",
      thousands: ".",
      prefix: "",
      suffix: "",
      precision: 2,
      masked: false,
      maxlength: 18,
    },
    QuantityMeasure: {
      decimal: ",",
      thousands: ".",
      prefix: "",
      suffix: "",
      precision: 0,
      masked: false,
      maxlength: 6,
    },
  };
}

//-----------------------   Method    ------------------------
async function getService() {
  this.$store.state.yardManagement.loading = true;
  let requests = [
    this.$http.get('YardWCargoPackagingReception-by-BlCargoId', { BlCargoId: this.dataBl.BlCargoId, YardId: this.yardData.YardId }),
  ]
  await Promise.all(requests)
    .then((responses) => {
      this.PackagingList = responses[0].data.data ?? [];
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .finally(() => {
      this.$store.state.yardManagement.loading = false;
    });
}

function ValidateSubmit() {
  try {
    this.isSubmit = true;
    this.vForm.Packaging.$touch();
    if (this.vForm.Packaging.$error){
      throw this.$t('label.errorsPleaseCheck');
    }
    this.PackagingItem = { id: '', ImplementJson: [...this.form.Packaging.ImplementJson] };
    this.ModalImplement = true;
    this.isSubmit = false;
  } catch (error) {
    this.isSubmit = false;
    this.notifyError({text: error});
  }
  
}

async function SubmitPackaging() {
  try {
    this.$store.state.yardManagement.loading = true;
    this.isSubmit = true;
    this.vForm.Packaging.$touch();
    if (this.vForm.Packaging.$error){
      throw this.$t('label.errorsPleaseCheck');
    }

    if (this.form.Packaging.Id=='') {
      let PackagingData = this.PackagingList.find(e => e.BlCargoMasterDetailId == this.form.Packaging.BlCargoMasterDetailId);
      this.form.DetailJson.push({
        IdX: this.form.DetailJson.length > 0 ? this.form.DetailJson[this.form.DetailJson.length - 1].IdX + 1 : 1,
        BlCargoMasterDetailId: this.form.Packaging.BlCargoMasterDetailId,
        PackagingNameEn: PackagingData?.PackagingNameEn ?? '',
        PackagingNameEs: PackagingData?.PackagingNameEs ?? '',
        Quantity: NumberFormater.setNum(this.form.Packaging.Quantity),
        Length: NumberFormater.setNum(this.form.Packaging.Length),
        Width: NumberFormater.setNum(this.form.Packaging.Width),
        Height: NumberFormater.setNum(this.form.Packaging.Height),
        Volumen: !this.form.Packaging.FgVolumen ? NumberFormater.setNum(this.form.Packaging.Volumen) : this.Meters.Volumen,
        ImplementJson: !this.form.Packaging.FgSerial ? this.PackagingItem.ImplementJson : [],
        SerialJson: this.form.Packaging.FgSerial ? [{
          BlCargoMasterDetailSerialId: this.form.Packaging.BlCargoMasterDetailSerialId,
          Serial: PackagingData?.SerialJson?.find(e => e.BlCargoMasterDetailSerialId == this.form.Packaging.BlCargoMasterDetailSerialId)?.Serial ?? '',
          Quantity: 1,
          Length: NumberFormater.setNum(this.form.Packaging.Length),
          Width: NumberFormater.setNum(this.form.Packaging.Width),
          Height: NumberFormater.setNum(this.form.Packaging.Height),
          Volumen: !this.form.Packaging.FgVolumen ? NumberFormater.setNum(this.form.Packaging.Volumen) : this.Meters.Volumen,
          ImplementJson: this.PackagingItem.ImplementJson,
        }] : [],
      })
    }else {
      this.form.DetailJson = this.form.DetailJson.map(e => {
        if (e.IdX === this.form.Packaging.Id) {
          return {
            ...e,
            //BlCargoMasterDetailId: this.form.Packaging.BlCargoMasterDetailId,
            Quantity: NumberFormater.setNum(this.form.Packaging.Quantity),
            Length: NumberFormater.setNum(this.form.Packaging.Length),
            Width: NumberFormater.setNum(this.form.Packaging.Width),
            Height: NumberFormater.setNum(this.form.Packaging.Height),
            Volumen: !this.form.Packaging.FgVolumen ? NumberFormater.setNum(this.form.Packaging.Volumen) : this.Meters.Volumen,
            ImplementJson: !this.form.Packaging.FgSerial ? this.PackagingItem.ImplementJson : [],
            SerialJson: this.form.Packaging.FgSerial ? [{
              ...e.SerialJson?.[0],
              //BlCargoMasterDetailSerialId: this.form.Packaging.BlCargoMasterDetailSerialId,
              Quantity: 1,
              Length: NumberFormater.setNum(this.form.Packaging.Length),
              Width: NumberFormater.setNum(this.form.Packaging.Width),
              Height: NumberFormater.setNum(this.form.Packaging.Height),
              Volumen:!this.form.Packaging.FgVolumen ? NumberFormater.setNum(this.form.Packaging.Volumen) : this.Meters.Volumen,
              ImplementJson: this.PackagingItem.ImplementJson,
            }] : [],
          }
        }else{
          return e
        }
      })
    }
    await this.resetForm()
    this.isSubmit = false;
    this.$store.state.yardManagement.loading = false;
  } catch (error) {
    this.isSubmit = false;
    this.$store.state.yardManagement.loading = false;
    this.notifyError({text: error});
  }
}

function updateImplementOnly() {
  this.form.DetailJson = this.form.DetailJson.map(e => {
    if (e.IdX == this.PackagingItem.id) {
      return {
        ...e,
        ImplementJson: e.SerialJson.length === 0 ? this.PackagingItem.ImplementJson : [],
        SerialJson: e.SerialJson.length > 0 ? [{
          ...e.SerialJson[0],
          ImplementJson: this.PackagingItem.ImplementJson,
        }] : [],
      }
    } else {
      return e
    }
  })
}

function updateImplementJson(item) {
  if (this.form.Implement.id == '') {
    this.PackagingItem.ImplementJson.push(item);
  }else {
    this.PackagingItem.ImplementJson = this.PackagingItem.ImplementJson.map(e => {
      if (e.IdX === this.form.Implement.Id) {
        return {
          ...e,
          ...item,
        }
      }else {
        return e
      }
    })
  }
}

function deleteImplement(item) {
  this.$swal
    .fire(this.alertProperties({
        text: `${this.$t('label.deleteQuestion')}?`,
    }))
    .then((result) => {
    if (result.isConfirmed) {
      this.PackagingItem.ImplementJson = this.PackagingItem.ImplementJson.filter(e => e.IdX != item.IdX);
    }
  });
}

function changeFgVolumen() {
  this.form.Packaging.Length = 0;
  this.form.Packaging.Width = 0;
  this.form.Packaging.Height = 0;
  this.form.Packaging.Volumen = 0;
}

function onChangeBlCargoMasterDetailId(id) {
  this.form.Packaging.BlCargoMasterDetailId = id;
  this.form.Packaging.BlCargoMasterDetailSerialId = '';
  this.form.Packaging.Quantity = 0;
  if (this.form.Packaging.BlCargoMasterDetailId) {
    let PackagingData = this.PackagingList.find(e => e.BlCargoMasterDetailId == this.form.Packaging.BlCargoMasterDetailId);
    this.SerialList = PackagingData?.SerialJson ?? [];
    this.form.Packaging.FgSerial = this.SerialList.length != 0;
    //this.form.Packaging.WeightOrigin = this.SerialList.length != 0 ? 0 : (PackagingData?.Weigth ?? 0);
  }else{
    this.form.Packaging.FgSerial = false;
    //this.form.Packaging.WeightOrigin = 0;
    this.SerialList = [];
  }
}

function onChangeBlCargoMasterDetailSerialId(id) {
  this.form.Packaging.BlCargoMasterDetailSerialId = id;
  //this.form.Packaging.WeightOrigin = 0
  if (this.form.Packaging.BlCargoMasterDetailSerialId) {
    this.form.Packaging.Quantity = 1;
    //let SerialList = this.SerialJson.find(e => e.BlCargoMasterDetailSerialId == this.form.Packaging.BlCargoMasterDetailSerialId);
    //this.form.Packaging.WeightOrigin = SerialList?.Weigth ?? 0;
  }else{
    this.form.Packaging.Quantity = 0;
  }
}

function editPackaging(item) {
  this.form.Packaging = {
    Id: item.IdX,
    FgVolumen: item.Length > 0 || item.Width > 0 || item.Height > 0,
    FgSerial: item.BlCargoMasterDetailSerialId ? true : false,
    BlCargoMasterDetailId: item.BlCargoMasterDetailId,
    BlCargoMasterDetailSerialId: item.SerialJson.length > 0 ? item?.SerialJson[0]?.BlCargoMasterDetailSerialId ?? '' : '',
    //WeightOrigin: 0,
    Quantity: item.Quantity,
    Length: item.Length,
    Width: item.Width,
    Height: item.Height,
    Volumen: item.Volumen,
    ImplementJson: item.SerialJson.length == 0 ? item?.ImplementJson ?? [] :  item.SerialJson[0]?.ImplementJson ?? [],
  };
  let PackagingData = this.PackagingList.find(e => e.BlCargoMasterDetailId == this.form.Packaging.BlCargoMasterDetailId);
  this.SerialList = PackagingData?.SerialJson ?? [];
  this.form.Packaging.FgSerial = this.SerialList.length != 0;
  this.vForm.Packaging.$touch();
}

function implementAdd(item) {
  this.PackagingItem = { 
    id: item.IdX,
    ImplementJson: item.SerialJson.length == 0 ? item?.ImplementJson ?? [] :  item.SerialJson[0]?.ImplementJson ?? [],
  }
  this.ModalImplement = true;
}

function DeletePackaging(item) {
  this.$swal
    .fire(this.alertProperties({
        text: `${this.$t('label.deleteQuestion')}?`,
    }))
    .then((result) => {
      if (result.isConfirmed) {
        if (item.BlCargoMasterDetailId) {
          this.form.DetailJson = this.form.DetailJson.map(Packaging => {
            if (Packaging.BlCargoMasterDetailId == item.BlCargoMasterDetailId) {
              return {
                ...Packaging,
                Status: 0,
              };
            } else {
              return Packaging;
            }
          });
        }else{
          this.form.DetailJson = this.form.DetailJson.filter(e => e.IdX != item.IdX);
        }
        this.vForm.DetailJson.$touch();
      }
    });
}

function validateDateRange(date) {
  return date < this.previousDate || date > this.laterDate;
}

function formatNumber(number, decimal) {
  return NumberFormater.formatNumber(number, decimal);
}

function resetForm(All) {
  this.CleanInformation = true;
  this.form.Packaging = {
    Id: '',
    FgVolumen: false,
    FgSerial: false,
    BlCargoMasterDetailId: '',
    BlCargoMasterDetailSerialId: '',
    WeightOrigin: 0,
    Quantity: 0,
    Length: 0,
    Width: 0,
    Height: 0,
    Volumen: 0,
    ImplementJson: [],
  };
  this.PackagingItem = {};
  if (All) {
    this.form.DetailJson = [];
    this.PackagingList = [];
    this.SerialList = [];
  }
  setTimeout(() => {
    this.vForm.Packaging.$reset();
    this.CleanInformation = false;
  }, 30);
}

//-----------------------   Computed   ------------------------
function PackagingOptions() {
  let _lang = (this.$i18n.locale)?.charAt(0)?.toUpperCase() + (this.$i18n.locale)?.slice(1);
  var chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
   this.PackagingList.map(e => {
    chart.push({
      value: e.BlCargoMasterDetailId, 
      label: e[`PackagingName${_lang}`],
    })    
  })
  return chart;
}

function SerialOptions() {
  var chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
   this.SerialList.map(e => {
    chart.push({
      value: e.BlCargoMasterDetailSerialId, 
      label: e.Serial,
    })    
  })
  return chart;
}

function Meters() {
  return {
    Volumen: NumberFormater.setNum(this.form.Packaging.Length) * NumberFormater.setNum(this.form.Packaging.Width) * NumberFormater.setNum(this.form.Packaging.Height),
  }
}

function computedDetailList() {
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  let List = this.form.DetailJson.filter(e => e.Status != 0);
  return List.map((e, index) => {
    let SerialData = e?.SerialJson?.[0] ?? {};
    return {
      ...e,
      Nro: index+1,
      PackagingName: e?.['PackagingName'+_lang] ?? '',
      Serial: SerialData?.Serial ? SerialData?.Serial : 'N/A',
      // ImdgCod: imdgData?.ImdgCode ?? 'N/A',
      // PackagingGroupCod: imdgData?.PackagingGroupCode ?? 'N/A',
      // UnNumber: imdgData?.UnNumber ?? 'N/A',
    }
  });
}

function detailFields(){
  return [
    { key: 'options', label: '', _style: 'width: 1%; min-width:125px;', sorter: false, filter: false },
    { key: 'Nro', label: '#', _style: 'min-width: 45px;', _classes: 'text-center', filter: false },
    { key: 'PackagingName', label: this.$t('label.packaging'),  _classes: 'text-uppercase text-center', _style: 'min-width: 200px;'},
    { key: 'Serial', label: this.$t('label.serial'),  _classes: 'text-uppercase text-center', _style: 'min-width: 180px;'},
    { key: 'Quantity', label: this.$t('label.quantity'), _classes: 'text-uppercase text-center', _style: 'min-width: 160px;'},
    //{ key: 'Weight', label: `${this.$t('label.weight')} (KGM)`, _style:'min-width: 180px;', _classes: 'text-uppercase text-center'},
    { key: 'Volumen', label: `${this.$t('label.volume')} (M³)`, _style:'min-width: 180px;', _classes:"center-cell text-center"},
    { key: 'ImdgCod', label: this.$t('label.imdgCode'), _classes: 'text-uppercase text-center', _style: 'min-width: 160px;' },
    { key: 'PackagingGroupCod', label: this.$t('label.PackagingGroup'), _style:'min-width: 160px;', _classes: 'text-uppercase text-center' },
    { key: 'UnNumber', label: this.$t('label.UnNumber'), _style:'min-width: 180px;', _classes:"text-center" },
  ]
}

export default {
  name: 'packagingImplement-tab',
  data,
  components: {
    ModalImplement
  },
  props: {
    form: {
      type: Object,
      required: true,
      default: () => {},
    },
    vForm: Object,
    Tab: Boolean,
    isEdit: Boolean,
    show: Boolean,
    
  },
  mixins: [
    ModalMixin, 
    GeneralMixin
  ],
  directives: UpperCase,
  methods: {
    getService,
    ValidateSubmit,
    SubmitPackaging,
    updateImplementOnly,
    updateImplementJson,
    deleteImplement,
    changeFgVolumen,
    onChangeBlCargoMasterDetailId,
    onChangeBlCargoMasterDetailSerialId,
    editPackaging,
    implementAdd,
    DeletePackaging,
    validateDateRange,
    formatNumber,
    resetForm,
  },
  computed:{
    PackagingOptions,
    SerialOptions,
    Meters,
    computedDetailList,
    detailFields,
    ...mapState({
      branch: state => state.auth.branch,
      yardData: state => state.yardManagement.yardData,
      dataBl: state => state.yardManagement.dataBl,
    })
  },
  watch: {
    show: async function (NewVal) {
      if (!NewVal) {
        this.resetForm(true);
      }
    },
    Tab: async function (NewVal) {
      if (NewVal) {
        await this.getService()
      }
    },
  }
};
</script>
<template>
  <div>
    <CModalExtended
      color="dark"
      :show.sync="showModal"
      :closeOnBackdrop="false"
      size="xl"
    >
      <template #header>
        <h5 class="modal-title">{{titleModal}}</h5>
        <button type="button" aria-label="Close" class="close" @click="closeModal">×</button>
      </template>

      <CRow class="my-1">
        <CCol sm="12">
            <p  class="text-start fw-bold mb-1">{{ `
                ${$t('label.quantityDispached')}: ${FormatNumber(this.ModuleForm.PackagingItem.Quantity, 2)} 
            ` }}</p>
            <p  class="text-start fw-bold">{{ `
                ${$t('label.selectedQuantity')}: ${FormatNumber(PositionList.reduce((accumulator, currentValue) => accumulator + (currentValue?.Quantity ? currentValue.Quantity : 0), 0), 2)} 
            ` }}</p>
            <dataTableExtended
                class="align-center-row-datatable"
                :items-per-page="5"
                :items="computedPositionsList"
                :fields="positionsfields"
                pagination
                :noItemsView="tableText.noItemsViewText"
            >
                <template #FgChecked="{ item }">
                    <td class="text-center">
                        <CInputCheckbox
                            custom
                            :checked="item.FgChecked"
                            class="checkbook-filter ml-1"
                            @update:checked="(e)=>{changeTableValues(e, item, 'FgChecked')}"
                            :disabled="isWasRequested"
                        />
                    </td>
                </template>

                <template #Quantity="{ item }">
                    <td class="text-center">
                    <div class="input-group input-group-sm col-lg-10 d-flex align-items-center justify-content-center">
                        <money
                            :disabled="!item.FgChecked || isWasRequested"
                            v-bind="measure" 
                            v-model="item.Quantity"
                            :class="( !item.Error && !item.ErrorMaxCant && !item.ErrorMinCant && !item.ErrorTotal) ? 'form-control' : ((item.Error || item.ErrorMaxCant || item.ErrorMinCant || item.ErrorTotal)? 'form-control is-invalid' : 'form-control is-valid')"
                            class="form-control"
                            maxlength= "12"
                            @input="(e)=>{changeTableValues(e, item, 'Quantity')}"
                        >
                        </money>
                        <div class="invalid-feedback" v-if="item.Error">
                            {{ $t('label.required') }}
                        </div>
                        <div class="invalid-feedback" v-if="item.ErrorMaxCant">
                            {{ `${$t('validation.maxValue')}: ${item.MaxQuantity}`}}
                        </div>
                        <div class="invalid-feedback" v-if="item.ErrorMinCant">
                            {{ `${$t('validation.minValue')}: ${0}`}}
                        </div>
                    </div>
                    </td>
                </template>
                
            </dataTableExtended>
            <p v-if="this.PositionList.some((item)=> item.ErrorTotal == true)" class="mt-2 text-danger text-center required">
                {{$t('validation.quantityDispatchedExceedsSelected')}}
            </p>
        </CCol>
      </CRow>
      <template #footer>
        <CButton
            v-if="!isWasRequested"
            square
            color="add"
            class="d-flex align-items-center"
            @click="Submit()"
        >
            <CIcon name="checkAlt"/>{{$t('button.accept')}}
        </CButton>
        <CButton
          color="wipe"
          :disabled="isSubmit"
          class="d-flex align-items-center"
          @click.stop="closeModal"
        >
          <CIcon name="x"/><span class="ml-1">{{ $t('button.exit')}}</span>
        </CButton>
      </template>
    </CModalExtended>
  </div>
</template>
<script>
import UpperCase  from '@/_validations/uppercase-directive';
import GeneralMixin from '@/_mixins/general';
import { mapState } from 'vuex';
import PictureInput from '@/components/picture-input';
import ImdgTab from './imdg-tab';
import { DateFormater, NumberFormater } from '@/_helpers/funciones';

	function data() {
        return {
            //General
            showModal: false,
            isSubmit: false,
            tabIndex:0,
            PositionList: [],
            PackagingsList: [],
            measure: {
                    decimal: ",",
                    thousands: ".",
                    prefix: "",
                    suffix: "",
                    precision: 0,
                    masked: false,
                    maxlength: 18,
            }
        };
	}

    //-----------------------   Method    ------------------------

    async function getPositionList(BlCargoMasterDetailId) {
        this.$store.state.yardManagement.loading = true;
        await this.$http
            .get('YardWCargoOpenPosition-list',{BlCargoMasterDetailId: BlCargoMasterDetailId??'',  YardId: this.$store.state.yardManagement.yardData.YardId})
                .then(async response => {
                let data =  response?.data?.data ? response.data.data : [];

                data = data.map((e)=>{
                    return {...e, MaxQuantity: e?.Quantity, Quantity: 0, FgChecked: false}
                })

                this.PositionList = data;
            })
            .catch( err => { 
                this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
            }).finally(() => {
            this.$store.state.yardManagement.loading = false;
            });
    }

    function closeModal() {
        this.$emit('CloseModal');
    }

    function handleTab(tab) {
        this.tabIndex = tab;
    }

    async function changeTableValues(event, item, name){
        try{
            this.PositionList[item.Nro-1].Error= false;
            this.PositionList[item.Nro-1][name] = event;

            if(name == 'Quantity'){

                //Validación del maximo 
                if(this.PositionList[item.Nro-1][name] > this.PositionList[item.Nro-1].MaxQuantity)
                    this.PositionList[item.Nro-1].ErrorMaxCant = true;
                else
                    this.PositionList[item.Nro-1].ErrorMaxCant = false;


                //Validación del minimo 
                if(this.PositionList[item.Nro-1][name] < 0)
                    this.PositionList[item.Nro-1].ErrorMinCant = true;
                else
                    this.PositionList[item.Nro-1].ErrorMinCant = false;


                //Verificación de que no se supere de la cantidad maxima
                if(this.PositionList[item.Nro-1][name] > 0 && this.PositionList?.reduce((accumulator, currentValue) => accumulator + ( currentValue.Quantity), 0) > NumberFormater.setNum(this.ModuleForm.PackagingItem?.Quantity??0))
                    this.PositionList?.map((e)=>{ if(e?.Quantity > 0) e.ErrorTotal= true; })
                else
                    this.PositionList?.map((e)=>{ if(e?.Quantity > 0) e.ErrorTotal= false; })
            }

            if(name == 'FgChecked'){
                this.PositionList[item.Nro-1].Quantity = 0;
                this.PositionList[item.Nro-1].ErrorMinCant = false;
                this.PositionList[item.Nro-1].ErrorMaxCant = false;
                this.PositionList[item.Nro-1].ErrorTotal = false;
            }
        }
        catch{}
    }

    function Submit() {
        try {
            let errorQuantity = false;

            this.PositionList.map(item => {
                if((item.FgChecked) && (item.Quantity == 0)){
                    item.Error = true;
                    errorQuantity = true;
                }
                else
                    item.Error = false;
            })

            if(errorQuantity) 
                throw this.$t('label.indicateQuantitYPosition');

            if(this.PositionList.some(item => item.ErrorMaxCant || item.ErrorMinCant || item.ErrorTotal))
                throw this.$t('label.errorsPleaseCheck');

            if(this.PositionList?.reduce((accumulator, currentValue) => accumulator + ( currentValue.Quantity), 0) != NumberFormater.setNum(this.ModuleForm.PackagingItem?.Quantity??0))
                throw this.$t('validation.quantityDispatchedNotMatchSelected');

            this.$emit('UpdatePackagingPositionList',{PositionList: this.PositionList, packagingData: this.ModuleForm.PackagingItem})
        }
        catch (error) {
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: error,
                type: "error"
            });
        }
    }

    function FormatNumber(number, decimal) {
        return NumberFormater.formatNumber(number, decimal);
    }

  //-----------------------   Computed   ------------------------

    function positionsfields(){
        return [
            { key: 'FgChecked', label: '', _classes: 'text-uppercase text-center', _style: 'width: 1%; min-width:45px;', sorter: false, filter: false},
            { key: 'Quantity', label: this.$t('label.quantity'),  _classes: 'text-uppercase text-center', _style: 'min-width:180px;'},
            { key: 'YardName',label: this.$t('label.ubication'), _style:'min-width: 180px;', _classes: 'text-uppercase text-center'},
            { key: 'YardAreaName', label: this.$t('label.module'), _style:'min-width: 180px;', _classes:"text-center"},
            { key: 'EventReasonName', label: this.$t('label.motive'), _style:'min-width: 180px;', _classes: 'text-uppercase text-center'},
            { key: 'ComputedInitDate', label: this.$t('label.startDate'),  _style:'min-width: 130px;', _classes:"text-center" },
            { key: 'ComputedFinishDate', label: this.$t('label.endDate'),  _style:'min-width: 130px;', _classes:"text-center" },
            { key: 'EventServiceName', label: this.$t('label.service'), _style:'min-width: 180px;', _classes: 'text-uppercase text-center'},
            { key: 'TransaLogin', label: this.$t('label.user'), _style:'min-width: 120px;', _classes: 'text-uppercase text-center' },
            
        ];
    }

    function computedPositionsList() {
        let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);

        return this.PositionList.map((item, index) => {

                return {
                    ...item,
                    Nro: index+1,
                    Quantity: item?.Quantity ?? 0,
                    YardAreaName: item?.YardAreaName ? item?.YardAreaName : '',
                    EventReasonName: _lang=='en' ? item?.EventReasonNameEn ?? '' : item?.EventReasonNameEs ?? '',
                    EventServiceName: _lang=='en' ? item?.EventServiceNameEn || '' : item?.EventServiceNameEs || '',
                    DaysInYard: _lang=='en' ? item?.DaysInYardEn || '' : item?.DaysInYardEs || '',
                    MachineCode: item?.MachineCode ? item?.MachineCode : '',
                    ComputedInitDate: item?.TransactionBegin ? DateFormater.formatDateTimeWithSlash(item?.TransactionBegin) : '',
                    ComputedFinishDate: item?.TransactionFinish ? DateFormater.formatDateTimeWithSlash(item?.TransactionFinish) : '',
                    TransaLogin: item.TransaLogin ?? '',
                    _classes: item?.Status != 'ACTIVO' ? 'table-danger' : '',
                    _style: (item.FgChecked) ? `background:#b9e3d9` : `background:#e8ebee`,
                }
        });
    }

    export default {
        name: 'modal-positions',
        components: {
        PictureInput,
        ImdgTab
        },
        directives: UpperCase,
        data,
        props: {
            modal: null,
            titleModal: String,
            Form: {
                type: Object,
                required: true,
                default: () => {},
            },
            ModuleForm: {
                type: Object,
                required: true,
                default: () => {},
            },
            Tab: Boolean,
            isWasRequested: Boolean,
            Active: Boolean,
            VForm: Object,
        },
        mixins: [GeneralMixin],
        methods: {
            FormatNumber,
            handleTab,
            closeModal,
            Submit,
            getPositionList,
            changeTableValues
        },
        computed: {
            positionsfields,
            computedPositionsList,
            ...mapState({
                dataBl: state => state.yardManagement.dataBl,
            })
        },
        watch: {
            modal: async function (val) {
                this.showModal = val;
                if (val) {
                    await this.getPositionList(this.ModuleForm.PackagingItem.BlCargoMasterDetailId);

                    if(this.ModuleForm?.PackagingItem?.PositionJson){
                        this.PositionList?.map((e)=>{
                            this.ModuleForm?.PackagingItem?.PositionJson?.map((item)=>{
                                if(e.YardAreaId == item.YardAreaId){
                                    e.Error = false;
                                    e.ErrorMaxCant = false;
                                    e.ErrorMinCant = false;
                                    e.ErrorTotal = false;
                                    e.FgChecked = item.FgChecked;
                                    e.Quantity = item.Quantity;
                                }
                            })
                        });
                    }
                    else{
                        if(this.PositionList.length == 1){
                            this.PositionList?.map((e)=>{
                                e.Error = false;
                                e.ErrorMaxCant = false;
                                e.ErrorMinCant = false;
                                e.ErrorTotal = false;
                                e.FgChecked = true;
                                e.Quantity = this.ModuleForm?.PackagingItem.Quantity;
                            });
                        }
                    }
                }
                else{
                    this.PositionList = [];
                    this.Form.ModalPackaging.SelectedPackagings = '';
                }
            }
        }
        };
  </script>
<template>
  <div class="yard-body">
    <div class="wrapper d-flex align-items-stretch">
      <nav id="sidebar-Detail" :class="collapsed ? 'active' : ''">
        <div class="list-menu">
          <CRow>
            <CCol sm="12">
              <img :src="imageSrc(Bl?.TpCargoRoute)" style="width: 100%; border-top-left-radius: 1rem;" class="img text-center" @error="setAltImg"/>
            </CCol>
          </CRow>
        </div>
        <div class="p-3">
          <ul class="list-unstyled components mb-5">
            <li>
              <div class="list-menu">
                <CRow>
                  <CCol sm="12">
                    <h5 class="border-title d-flex align-items-center justify-content-center py-1" style="height: auto">
                      <b><label class="mb-0">{{ `${$t('label.blCargoMaster')}:  ${getData(isMaster ? Bl?.BlNro : Bl?.MasterBlNro)}`}}</label></b>
                    </h5>
                    <h5 v-if="!isMaster" class="border-title d-flex align-items-center justify-content-center py-1" style="height: auto">
                      <b><label class="mb-0">{{ `${$t('label.BlHouse')}:  ${getData(Bl?.BlNro)}`}}</label></b>
                    </h5>
                    <h5 class="border-title d-flex align-items-center justify-content-center py-1" style="height: auto">
                      <b class="mr-2"><label class="mb-0">{{ `${$t('label.activity')}: ${getData($i18n?.locale=='en' ? Bl?.PortActivityNameEn : Bl?.PortActivityNameEs)}`}}</label></b> 
                      <CIcon size='lg' name="checkListCircleGreen"/>
                    </h5>
                    <h5 class="border-title d-flex align-items-center justify-content-center py-1" :style="`height: auto; background: ${Bl?.BlStatusColor}`">
                      <b><label class="mb-0 mr-1">{{ `${$t('label.status')}:`}}</label></b> 
                      <b><label class="mb-0">{{ `${getData($i18n?.locale=='en' ? Bl?.BlStatusEn : Bl?.BlStatusEs)}`}}</label></b> 
                    </h5>
                    <h5 class="border-title d-flex align-items-center justify-content-center py-1" :style="`height: auto; background: ${Bl?.YardCargoBlStatusColor}`">
                      <b><label class="mb-0">{{ `${getData($i18n?.locale=='en' ? Bl?.YardCargoBlStatusEn : Bl?.YardCargoBlStatusEs)}`}}</label></b> 
                    </h5>
                  </CCol>
                </CRow>
              </div>
            </li>
            <li>
              <div class="list-menu" style="font-size: 12px;">
                <CRow class="align-items-center">
                  <CCol sm="6">
                    {{`${$t('label.client')}`}}  
                  </CCol>
                  <CCol sm="6" class="pl-0">
                    {{ `${getData(Bl.ClientName)}` }}
                  </CCol>
                </CRow>
              </div>
            </li>
            <li>
              <div class="list-menu" style="font-size: 12px;">
                <CRow class="align-items-center">
                  <CCol sm="6">
                    {{`${$t('label.visitItineraty')}`}}
                  </CCol>
                  <CCol sm="6" class="pl-0">
                    {{ Bl.VesselName ? `${getData(Bl.VesselName)}` : ''}}
                  </CCol>
                </CRow>
              </div>
            </li>
            <li>
              <div class="list-menu" style="font-size: 12px;">
                <CRow class="align-items-center">
                  <CCol sm="6">
                    {{$t('label.voyage')}}  
                  </CCol>
                  <CCol sm="6" class="pl-0">
                    {{ getData(Bl?.Voyage) }}
                  </CCol>
                </CRow>
              </div>
            </li> 
            <li>
              <div class="list-menu" style="font-size: 12px;">
                <CRow class="align-items-center">
                  <CCol sm="6" class="d-flex align-items-center">
                    {{`${getData(Bl?.LabelArrival)} / ${getData(Bl?.LabelDeparture)}`}}
                  </CCol>
                  <CCol sm="6" class="pl-0">
                    {{ `${getDate(Bl?.Arrival)} - ${getDate(Bl?.Departure)}` }}
                  </CCol>
                </CRow>
              </div>
            </li>
            <li>
              <div class="list-menu" style="font-size: 12px;">
                <CRow class="align-items-center">
                  <CCol sm="6">
                    {{$t('label.FirstEvent')}}
                  </CCol>
                  <CCol sm="6" class="pl-0 d-flex align-items-center">
                    {{ getData($i18n.locale=='en' ? Bl?.FirstEventNameEn : Bl?.FirstEventNameEs) }}
                  </CCol>
                </CRow>
              </div>
            </li>
            <li>
              <div class="list-menu" style="font-size: 12px;">
                <CRow class="align-items-center">
                  <CCol sm="6">
                    {{$t('label.FirstEventDate')}}
                  </CCol>
                  <CCol sm="6" class="pl-0">
                    {{ getDate(Bl?.FirstEventDate) }}
                  </CCol>
                </CRow>
              </div>
            </li>
            <li>
              <div class="list-menu" style="font-size: 12px;">
                <CRow class="align-items-center">
                  <CCol sm="6">
                    {{$t('label.LastEvent')}}
                  </CCol>
                  <CCol sm="6" class="pl-0">
                    {{ getData($i18n.locale=='en' ? Bl?.LastEventNameEn : Bl?.LastEventNameEs) }}
                  </CCol>
                </CRow>
              </div>
            </li>
            <li>
              <div class="list-menu" style="font-size: 12px;">
                <CRow class="align-items-center">
                  <CCol sm="6">
                    {{`${$t('label.LastEventDate')}`}}
                  </CCol>
                  <CCol sm="6" class="pl-0">
                    {{ getDate(Bl?.LastEventDate) }}
                  </CCol>
                </CRow>
              </div>
            </li>
          </ul>
        </div>
      </nav>
            
      <!-- Page Content  -->
        
      <div id="content">
        <nav
          class="navbar navbar-expand-lg navbar-light bg-light-blue mb-0"
          :class="{'no-margin': null}"
        >
          <div class="container-fluid">
            <button type="button" id="CollapseSidebar" class="btn bg-none text-withe" @click="checkSideBarToggle()">
              <CIcon  class="text-white" :name="checkIconToggle"/>
              <span class="sr-only">{{checkTextToggle}}</span>
            </button>
            <div class="collapse navbar-collapse">
              <ul class="nav navbar-nav ml-auto w-100 justify-content-center align-items-center text-white">
                <li class="m-1 text-center">      
                  <div class="d-flex align-items-center border-title text-center" style="font-size: 9px; height: auto">
                    <div class="pr-1">
                      <CIcon name="Quantity" class="mr-1"/><b>{{ $t('label.Cant') }}</b>
                    </div>
                    <div>
                      <b>{{`${$t('label.AbbreviatedPlanned')}`}}</b>
                      <div>{{ FormatNumber(Bl?.Quantity, 2) }}</div>
                    </div>
                    <div class="px-1">-</div>
                    <div>
                      <b>{{`${$t('label.AbbreviatedReception')}`}}</b>
                      <div>{{ FormatNumber(Bl?.QuantityReceived, 2) }}</div>
                    </div>
                    <div class="px-1">-</div>
                    <div>
                      <b>{{`${$t('label.Abbreviatedcertified')}`}}</b>
                      <div>{{ FormatNumber(Bl?.QuantityCertified, 2) }}</div>
                    </div>
                    <div class="px-1">-</div>
                    <div>
                      <b>{{`${$t('label.AbbreviatedDispatch')}`}}</b>
                      <div>{{ FormatNumber(Bl?.QuantityDispatched, 2) }}</div>
                    </div>
                  </div>
                </li>

                <li class="m-1 text-center">
                  <div class="d-flex align-items-center border-title text-center" style="font-size: 9px; height: auto">
                    <div class="pr-1">
                      <CIcon name="Weight" class="mr-1"/><b>{{ `(KGM)` }}</b>
                    </div>
                    <div>
                      <b>{{`${$t('label.AbbreviatedPlanned')}`}}</b>
                      <div>{{ FormatNumber(Bl?.Weigth, 2) }}</div>
                    </div>
                    <div class="px-1">-</div>
                    <div>
                      <b>{{`${$t('label.AbbreviatedReception')}`}}</b>
                      <div>{{ FormatNumber(Bl?.WeigthReception, 2) }}</div>
                    </div>
                    <div class="px-1">-</div>
                    <div>
                      <b>{{`${$t('label.Abbreviatedcertified')}`}}</b>
                      <div>{{ FormatNumber(Bl?.WeigthCertified, 2) }}</div>
                    </div>
                    <div class="px-1">-</div>
                    <div>
                      <b>{{`${$t('label.AbbreviatedDispatch')}`}}</b>
                      <div>{{ FormatNumber(Bl?.WeigthDispatch, 2) }}</div>
                    </div>
                  </div>
                </li>
                
                <li class="m-1 text-center">
                  <div class="d-flex align-items-center border-title text-center" style="font-size: 9px; height: auto">
                    <div class="pr-1">
                      <CIcon name="boxFill" class="mr-1"/><b>{{ `(M³)` }}</b>
                    </div>
                    <div>
                      <b>{{`${$t('label.AbbreviatedPlanned')}`}}</b>
                      <div>{{ FormatNumber(Bl?.Volumen, 2) }}</div>
                    </div>
                    <div class="px-1">-</div>
                    <div>
                      <b>{{`${$t('label.AbbreviatedReception')}`}}</b>
                      <div>{{ FormatNumber(Bl?.VolumenReception, 2) }}</div>
                    </div>
                    <div class="px-1">-</div>
                    <div>
                      <b>{{`${$t('label.Abbreviatedcertified')}`}}</b>
                      <div>{{ FormatNumber(Bl?.VolumenCertified, 2) }}</div>
                    </div>
                    <div class="px-1">-</div>
                    <div>
                      <b>{{`${$t('label.AbbreviatedDispatch')}`}}</b>
                      <div>{{ FormatNumber(Bl?.VolumenDispatch, 2) }}</div>
                    </div>
                  </div>
                </li>
                <!-- <li class="m-1 text-center">
                  <div class="d-flex justify-content-center mb-1">
                    <CIcon :name="'movementYardDetaild'" class="mr-1"/><b>{{ $t('label.blStatus') }}</b>
                  </div>
                  <div class="border-title" :style="`font-size: 9px; background: ${Bl?.YardCargoBlStatusColor}`">
                    <b>{{ `${getData($i18n?.locale=='en' ? Bl?.YardCargoBlStatusEn : Bl?.YardCargoBlStatusEs)}` }}</b>
                  </div>
                </li> -->
                <!-- <li v-if="Bl?.BlCargoMasterId" class="d-flex align-items-center justify-content-center text-white mx-1">
                  <CButton
                    color="wait"
                    size="sm"
                    @click="getMaster"
                  >
                    <CIcon name="returnArrowBold" class="mr-1"/>
                    <span class="text-white">{{ $t('label.GoToBlMaster') }}</span>
                  </CButton>
                </li> -->
                
              </ul>
            </div>
            <button type="button"  class="btn bg-none text-white" @click="closeCollapse()" v-if="( $store.state.yardManagement.yardCollapse == 4)">
              <CIcon name="x"/>
            </button>
          </div>
        </nav>
          <!-- Contenido Interno -->
        <div class="container-fluid contenido-especial pt-3" >
          <CRow v-if="!isMaster" class="justify-content-end px-3 mb-2">
            <CButton
              color="wait"
              size="sm"
              @click="getMaster"
            >
              <CIcon name="returnArrowBold" class="mr-1"/>
              <span class="text-white">{{ $t('label.GoToBlMaster') }}</span>
            </CButton>
          </CRow>
          
          <div v-if="isYard" class="border-title border-gray mb-3">
            <label class="m-0"><b>{{ `${$t('label.yard')} - ${yardData?.YardName ?? ''}` }}</b></label>
            <CRow class="d-flex justify-content-center align-items-center m-0" >
              <div class="m-1 text-center">
                <div class="d-flex align-items-center border-title border-gray text-center" style="font-size: 9px; height: auto">
                  <div class="pr-1">
                    <CIcon name="Quantity" class="mr-1"/><b>{{ $t('label.Cant') }}</b>
                  </div>
                  <div>
                    <b>{{`${$t('label.AbbreviatedPlanned')}`}}</b>
                    <div>{{ FormatNumber(BlYardInfo?.QuantityPlanning, 2) }}</div>
                  </div>
                  <div class="px-1">-</div>
                  <div>
                    <b>{{`${$t('label.AbbreviatedReception')}`}}</b>
                    <div>{{ FormatNumber(BlYardInfo?.QuantityReceived, 2) }}</div>
                  </div>
                  <div class="px-1">-</div>
                  <div>
                    <b>{{`${$t('label.Abbreviatedcertified')}`}}</b>
                    <div>{{ FormatNumber(BlYardInfo?.QuantityCertified, 2) }}</div>
                  </div>
                  <div class="px-1">-</div>
                  <div>
                    <b>{{`${$t('label.AbbreviatedDispatch')}`}}</b>
                    <div>{{ FormatNumber(BlYardInfo?.QuantityDispatched, 2) }}</div>
                  </div>
                </div>
              </div>

              <div class="m-1 text-center">
                <div class="d-flex align-items-center border-title border-gray text-center" style="font-size: 9px; height: auto">
                  <div class="pr-1">
                    <CIcon name="Weight" class="mr-1"/><b>{{ `(KGM)` }}</b>
                  </div>
                  <div>
                    <b>{{`${$t('label.AbbreviatedPlanned')}`}}</b>
                    <div>{{ FormatNumber(BlYardInfo?.WeigthPlanning, 2) }}</div>
                  </div>
                  <div class="px-1">-</div>
                  <div>
                    <b>{{`${$t('label.AbbreviatedReception')}`}}</b>
                    <div>{{ FormatNumber(BlYardInfo?.WeigthReception, 2) }}</div>
                  </div>
                  <div class="px-1">-</div>
                  <div>
                    <b>{{`${$t('label.Abbreviatedcertified')}`}}</b>
                    <div>{{ FormatNumber(BlYardInfo?.WeigthCertified, 2) }}</div>
                  </div>
                  <div class="px-1">-</div>
                  <div>
                    <b>{{`${$t('label.AbbreviatedDispatch')}`}}</b>
                    <div>{{ FormatNumber(BlYardInfo?.WeigthDispatch, 2) }}</div>
                  </div>
                </div>
              </div>
              
              <div class="m-1 text-center">
                <div class="d-flex align-items-center border-title border-gray text-center" style="font-size: 9px; height: auto">
                  <div class="pr-1">
                    <CIcon name="boxFill" class="mr-1"/><b>{{ `(M³)` }}</b>
                  </div>
                  <div>
                    <b>{{`${$t('label.AbbreviatedPlanned')}`}}</b>
                    <div>{{ FormatNumber(BlYardInfo?.VolumenPlanning, 2) }}</div>
                  </div>
                  <div class="px-1">-</div>
                  <div>
                    <b>{{`${$t('label.AbbreviatedReception')}`}}</b>
                    <div>{{ FormatNumber(BlYardInfo?.VolumenReception, 2) }}</div>
                  </div>
                  <div class="px-1">-</div>
                  <div>
                    <b>{{`${$t('label.Abbreviatedcertified')}`}}</b>
                    <div>{{ FormatNumber(BlYardInfo?.VolumenCertified, 2) }}</div>
                  </div>
                  <div class="px-1">-</div>
                  <div>
                    <b>{{`${$t('label.AbbreviatedDispatch')}`}}</b>
                    <div>{{ FormatNumber(BlYardInfo?.VolumenDispatch, 2) }}</div>
                  </div>
                </div>
              </div>
            </CRow>
          </div>

          <CustomTabs :active-tab="$store.state.yardManagement.BlTabIndex" @update:activeTab="handleTab">
            <CustomTab  valueItemTab="0">
              <template #title>
                <span>{{$t('label.CurrentSituation')}}</span>
              </template>
              <CurrentSituation
                :Bl="Bl"
              />
            </CustomTab>
            <CustomTab valueItemTab="1">
              <template #title>
                <span>{{$t('label.basicData')}}</span>
              </template>
              <BasicData 
                :Bl="Bl"
                :FgEdit="Bl?.FgEdit"
                @Update="Update"
              />
            </CustomTab>
            <CustomTab  valueItemTab="2">
              <template #title>
                <span>{{$t('label.packaging')}}</span>
              </template>
              <Packaging
                :Bl="Bl"
              />
            </CustomTab>
            <CustomTab v-if="isMaster" valueItemTab="3">
              <template #title>
                <span>{{ $t('label.house') }}</span>
              </template>
              <House 
                :Bl="Bl"
                :VForm="$v.form"
                :fgUnconsolidated="fgUnconsolidated"
                :fgPartialUnconsolidated="fgPartialUnconsolidated"
                @Update="Update"
                @getHouse="getHouse"
              />
            </CustomTab>
            <CustomTab valueItemTab="4">
              <template #title>
                <span>{{ $t('label.CertificatesEntry') }}</span>
              </template>
              <certificateEntry
                :Bl="Bl"
              />
            </CustomTab>
            <CustomTab valueItemTab="5">
              <template #title class="contenido-especial">
                <CIcon :name="'movementYardDetaild'"/>
                <CDropdown
                  :togglerText="$t('label.movements')"
                  class="pl-0"
                  :flip="true"
                >
                  <CDropdownItem :active="activeItem(1)" @click="clickItem(1)">{{ `${$t('label.reception')}`}}</CDropdownItem>
                  <CDropdownItem :active="activeItem(5)" @click="clickItem(5)" v-if="isMaster">{{ `${$t('label.blsHousePendingReceive')}`}}</CDropdownItem>
                  <CDropdownItem :active="activeItem(2)" @click="clickItem(2)">{{ `${$t('label.blPositioning')}`}}</CDropdownItem>
                  <CDropdownItem :active="activeItem(3)" @click="clickItem(3)">{{ `${$t('label.dispatch')}`}}</CDropdownItem>
                  <CDropdownItem v-if="isMaster">
                    <nav id="menu_gral">
                      <ul>
                        <li class="dropdown_gral">
                          <a href="javascript:void(0)">{{$t('label.events')}}</a>
                          <ul>
                            <li><a href="javascript:void(0)"@click="clickItem(4)">{{$t('label.deconsolidated')}} </a></li>
                          </ul>
                        </li>
                      </ul>
                    </nav>
                  </CDropdownItem>
                  <CDropdownItem>
                    <nav id="menu_gral">
                      <ul>
                        <li class="dropdown_gral">
                          <a href="javascript:void(0)">{{$t('label.inspections')}}</a>
                          <ul>
                            <li><a href="javascript:void(0)" @click="clickItem(6)">{{$t('label.recognition')}} </a></li>
                            <li><a href="javascript:void(0)" @click="clickItem(7)">{{$t('label.dimensions')}} </a></li>
                            <li><a href="javascript:void(0)" @click="clickItem(8)">{{$t('label.accesories')}} </a></li>
                          </ul>
                        </li>
                      </ul>
                    </nav>
                  </CDropdownItem>
                </CDropdown>
              </template>
              <Movements 
                :Bl="Bl" 
                :fgCertificate="fgCertificate"
              />  
            </CustomTab>
            <CustomTab valueItemTab="6">
              <template #title>
                <span>{{$t('label.transactions')}}</span>
              </template>
              <Transactions
                :Bl="Bl"
                :tab="BlTabIndex == 6"
              />
            </CustomTab>
          </CustomTabs>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import General from '@/_mixins/general';
import { mapState } from 'vuex';
import CustomTabs from '@/components/tabs/CustomTabs';
import CustomTab from '@/components/tabs/CustomTab';
import BlValidations from '@/_validations/bl-query/blValidations';
import CurrentSituation from './taps/currentSituation/index';
import certificateEntry from './taps/certificateEntry/index';
import BasicData from './taps/basicData/index';
import House from './taps/house/index';
import Movements from './taps/movements/index';
import Packaging from './taps/packaging/index';
import Transactions from './taps/transactions/index';
import { DateFormater, NumberFormater } from '@/_helpers/funciones';

//data
function data() {
  return {
    collapsed: false,
    collapseDuration: 0,
    toggled: false,
  };
}

//methods
async function getBlCargoById(blCargoId) {
  this.$store.state.yardManagement.loading = true;
  await this.$http.get("BlCargo-by-Id", { BlCargoId: blCargoId})
  .then(response => {
    this.$store.state.yardManagement.dataBl = response?.data?.data?.[0] ?? {};
    this.$store.state.yardManagement.BlTabIndex = 0;
  }).catch( err => {
      this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
      });
  })
  .finally(() => {
    this.$store.state.yardManagement.loading = false;
  });
}

async function YardCargoById() {
  this.$store.state.yardManagement.loading = true;
  await this.$http.get("YardCargo-by-Id", { YardCargoId: this.YardCargoId, YardId: this.yardData?.YardId })
  .then(response => {
    let Container = response?.data?.data?.[0] ?? undefined;
    if (Container) {
      this.$store.state.yardManagement.dataContainer = Container;
      this.$store.state.yardManagement.yardCollapse = 2;
    }else{
      this.$store.state.yardManagement.yardCollapse = 1;
    }
  }).catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
  })
  .finally(() => {
    this.$store.state.yardManagement.loading = false;
  });
}

function Update(item) {
  if (!this.isYard) {
    this.$emit('Update', item);
  }else {
    this.getBlCargoById(item?.BlCargoId);
  }
}

function getMaster() {
  if (!this.isYard) {
    this.$emit('getBl', this.Bl, true);
  }else {
    this.getBlCargoById(this.Bl?.BlCargoMasterId);
  }

  this.$store.state.yardManagement.dropBlMovement = 0;
  this.$store.state.yardManagement.dropItemInspection = 0;
  this.$store.state.yardManagement.BlTabIndex = 0;
  this.$store.state.yardManagement.blSelected = this.Bl?.BlCargoMasterId;
}

function getHouse(item) {
  if (!this.isYard) {
    this.$emit('getBl', item);
  }else {
    this.getBlCargoById(item?.BlCargoId);
  }
}

function handleTab(tab) {
  this.$store.state.yardManagement.dropBlMovement = 0;
  this.$store.state.yardManagement.dropItemInspection = 0;
  this.$store.state.yardManagement.BlTabIndex = tab;
}
function imageSrc(Src){
  return `${this.$store.getters["connection/getBase"]}${Src}`;
}

function setAltImg(event){
  event.target.src = '/img/errorimage.jpg';
}

function getData(Data) {
  return Data ? Data : '';
}

function getDate(date) {
  if (date) {
    return DateFormater.formatDateTimeWithSlash(date);
  } else {
    return 'N/A';
  }
}

function FormatNumber(item, decimal) {
  return  NumberFormater.formatNumber(item, decimal)
}

function checkSideBarToggle(){
  this.collapsed = !this.collapsed;
}

function activeItem(item) {
  return this.dropBlMovement === item;
}

function clickItem(item){
  this.$store.state.yardManagement.dropBlMovement = item;
}

async function onChangeYardFuntion() {
  this.$store.state.yardManagement.FgPositioning = false;
  setTimeout(() => {this.$store.state.yardManagement.loading = false;}, 20);
}

async function closeCollapse(){
  if (this.FgBlNavigation) {
    this.$store.state.yardManagement.FgBlNavigation = false;
    if (this.YardCargoId) {
      this.$store.state.yardManagement.blSelected = '';
      await this.YardCargoById();
    }else {
      this.$store.state.yardManagement.yardCollapse = 1;
      this.$store.state.yardManagement.dropBlMovement = 0;
      this.$store.state.yardManagement.dropItemEvent = 0;
      this.$store.state.yardManagement.isSelect = false;
      this.$store.state.yardManagement.GeneralList = 0;
      this.$store.state.yardManagement.loading = false;
      this.$store.state.yardManagement.dataBl = {};
    }
  }else{
    this.$store.state.yardManagement.yardCollapse = 1;
    this.$store.state.yardManagement.dropBlMovement = 0;
    this.$store.state.yardManagement.dropItemEvent = 0;
    this.$store.state.yardManagement.isSelect = false;
    this.$store.state.yardManagement.GeneralList = 0;
    this.$store.state.yardManagement.loading = false;
    this.$store.state.yardManagement.dataBl = {};
  }
  this.collapsed = false;
  this.$store.state.yardManagement.FgReception = false;
  this.$store.state.yardManagement.FgDispatch = false;
  this.$store.state.yardManagement.blSelected = '';
}

//computeds
function form() {
  if (this.isMaster) {
    let SerializationList = [];
    let PackagingList = this.Bl?.DetailJson?.map((e, index) => {
      if (e?.SerialJson) {
        SerializationList.push(...e.SerialJson.map((el, index2) => {return {...el, IdX: index2+1, Weight: el.Weigth, Status: 1}}));
      }
      return {...e, IdX: index+1, Weight: e.Weigth, Status: 1}
    }) ?? [];
    return {
      dataBasic:{
        BlCargoId: this.Bl?.BlCargoId ?? '',
        bl: this.Bl?.BlNro ?? '',
        TpBlId: this.Bl?.TpBlId ?? '',
        BlCargoMasterId: this.Bl?.BlCargoMasterId ?? '',
        MasterBlNro: this.Bl?.TpBlId == process.env.VUE_APP_TP_BL_ID_HOUSE ? (this.Bl?.MasterBlNro ?? '') : 'N/A',
        VesselId: this.Bl?.VesselId ?? '',
        VisitIdOrItineraryId: this.Bl?.VisitId ?? (this.Bl?.ItineraryId ?? ''),
        PortActivityId: this.Bl?.PortActivityId ?? '',
        Pol: this.Bl?.LoadPortId ?? '',
        Pod: this.Bl?.DischargePortId ?? '',
        TpCargoId: this.Bl?.TpCargoId ?? '',
        TpCargoClassifId: this.Bl?.TpCargoClassifId ?? '',
        BookingNro: this.Bl?.BookingNro ?? '',
        Procedence: this.Bl?.Procedence ?? '',
        Weight: this.Bl?.Weigth ?? 0,
        Volumen: this.Bl?.Volumen ?? 0,
        Quantity: this.Bl?.Quantity ?? 0,
        Viscosity: this.Bl?.Viscosity ?? 0,
        Temperature: this.Bl?.Temperature ?? 0,
        headingJson: this.Bl?.HeadingJson ?? [],
        customsRegime: this.Bl?.CustomOperationId ?? '',
        ReceptionBpAct: this.Bl?.ReceptionBpAct ?? '',
        Dua: this.Bl?.Dua ?? '',
        Status: this.Bl?.FgActBlCargoMaster? 1:0,
        validCargoMaster: this.Bl?.TpBlId == process.env.VUE_APP_TP_BL_ID_HOUSE,
      },
      PackagingList: PackagingList,
      SerializationList: SerializationList,
      seller:{
        SellerName: this.Bl?.SellerName ?? '',
        SellerAddress: this.Bl?.SellerAddress ?? '',
        SellerPrimaryPhone: this.Bl?.SellerPrimaryPhone ?? '',
        SellerSecondaryPhone: this.Bl?.SellerSecondaryPhone ?? '',
        SellerPrimaryEmail: this.Bl?.SellerPrimaryEmail ?? '',
        SellerSecondaryEmail: this.Bl?.SellerSecondaryEmail ?? ''
      },
      customBroke:{
        CustomBrokerId: this.Bl?.CustomBrokerId ?? '',
        CustomBrokerRif: this.Bl?.CustomBrokerRif ?? '',
        CustomBrokerName: this.Bl?.CustomBrokerName ?? '',
        CustomBrokerAddress: this.Bl?.CustomBrokerAddress ?? '',
        CustomBrokerPrimaryPhone: this.Bl?.CustomBrokerPrimaryPhone ?? '',
        CustomBrokerSecondaryPhone: this.Bl?.CustomBrokerSecondaryPhone ?? '',
        CustomBrokerPrimaryEmail: this.Bl?.CustomBrokerPrimaryEmail ?? '',
        CustomBrokerSecondaryEmail: this.Bl?.CustomBrokerSecondaryEmail ?? '',
        consolidatorCheck: this.Bl?.ConsolidatorId ? true : false,
      },
      consolidator:{
        ConsolidatorId: this.Bl?.ConsolidatorId ?? '',
        ConsolidatorRif: this.Bl?.ConsolidatorRif ?? '',
        ConsolidatorName: this.Bl?.ConsolidatorName ?? '',
        ConsolidatorAddress: this.Bl?.ConsolidatorAddress ?? '',
        ConsolidatorPrimaryPhone: this.Bl?.ConsolidatorPrimaryPhone ?? '',
        ConsolidatorSecondaryPhone: this.Bl?.ConsolidatorSecondaryPhone ?? '',
        ConsolidatorPrimaryEmail: this.Bl?.ConsolidatorPrimaryEmail ?? '',
        ConsolidatorSecondaryEmail: this.Bl?.ConsolidatorSecondaryEmail ?? ''
      },
      consignee:{
        ConsigneeId: this.Bl?.ClientTpId ?? '',
        ConsigneeRif: this.Bl?.ClientRif ?? '',
        ConsigneeAddress: this.Bl?.ClientAddress ?? '',
        ConsigneeName: this.Bl?.ClientName ?? '',
        ConsigneePrimaryPhone: this.Bl?.ClientPrimaryPhone ?? '',
        ConsigneeSecondaryPhone: this.Bl?.ClientSecondaryPhone ?? '',
        ConsigneePrimaryEmail: this.Bl?.ClientPrimaryEmail ?? '',
        ConsigneeSecondaryEmail: this.Bl?.ClientSecondaryEmail ?? '', 
      },
      notify:{
        NotifyToName: this.Bl?.NotifyToName ?? '',
        NotifyToAddress: this.Bl?.NotifyToAddress ?? '',
        NotifyToPrimaryPhone: this.Bl?.NotifyToPrimaryPhone ?? '',
        NotifyToSecondaryPhone: this.Bl?.NotifyToSecondaryPhone ?? '',
        NotifyToPrimaryEmail: this.Bl?.NotifyToPrimaryEmail ?? '',
        NotifyToSecondaryEmail: this.Bl?.NotifyToSecondaryEmail ?? ''
      },
      DescriptionOfGoods:{
        DescriptionOfGoods: this.Bl?.DescriptionOfGoods ?? ''
      },
    }
  }else {
    return {}
  }
}

function isMaster() {
  return this.Bl.TpBlId == process.env.VUE_APP_TP_BL_ID_MASTER
}

function fgCertificate() {
  return this.Bl.BlStatus == process.env.VUE_APP_BL_STATUS_ID_CERTIFICATE
}

function fgUnconsolidated() {
  return this.Bl.YardCargoBlStatus == process.env.VUE_APP_YARD_CARGO_BL_STATUS_UNCONSOLIDATED
}

function fgPartialUnconsolidated() {
  return this.Bl.YardCargoBlStatus == process.env.VUE_APP_YARD_CARGO_BL_STATUS_PARTIAL_UNCONSOLIDATED
}

function checkIconToggle() {
  if (this.collapsed) {
    return "cil-chevron-right-alt";
  } else {
    return "cil-chevron-left-alt";
  }
}

function checkTextToggle() {
  if (this.collapsed) {
    return this.$t("label.close");
  } else {
    return this.$t("label.watch");
  }
}

function BlYardInfo() {
  return this.Bl?.YardDataJson?.find(e => e.YardId == this.yardData.YardId) ?? {};
}

export default{
  name: 'bl-info',
  data,
  components: {
    CustomTabs,
    CustomTab,
    CurrentSituation,
    certificateEntry,
    BasicData,
    House,
    Movements,
    Packaging,
    Transactions,
  },
  mixins: [General],
  validations(){ return BlValidations(this.form?.customBroke, this.form.dataBasic?.validCargoMaster)},
  beforeDestroy () {
    this.$store.state.yardManagement.dataBl = {};
    this.$store.state.yardManagement.yardCollapse = 0;
  },
  props: {
    Bl: {
      type: Object,
      Required: true,
      default: undefined,
    },
    isYard: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    getBlCargoById,
    YardCargoById,
    Update,
    getMaster,
    getHouse,
    onChangeYardFuntion,
    imageSrc,
    setAltImg,
    getData,
    getDate,
    FormatNumber,
    checkSideBarToggle,
    activeItem,
    clickItem,
    handleTab,
    closeCollapse,
  },
  computed: {
    form,
    isMaster,
    fgCertificate,
    fgUnconsolidated,
    fgPartialUnconsolidated,
    checkIconToggle,
    checkTextToggle,
    BlYardInfo,
    ...mapState({
      dropBlMovement: state => state.yardManagement.dropBlMovement,
      yardData: state => state.yardManagement.yardData,
      yardCollapse: state => state.yardManagement.yardCollapse,
      FgBlNavigation: state => state.yardManagement.FgBlNavigation,
      YardCargoId: state => state.yardManagement.dataContainer.YardCargoId,
      BlTabIndex: state => state.yardManagement.BlTabIndex,
    })
  },
  watch:{
    yardCollapse: async function (value) {
      if ((value == 4) && this.isYard) {
        this.onChangeYardFuntion();
      }
    },
  }
}
</script>
<style lang="scss">
  .custom-nav li > .custom-tab-link{
    .dropdown{
      width:100%;
      padding: 0 20px;
      .dropdown-toggle {
        color:white;
        opacity: 1;
        padding-left: 0;
        padding-top: 0;
      }
      .dropdown-toggle::after {
        margin-left: 0.255em;
        margin-right: 1.0em;
      }
    }
  }
  .custom-nav li.active > .custom-tab-link {
    .dropdown{
        width:100%;
        padding: 0 20px;
      .dropdown-toggle {
        color: #000;
        opacity: 1;
      }
      .dropdown-toggle::after {
        margin-left: 0.255em;
        margin-right: 1.0em;
      }
    }
  }
</style>
<style scoped>
.icon{
  background-color: null; 
  border: 0px;
}
.Labels {
  font-size: 10px;
}
.border-title{
  background-color: #1a3760;
  color: white;
  display: block;
  height: calc(1.5em + 0.75rem + 1px);
  padding: 0.375rem 0.75rem;
  font-size: 11px;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  border: 1px solid;
  border-color: #d8dbe0;
  border-radius: 0.50rem;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}
.border-gray {
  background-color: white; 
  color: #3c4b64; 
  height: auto;
}
</style>
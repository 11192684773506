var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showIndex===0),expression:"showIndex===0"}]},[_c('CRow',{staticClass:"mb-3"},[_c('CCol',{staticClass:"d-flex align-items-center justify-content-start",attrs:{"sm":"12","lg":"6"}},[_c('h6',{staticClass:"mt-2"},[_c('CIcon',{staticClass:"titulo-icono",attrs:{"name":"cil-list","size":"lg"}}),_vm._v(" "+_vm._s(`${this.$t('label.RecognitionList')}`)+" ")],1)]),_c('CCol',{staticClass:"d-flex align-items-center justify-content-end",attrs:{"sm":"12","lg":"6"}},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
            content: _vm.$t('label.recognition'),
            placement: 'top-end'
          }),expression:"{\n            content: $t('label.recognition'),\n            placement: 'top-end'\n          }"}],attrs:{"color":"add","size":"sm"},on:{"click":function($event){_vm.showIndex = 1}}},[_c('CIcon',{attrs:{"name":"cil-playlist-add"}}),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.$t('label.nuevo')))])],1)],1)],1),_c('CCol',{attrs:{"sm":"12"}},[_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"items":_vm.computedList,"fields":_vm.fields,"items-per-page":5,"column-filter":"","pagination":"","table-filter":_vm.tableText.tableFilterText,"items-per-page-select":_vm.tableText.itemsPerPageText,"noItemsView":_vm.tableText.noItemsViewText,"sorter":""},scopedSlots:_vm._u([{key:"Quantity",fn:function({ item }){return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.formatNumber(item.Quantity, 2))+" ")])]}},{key:"FirstEventDate",fn:function({ item }){return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.formatDateTimeWithSlash(item?.FirstEventDate))+" ")])]}},{key:"LastEventDate",fn:function({ item }){return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.formatDateTimeWithSlash(item?.LastEventDate))+" ")])]}},{key:"options",fn:function({ item }){return [_c('td',{staticClass:"text-center"},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                content: `${_vm.$t('label.see')} ${_vm.$t('label.yard')}`,
                placement: 'top-start'
              }),expression:"{\n                content: `${$t('label.see')} ${$t('label.yard')}`,\n                placement: 'top-start'\n              }"}],attrs:{"square":"","size":"sm","color":"watch"},on:{"click":function($event){return _vm.SeeUbicationDetailJson(item)}}},[_c('CIcon',{attrs:{"name":"eye"}})],1)],1)]}},{key:"details",fn:function({item}){return [_c('CCollapse',{staticClass:"p-2",attrs:{"show":Boolean(item?.FgCollapse)}},[_c('CRow',{staticClass:"justify-content-start m-0"},[_c('CCol',{staticClass:"px-0",attrs:{"sm":"12","lg":"8"}},[_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"column-filter":"","items":_vm.computedUbicationDetailJson(item),"fields":_vm.fieldUbicationDetail(item?.PackagingId),"table-filter":_vm.tableText.tableFilterText,"noItemsView":_vm.tableText.noItemsViewText,"items-per-page":_vm.tableText.itemsPerPage},scopedSlots:_vm._u([{key:"Quantity",fn:function({ item }){return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.formatNumber(item?.Quantity, 2))+" ")])]}},{key:"Weigth",fn:function({ item }){return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.formatNumber(item?.Weigth, 2))+" ")])]}},{key:"Volumen",fn:function({ item }){return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.formatNumber(item?.Volumen, 2))+" ")])]}}],null,true)})],1)],1)],1)]}}])})],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showIndex===1),expression:"showIndex===1"}]},[_c('RecognitionRegister',{attrs:{"Active":_vm.showIndex===1},on:{"close":function($event){_vm.showIndex=0}}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
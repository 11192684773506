<template>
  <div>
    <CRow class="mb-3">
      <CCol sm="12" lg="12" xl="12" class="d-flex align-items-center justify-content-start">
        <CButton
          color="edit"
          class="mr-2"
          v-c-tooltip="{
            content: $t('label.return'),
            placement: 'top',
          }"
          size="sm"
          @click="close()"
        >
          <CIcon name="cil-chevron-left-alt" />
        </CButton>
        <h6 class="mt-2">
          {{ title }}
        </h6>
      </CCol>
    </CRow>
    <CTabs variant="tabs" :active-tab="tabIndex" @update:activeTab="tabIndex=$event">
      <CTab :title="$t('label.generalData')">
        <div class="border border-top-0 rounded-bottom pt-3 m-0"> 
          <GeneralDataTab
            :form="form"
            :vForm="$v.form"
            :Tab="tabIndex===0 && Active"
            :isEdit="isEdit"
            :show="Active"
          />
        </div>
      </CTab>
      <CTab :title="`${$t('label.packaging')} / ${$t('label.implement')}`">
        <div class="border border-top-0 rounded-bottom pt-3 m-0"> 
          <PackagingImplementTab
            :form="form"
            :vForm="$v.form"
            :Tab="tabIndex===1 && Active"
            :isEdit="isEdit"
            :show="Active"
          />
        </div>
      </CTab>
    </CTabs>
  </div>
</template>
<script>
import General from '@/_mixins/general';
import UpperCase from '@/_validations/uppercase-directive';
import recognitionValidations from '@/_validations/bl-query/recognitionValidations';
import GeneralDataTab from './tab/generalData';
import PackagingImplementTab from './tab/packagingImplement';
import { DateFormater, NumberFormater } from '@/_helpers/funciones';
import { mapState } from 'vuex';

function data() {
  return {
    tabIndex: 0,
    form: {
      GeneralData: {
        FgTotal: false,
        YardAreaId: '',
        TransactionBegin: '',
        TransactionFinish: '',
        EventReasonId: '',
        MachineId: '',
        Observation: '',
      },
      Packaging: {
        Id: '',
        FgVolumen: false,
        FgSerial: false,
        BlCargoMasterDetailId: '',
        BlCargoMasterDetailSerialId: '',
        WeightOrigin: 0,
        Quantity: 0,
        Length: 0,
        Width: 0,
        Height: 0,
        Volumen: 0,
        ImplementJson: [],
      },
      DetailJson: [],
      Implement: {
        id: '',
        GpoImplementId: '',
        ImplementId: '',
        Quantity: 0,
        Observation: '',
      },
    }
  };
}

//methods
function close() {
  this.$emit("close");
}

//computed
function title() {
  if (this.isEdit) {
    return `${this.$t('label.edit')} ${this.$t('label.recognition')}: `
  }else {
    return `${this.$t('label.nuevo')} ${this.$t('label.recognition')}`
  }
}

export default{
  name: 'BlRecognitionRegister',
  data,
  mixins: [General],
  validations: recognitionValidations,
  directives: UpperCase,
  components:{
    GeneralDataTab,
    PackagingImplementTab,
  },
  props: {
    Active: Boolean,
    isEdit: Boolean,
  },
  methods: {
    close,
  },
  computed: {
    title,
    ...mapState({
      dataBl: state => state.yardManagement.dataBl,
    })
  },
  watch: {

  }
}
</script>
<template>
  <div>
    <div v-show="showIndex==0">
      <CRow class="mb-3">
        <CCol sm="12" lg="12" class="d-flex align-items-center justify-content-start">
          <h6 class="mt-2"><CIcon name='cil-list' size='lg' class="titulo-icono" /> {{ `${this.$t('label.deconsolidatedList')}`}} </h6>
        </CCol>
      </CRow>
      <CRow class="my-3">
          <CCol sm="12" lg="12" class="d-flex align-items-center justify-content-end">
            <CRow>
              <CCol sm="12" xl="12" class="align-items-center" v-if="fgDesconsolidate">
                <CButton
                  shape="square"
                  color="add"
                  class="d-flex align-items-center ml-auto"
                  v-c-tooltip="{
                    content: $t('label.deconsolidated'),
                    placement: 'top-end'
                  }"
                  @click="toggleAdd({})"
                >
                  <CIcon name="cil-playlist-add"/><span class="ml-1">{{$t('label.nueva')}}</span>
                </CButton>
              </CCol>
            </CRow>
          </CCol> 
      </CRow>
      <div>
        <CRow>
            <CCol col="12" xl="12">
                <dataTableExtended
                    class="align-center-row-datatable"
                    hover
                    sorter
                    small
                    details
                    column-filter
                    :items="formatedItems"
                    :fields="fields"
                    :table-filter="tableText.tableFilterText"
                    :noItemsView="tableText.noItemsViewText"
                    :items-per-page="tableText.itemsPerPage"
                    :items-per-page-select="tableText.itemsPerPageText"
                    pagination
                >
                    <template #number="{ index }">
                        <td class="text-center">
                            {{index+1}}
                        </td>
                    </template>
                    <template #Status="{item}">
                      <td class="text-center">
                        <CBadge :color="getBadge(item.Status)">
                          {{$t('label.'+item.Status)}}
                        </CBadge>
                      </td>
                    </template>
                    <template #buttonOptions="{item, index}">
                        <td class="text-center">
                            <CButton
                              color="edit"
                              square
                              size="sm"
                              class="mr-1"
                              v-c-tooltip="{
                                content: $t('label.editReception'),
                                placement: 'top-start'
                              }"
                              @click="toggleAdd(item, true)"
                            >
                              <CIcon name="pencil" />
                            </CButton>
                            <CButton
                              style="background-color: #d6d6d7;"
                              square
                              size="sm"
                              v-c-tooltip="{
                                content: $t('label.Seals'),
                                placement: 'top-start'
                              }"
                              @click="viewDetail(item, index)"
                          >
                              <CIcon name="bottomSeals"/>
                            </CButton>
                        </td>
                    </template>
                    <template #details="{item}">
                      <CCollapse
                        :show="Boolean(item._toggled)"
                        :duration="collapseDuration"
                      >
                        <CCardBody>
                          <dataTableExtended
                            class="align-center-row-datatable table-lv-2"
                            small
                            :items="formatedSeal(item.SealJson[0])"
                            :fields="level2Fields"
                            :noItemsView="tableText.noItemsViewText"
                            :items-per-page="tableText.itemsPerPage"
                        >
                        
                            <template #button="{item}">
                                <td class="text-center">
                                    <CButton
                                    v-if="item.SealDocumentJson.length!=0&&item.Seal"
                                        color="watch"
                                        square
                                        size="sm"
                                        class="mr-1"
                                        @click="ModalImagen(item)"
                                        
                                    >
                                      <CIcon name="pencil" />
                                    </CButton>
                                </td>
                            </template>
                          </dataTableExtended>
                        </CCardBody>
                      </CCollapse>
                    </template>
                </dataTableExtended>
            </CCol>
        </CRow>
      </div>
    </div>
    <div v-show="showIndex==1">
      <DesconsolidatedRegister
          :Active="ActiveRegister"
          :HouseData="HouseData"
          :isEdit="isEdit"
          :ContainerItem="ContainerItem"
          @Close="showIndex=0, ActiveRegister=false, isEdit=false, Update()"
          @Update-list="Update"
      />
    </div>
  </div>
</template>
<script>

import GeneralMixin from '@/_mixins/general';
import CustomTabs from '@/components/tabs/CustomTabs';
import CustomTab from '@/components/tabs/CustomTab';
import DesconsolidatedRegister from './desconsolidated-register';
import { DateFormater, NumberFormater } from '@/_helpers/funciones';
import { mapState } from 'vuex';

//DATA-------------------------
  function data() {
    return {
      dataDesconsolited: [],
      collapseDuration: 0,
      _toggled: false,
      showIndex: 0,
      ActiveRegister: false,
      isEdit: false,
      ContainerItem:{},
      HouseData: {},
    }
  }

  async function getYardCargo() {
    await this.$http.get('YardCargo-by-Id', { YardCargoId: this.YardCargoId, YardId: this.YardId })
      .then(response => {
        let List = response.data.data;
        if (List&&List.length!=0) {
          if(this.$store.state.yardManagement.dataContainer.YarCargoDispachtId){
            let yardCargoReception = '', yardCargoDispatch = ''; 

            yardCargoReception = this.$store.state.yardManagement.dataContainer.YardCargoId;
            yardCargoDispatch = this.$store.state.yardManagement.dataContainer.YarCargoDispachtId;

            this.$store.state.yardManagement.dataContainer = {...List[0], YardCargoId : yardCargoReception, YarCargoDispachtId: yardCargoDispatch};
          } 
          else{
            this.$store.state.yardManagement.dataContainer = List[0];
          }
          
          if (process.env.VUE_APP_YARD_CARGO_STATUS_RECEPTION == List[0].YardCargoStatus.toUpperCase()) {
            this.$store.state.yardManagement.globalSelectContainer = 
              this.$store.state.yardManagement.globalSelectContainer.map(Select=>{
                return Select.CargoId == this.CargoId ? List[0] : Select;
              });
          }
        }else{
          this.$store.state.yardManagement.dataContainer = {};
          this.$store.state.yardManagement.ListReceivedContainers = [];
          this.$store.state.yardManagement.ListDispatchedContainers = [];
          this.$store.state.yardManagement.ListRecognitionContainers = [];
        }
      })
      .catch( err => {
        this.$store.state.yardManagement.dataContainer = {};
          this.$store.state.yardManagement.ListReceivedContainers = [];
          this.$store.state.yardManagement.ListDispatchedContainers = [];
          this.$store.state.yardManagement.ListRecognitionContainers = [];
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: err,
          type: "error"
        });
      })
  }

  async function getBlHouseData() {
    await this.$http.get('BlHouseData-by-CargoId', { CargoId: this.dataContainer.CargoId })
      .then(response => {
        this.HouseData = response.data.data[0] ?? []; 
      })
      .catch( err => {
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: err,
          type: "error"
        });
      })
  }

  async function getDesconsolitedList() {
    await this.$http.get('YardEventDeconsolidation-by-CargoId', { CargoId: this.dataContainer.CargoId})
      .then(response => {
        this.dataDesconsolited = response.data.data ?? [];
      })
      .catch( err => {
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: err,
          type: "error"
        });
      })
  }

  function toggleAdd(item, Edit = false) {
      this.ContainerItem = item;
      this.ActiveRegister = true;
      this.showIndex = 1;
      this.isEdit = Edit;
  }

  function formatedItems() {
    let _lang = this.$i18n.locale;
    return this.dataDesconsolited.map((item, index) => {
      return {
            ...item,
            Nro: index+1,
            YardName: item.YardName? item.YardName : this.dataContainer.YardName,
            TransactionDate: item.TransactionDate ? DateFormater.formatDateTimeWithSlash(item.TransactionDate) : '',
            TransaRegDate: item.TransaRegDate ? DateFormater.formatDateTimeWithSlash(item.TransaRegDate) : '',
            MachineCode: item.MachineCode ?? '',
            ComputedInitDate: item.TransactionBegin ? DateFormater.formatDateTimeWithSlash(item.TransactionBegin) : '',
            ComputedFinishDate: item.TransactionFinish ? DateFormater.formatDateTimeWithSlash(item.TransactionFinish) : '',
            EventReasonName: _lang=='en' ? item.EventReasonNameEn || '' : item.EventReasonNameEs || '',
            EventServiceName: _lang=='en' ? item.EventServiceNameEn || '' : item.EventServiceNameEs || ''
        };
    })
  }

  function fields(){
    return [
        {
          key: 'buttonOptions', 
          label: '', 
          _style: 'min-width: 90px; width: 1%; text-align:center; ', 
          sorter: false, 
          filter: false
        },
        { key: 'number', label: '#',_style: 'min-width: 60px; text-align:center;', filter: false, _classes: 'text-uppercase text-center'},
        { key: 'YardName',label: this.$t('label.yard'), _style:'min-width: 120px;', _classes: 'text-uppercase text-center'},
        { key: 'EventReasonName', label: this.$t('label.motive'), _style:'min-width: 180px;', _classes: 'text-uppercase text-center'},
        { key: 'EventServiceName', label: this.$t('label.service'), _style:'min-width: 180px;', _classes: 'text-uppercase text-center'},
        { key: 'MachineCode', label: this.$t('label.machine'), _style:'min-width: 120px;', _classes: 'text-uppercase text-center' },
        { key: 'ComputedInitDate',  label: this.$t('label.dateFrom'), _classes: 'text-uppercase text-center', _style:'min-width: 180px;'},
        { key: 'ComputedFinishDate', label: this.$t('label.dateTo'), _classes: 'text-uppercase text-center', _style:'min-width: 180px;'},
        { key: 'DeviceName', label: this.$t('label.device'), _style:'min-width: 120px;', _classes: 'text-uppercase text-center'},
        { key: 'TransaLogin', label: this.$t('label.user'), _style:'min-width: 180px;', _classes:"text-center"},
        { key: 'TransaRegDate', label: this.$t('label.registerDate'),  _style:'min-width: 150px; text-align:center;', _classes:"text-center" },
        { key: 'Status', label: this.$t('label.status'),  _style:'min-width: 130px; text-align:center;', _classes:"text-center" },
        
    ];
  }

  function level2Fields(){
    return [
        {
          key: 'button', 
          label: '', 
          _style: 'min-width: 45px; width: 1%; text-align:center; background-color: #828c9c;', 
          sorter: false, 
          filter: false
        },
        { key: 'Seal',label: this.$t('label.Seals'), _style:'width: 20%; background-color: #828c9c; color: white;', _classes: 'text-uppercase text-center', filter: false, sorter:false,},
        { key: 'SealConditionName', label: this.$t('label.Mode'), _style:'width: 20%; background-color: #828c9c; color: white;', _classes: 'text-uppercase text-center', filter: false, sorter:false,},
        { key: 'SealB', label: this.$t('label.previous'), _style:'width: 25%; background-color: #828c9c; color: white;', _classes: 'text-uppercase text-center', filter: false, sorter:false,},
        { key: 'SealAssignmentName', label: this.$t('label.assignation'), _style:'width: 25%; background-color: #828c9c; color: white;', _classes:"text-center", filter: false, sorter:false,},
        { key: 'ObservationSeal', label: this.$t('label.observation'), _style:'width: 35%; background-color: #828c9c; color: white;', _classes:"text-center", filter: false, sorter:false,},
    ];
  }

  async function Update() {
    this.$store.state.yardManagement.loading = true;
    await this.getYardCargo();
    await this.getBlHouseData();
    await this.getDesconsolitedList();
    this.$store.state.yardManagement.loading = false;
  }

  function closeShow(item, level) {

  }

  function viewDetail(item){
    this.closeShow(item, 1);

    item._toggled = !item._toggled;
    this.collapseDuration = 300;
    this.$nextTick(() => {
      this.collapseDuration = 0;
      this.loading = false;
      this.apiStateLoading = false;
    });
  }

function NumberFormat(number) {
  return NumberFormater.setNum(number);
}

//computed
function VisitOptions(){
  if(this.ListVisit.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.ListVisit.map(function(e){
      chart.push({
        value: e.IncidenceGpoId, 
        label: e.IncidenceGpoNameEn,
      })    
    })
    return chart;
  }
}

function formatedSeal(SealJson) {
  if (SealJson) {
    let _lang = this.$i18n.locale;
    let SealArray = SealJson.Seal1Json.concat(SealJson.Seal2Json, SealJson.Seal3Json, SealJson.Seal4Json);
    return SealArray.map((item) => {
      return {
        ...item,
        Seal: item.Seal ? item.Seal : '',
        SealConditionName: _lang=='en' ? item.SealConditionNameEn || '' : item.SealConditionNameEs || '',
        SealB: item.SealB ? item.SealB : '',
        SealAssignmentName: _lang=='en' ? item.SealAssignmentNameEn || '' : item.SealAssignmentNameEs || '',
        ObservationSeal: item.ObservationSeal ? item.ObservationSeal : '',
      };
    })
  }
}

function fgDesconsolidate() {
  return ( 
    (process.env.VUE_APP_YARD_CARGO_BL_STATUS_UNCONSOLIDATED != this.dataContainer.BlJson?.[0]?.YardCargoBlStatus) && 
    (this.HouseData?.BlHouseCount && (NumberFormat(this.HouseData?.BlHouseCount??0)>0)) && 
    this.dataContainer?.FgCertified && this.dataContainer?.TpCargoStatusId == process.env.VUE_APP_TP_CARGO_STATUS_FULL 
  )
}

export default {
  name: 'desconsolidated',
  components: {
    CustomTabs,
    CustomTab,
    DesconsolidatedRegister
   },
  data,
  mixins: [GeneralMixin],
  methods: {
    viewDetail,
    closeShow,
    toggleAdd,
    Update,
    formatedSeal,
    getDesconsolitedList,
    getBlHouseData,
    NumberFormat,
    getYardCargo,
  },
  computed: {
    VisitOptions,
    fields,
    level2Fields,
    formatedItems,
    fgDesconsolidate,
    ...mapState({
        CompanyBranchId: state => state.auth.branch.CompanyBranchId,
        YardCargoId: state => state.yardManagement.dataContainer.YardCargoId,
        YardId: state => state.yardManagement.dataContainer.YardId,
        GeneralList: state => state.yardManagement.GeneralList,
        dropItemEvent: state => state.yardManagement.dropItemEvent,
        dataContainer: state => state.yardManagement.dataContainer,
    })
  },
  watch: {
    dropItemEvent: async function (val) {
      if(val == 1){
        this.$store.state.yardManagement.loading = true;
        
        this.showIndex = 0;
        this.isEdit = false;
        await this.getBlHouseData();
        await this.getDesconsolitedList();
        
        this.$store.state.yardManagement.loading = false;
      }
    },
  },
};
</script>
<style scoped>
.Containers-header{
  background: #4e5a70;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
}
.Containers-Search{
  background: #ffffff;
  border-radius: 10px;
}
.form-control-Search{
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  border: 1px solid;
  color: #768192;
  background-color: #fff;
  border-color: #fff;
  border-radius: 10px;
  outline: none;
}
.Containers-data{
  margin: 5px;
  padding: 5px 15px;
  background-color: #fff;
  border-radius: 10px;
}

.Container-items{
  display: flex;
  justify-content: space-between;
  margin-bottom: 0;
}
.value-Table{
  background-color: white;
}
</style>
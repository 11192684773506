import {
  onlyText,
  phoneTest,
  especiales,
  onlyAlphanumericGuion,
  onlyAlphanumericGuion2,
} from '@/_validations/validacionEspeciales'

import {
  required,
  email,
  helpers,
  minLength,
  maxLength,
  minValue,
  maxValue,
  not,
  sameAs,
} from "vuelidate/lib/validators";

import { NumberFormater } from "@/_helpers/funciones";

export default () => {
  return {
    form: {
      GeneralData: {
        FgTotal: {},
        YardAreaId: {},
        TransactionBegin: {},
        TransactionFinish: {},
        EventReasonId: {},
        MachineId: {},
        Observation: {},
      },
      Packaging: {
        FgVolumen: {},
        FgSerial: {},
        BlCargoMasterDetailId: {},
        BlCargoMasterDetailSerialId: {},
        WeightOrigin: {},
        Quantity: {},
        Length: {},
        Width: {},
        Height: {},
        Volumen: {},
        ImplementJson: {},
      },
      DetailJson: {},
      Implement: {
        GpoImplementId: {},
        ImplementId: {},
        Quantity: {},
        Observation: {},
      },
    }
  }
}
<template>
  <div>
    <CRow class="mb-3">
      <CCol sm="12" lg="12" xl="12" class="d-flex align-items-center justify-content-start">
        <CButton
          color="edit"
          class="mr-2"
          v-c-tooltip="{
            content: $t('label.return'),
            placement: 'top',
          }"
          size="sm"
          @click="Reset()"
        >
          <CIcon name="cil-chevron-left-alt" />
        </CButton>
        <h6 class="mt-2">
          {{ isEdit ? `${$t('label.edit')} ${$t('label.ContainerDispatch')}: ${ContainerItem.ContainerCode}` : `${$t('label.nuevo')} ${$t('label.ContainerDispatch')}: ${ContainerItem.ContainerCode}`}} 
        </h6>
      </CCol>
    </CRow>
    <CRow class="justify-content-center mt-3 px-3">
        <CCol sm="12">
          <CTabs variant="tabs" :active-tab="0">
            <CTab :title="$t('label.transport')">
              <CCard class="card-border">
                <CRow class="justify-content-center px-3">
                  <CCol sm="12" lg="6">
                    <CSelect
                      size="sm"
                      v-uppercase
                      :label="$t('label.transaction')"
                      addLabelClasses="required text-right"
                      :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-9'}"
                      :placeholder="$t('label.select')"
                      v-model="$v.YardStatusSuggestTransactionId.$model"
                      :is-valid="hasError($v.YardStatusSuggestTransactionId)"
                      :invalid-feedback="errorMessage($v.YardStatusSuggestTransactionId)"
                      :options="TransactionOptions"
                      @change="onChangeTransactionId"
                      :disabled="validateTypeYard"
                    />
                    <CSelect
                      size="sm"
                      v-uppercase
                      v-model="$v.MachineId.$model"
                      :options="MachineOptions"
                      :label="$t('label.machine')"
                      addLabelClasses="text-right required"
                      :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-9'}"
                      :placeholder="$t('label.select')"
                      :is-valid="hasError($v.MachineId)"
                      :invalid-feedback="errorMessage($v.MachineId)"
                      :disabled="validateTypeYard"
                    />
                    <div class="form-group form-row" rol="group">
                        <label class="col-form-label col-sm-12 col-lg-3 text-right" style="font-size:13px" >{{$t('label.transport')}}</label>
                        <div class="col-sm-12 col-lg-9 input-group h-25">
                            <v-select
                              id="v-select-small"
                              size="sm"
                              class="select-adjust"
                              v-model="$v.TransportId.$model"
                              :options="TransportOptions"
                              :reduce="option => option.value"
                              :getOptionLabel="option => option.title"
                              :placeholder="$t('label.select')"
                              :disabled="validateTypeYard"
                              :class="$v.TransportId.$dirty ? ($v.TransportId.$error ? 'select-client--error' : 'select-client--correct')  : ''"
                            >
                                <template #no-options="{ }">
                                    {{$t('label.noResultsFound')}}
                                </template>
                                <template #option="{title}">
                                    {{ title }}<br />
                                </template>
                            </v-select>
                        </div>
                    </div>
                    <div class="form-group form-row mb-3 px-0">
                      <label class="col-form-label-sm col-sm-12 col-lg-3 required text-right mb-0">{{`${$t('label.dispatchDate')}`}}</label>
                      <div class="input-group col-sm-12 col-lg-9 align-items-center mb-0">
                        <vue-datepicker 
                          type="datetime"
                          header
                          :lang="this.$i18n.locale"
                          :editable="false"
                          :clearable="false"
                          format="DD/MM/YYYY HH:mm"
                          placeholder="DD/MM/YYYY HH:mm"
                          time-title-format="DD/MM/YYYY HH:mm"
                          v-model="$v.TransactionDate.$model"
                          :disabled-date="validateDateRange"
                          :append-to-body="false"
                          value-type="format"
                          :show-second="false"
                          class="w-100"
                          :disabled="validateTypeYard"
                        >
                          <template #input>
                            <CInput
                              v-uppercase
                              class="w-100 float-left mb-0"
                              :value="TransactionDate"
                              placeholder="DD/MM/YYYY HH:mm"
                              :is-valid="hasError($v.TransactionDate)"
                              size="sm"            
                              :disabled="validateTypeYard"          
                            >
                              <template #append-content>
                                <CIcon name="cil-calendar" />
                              </template>
                            </CInput>
                          </template>
                          <template #icon-calendar>
                            <div style="display: none"></div>
                          </template>
                        </vue-datepicker>
                      </div>
                    </div>
                    <CInput
                      v-if="ValidateVisitcargoId"
                      size="sm"
                      v-uppercase
                      v-model="$v.Destination.$model"
                      :label="$t('label.destinationLocation')"
                      addLabelClasses="text-right"
                      :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-9'}"
                      :placeholder="$t('label.destinationLocation')"
                      :is-valid="hasError($v.Destination)"
                      :invalid-feedback="errorMessage($v.Destination)"
                      :disabled="validateTypeYard"
                    />
                    <CSelect
                      v-else
                      size="sm"
                      v-uppercase
                      v-model="$v.YardDestinyId.$model"
                      :options="YardWarehouseOptions"
                      :label="$t('label.destinationLocation')"
                      addLabelClasses="text-right required"
                      :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-9'}"
                      :placeholder="$t('label.select')"
                      :is-valid="hasError($v.YardDestinyId)"
                      :invalid-feedback="errorMessage($v.YardDestinyId)"
                      :disabled="validateTypeYard"
                    />
                    <CSelect
                      v-if="isEdit"
                      size="sm"
                      v-uppercase
                      :options="statusOptions"
                      :value.sync="Status"
                      :label="$t('label.status')"
                      :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-9'}"
                      addLabelClasses="required text-right"
                      :is-valid="statusSelectColor"
                      :disabled="((ContainerItem?.YardId != YardId) || YardId == undefined || !ContainerItem.FgActYardCargo)"
                    />
                  </CCol>
                  <CCol sm="12" lg="6">
                    <CInput
                      size="sm"
                      v-uppercase
                      v-model="$v.SearchDriver.$model"
                      label="CI"
                      :addLabelClasses="ValidateVisitcargoId ?  'required text-right' : 'text-right'"
                      :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
                      placeholder="CI"
                      :is-valid="hasError($v.DriverId)"
                      :invalid-feedback="errorMessage($v.DriverId)"
                      :disabled="validateTypeYard"
                    >
                      <template #prepend>
                        <CSelect
                          size="sm"
                          v-uppercase
                          :value.sync="numberIdLetter"
                          :options="CiTypeOptions"
                          class="mr-2 mb-0"
                          :is-valid="hasError($v.DriverId)"
                          :disabled="validateTypeYard"
                        />
                      </template>
                      <template #append>
                        <div class="d-flex align-items-start">
                          <CButton
                            color="watch"
                            square
                            size="sm"
                            class="mr-1"
                            style="padding: 0.15rem 0.4rem;"
                            @click="getDriverByCi"
                            :disabled="validateTypeYard"
                          >
                            <CIcon name="cil-search"/>
                          </CButton>
                          <CButton
                            v-if="Object.keys(diverSelected).length>1"
                            color="edit"
                            square
                            size="sm"
                            style="padding: 0.15rem 0.4rem;"
                            @click="(isEditMaster=true, ModalNewDriver=true)"
                            :disabled="validateTypeYard"
                          >
                            <CIcon name="pencil" />
                          </CButton>
                        </div>
                      </template>
                    </CInput>
                    <CInput
                      size="sm"
                      v-uppercase
                      v-model="DriverName"
                      :label="$t('label.driver')"
                      :addLabelClasses="ValidateVisitcargoId ?  'required text-right' : 'text-right'"
                      :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
                      :placeholder="$t('label.driver')"
                      :is-valid="hasError($v.DriverId)"
                      :invalid-feedback="errorMessage($v.DriverId)"
                      :disabled="true"
                    />
                    <CInput
                      size="sm"
                      v-uppercase
                      v-model="$v.SearchPlate.$model"
                      :label="$t('label.plate')"
                      :addLabelClasses="ValidateVisitcargoId ?  'required text-right' : 'text-right'"
                      :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
                      :placeholder="$t('label.plate')"
                      :is-valid="hasError($v.VehicleId)"
                      :invalid-feedback="errorMessage($v.VehicleId)"
                      :disabled="validateTypeYard"
                    >
                      <template #append>
                        <div class="d-flex align-items-start">
                          <CButton
                            color="watch"
                            square
                            size="sm"
                            class="mr-1"
                            style="padding: 0.15rem 0.4rem;"
                            @click="getVehicleByLicensePlate"
                            :disabled="validateTypeYard"
                          >
                            <CIcon name="cil-search"/>
                          </CButton>
                          <CButton
                            v-if="Object.keys(objectVehicle).length>1"
                            color="edit"
                            square
                            size="sm"
                            style="padding: 0.15rem 0.4rem;"
                            @click="(isEditMaster=true, ModalNewVehicle=true)"
                            :disabled="validateTypeYard"
                          >
                            <CIcon name="pencil" />
                          </CButton>
                        </div>
                      </template>
                    </CInput>
                    <CInput
                      size="sm"
                      v-uppercase
                      v-model="VehicleName"
                      :label="$t('label.vehicle')"
                      :addLabelClasses="ValidateVisitcargoId ? 'required text-right' : 'text-right'"
                      :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
                      :placeholder="$t('label.vehicle')"
                      :is-valid="hasError($v.VehicleId)"
                      :invalid-feedback="errorMessage($v.VehicleId)"
                      :disabled="true"
                    />
                    <CTextarea
                      size="sm"
                      rows="2"
                      :label="$t('label.observation')"
                      addLabelClasses="text-right"
                      :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
                      :placeholder="$t('label.select')"
                      v-model="$v.Observation.$model"
                      v-uppercase
                      :is-valid="hasError($v.Observation)"
                      :invalid-feedback="errorMessage($v.Observation)"
                      :disabled="validateTypeYard"
                    />
                  </CCol>
                </CRow>
              </CCard>
            </CTab>
          </CTabs>

          <CTabs variant="tabs" :active-tab="0">
            <CTab :title="$t('label.documents')">
              <CCard class="card-border">
                <CRow class="justify-content-center px-3">
                  <CCol sm="12" lg="6">
                    <CInput
                      size="sm"
                      v-uppercase
                      v-model="$v.Eir.$model"
                      label="EIR"
                      addLabelClasses="text-right"
                      :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-9'}"
                      placeholder="EIR"
                      :is-valid="hasError($v.Eir)"
                      :invalid-feedback="errorMessage($v.Eir)"
                      :disabled="validateTypeYard"
                    >
                      <template #append>
                        <div class="d-flex align-items-start">
                          <CButton
                              color="add"
                              square
                              size="sm"
                              style="padding: 0.15rem 0.4rem;"
                              @click="OpenImageModal($t('label.EirDocumentation'), EirDocumentJson, 1)"
                           
                          >
                            <CIcon name="cil-image"/>
                          </CButton>
                        </div>
                      </template>
                    </CInput>
                  </CCol>
                  <CCol sm="12" lg="6">
                    <CInput
                      size="sm"
                      v-uppercase
                      v-model="$v.TicketOut.$model"
                      :label="$t('label.ticketOut')"
                      addLabelClasses="text-right"
                      :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
                      :placeholder="$t('label.ticketOut')"
                      :is-valid="hasError($v.TicketOut)"
                      :invalid-feedback="errorMessage($v.TicketOut)"
                      :disabled="validateTypeYard"
                    >
                      <template #append>
                        <div class="d-flex align-items-start">
                          <CButton
                              color="add"
                              square
                              size="sm"
                              style="padding: 0.15rem 0.4rem;"
                              @click="OpenImageModal($t('label.DocumentationOfTicketOut'), TicketOutDocumentJson, 2)"
                          >
                            <CIcon name="cil-image"/>
                          </CButton>
                        </div>
                      </template>
                    </CInput>
                  </CCol>
                </CRow>
              </CCard>
            </CTab>
          </CTabs>

          <CTabs variant="tabs" :active-tab="0">
          <CTab :title="$t('label.inspectionSeals')">
            <CCard class="card-border">
              <CRow class="justify-content-center px-3">
                <CCol sm="12" lg="6">
                  <div class="form-group form-row">
                    <label class="col-form-label-sm col-sm-12 col-lg-3 mb-0 text-right">{{`${$t('label.Seal')} 1`}}</label>
                    <div class="input-group col-sm-12 col-lg-9">
                      <CInput
                        size="sm"
                        class="select-adjust pr-1"
                        v-uppercase
                        :disabled="true"
                        v-model="$v.Seal.Seal1.Seal.$model"
                        :is-valid="hasError($v.Seal.Seal1.Seal)"
                        style="width: 20%;"
                      >
                      </CInput>
                      <CSelect
                        size="sm"
                        v-uppercase
                        class="col-sm-5 px-0 mb-0"
                        v-model="$v.Seal.Seal1.ConditionSeal.$model"
                        :options="ConditionOptions"
                        :placeholder="$t('label.select')"
                        :is-valid="hasError($v.Seal.Seal1.ConditionSeal)"
                        @change="SelectCondition(Seal.Seal1, 1)"
                        :disabled="validateTypeYard"
                      />
                      <CButton
                        v-if="Seal.Seal1.ConditionSeal && !ConditionWithoutSeal(Seal.Seal1.ConditionSeal)"
                        square
                        size="sm"
                        class="ml-1"
                        @click="(SealJson=Seal.Seal1,ModalSealActive=true)"
                        style="padding: 0.15rem 0.4rem;"
                        :disabled="validateTypeYard"
                      >
                        <CIcon name="dobleChevron"/>
                      </CButton>
                    </div>
                  </div>

                  <div class="form-group form-row">
                    <label class="col-form-label-sm col-sm-12 col-lg-3 mb-0 text-right">{{`${$t('label.Seal')} 2`}}</label>
                    <div class="input-group col-sm-12 col-lg-9">
                      <CInput
                        size="sm"
                        class="select-adjust pr-1"
                        v-uppercase
                        :disabled="true"
                        v-model="$v.Seal.Seal2.Seal.$model"
                        :is-valid="hasError($v.Seal.Seal2.Seal)"
                        style="width: 20%;"
                      >
                      </CInput>
                      <CSelect
                        size="sm"
                        v-uppercase
                        class="col-sm-5 px-0 mb-0"
                        v-model="$v.Seal.Seal2.ConditionSeal.$model"
                        :options="ConditionOptions"
                        :placeholder="$t('label.select')"
                        :is-valid="hasError($v.Seal.Seal2.ConditionSeal)"
                        @change="SelectCondition(Seal.Seal2, 2)"
                        :disabled="validateTypeYard"
                      />
                      <CButton
                        v-if="Seal.Seal2.ConditionSeal && !ConditionWithoutSeal(Seal.Seal2.ConditionSeal)"
                        square
                        size="sm"
                        class="ml-1"
                        @click="(SealJson=Seal.Seal2,ModalSealActive=true)"
                        style="padding: 0.15rem 0.4rem;"
                        :disabled="validateTypeYard"
                      >
                        <CIcon name="dobleChevron"/>
                      </CButton>
                    </div>
                  </div>
                </CCol>
                <CCol sm="12" lg="6">
                  <div class="form-group form-row">
                    <label class="col-form-label-sm col-sm-12 col-lg-3 mb-0 text-right">{{`${$t('label.Seal')} 3`}}</label>
                    <div class="input-group col-sm-12 col-lg-8">
                      <CInput
                        size="sm"
                        class="select-adjust pr-1"
                        v-uppercase
                        :disabled="true"
                        v-model="$v.Seal.Seal3.Seal.$model"
                        :is-valid="hasError($v.Seal.Seal3.Seal)"
                        style="width: 20%;"
                      >
                      </CInput>
                      <CSelect
                        size="sm"
                        v-uppercase
                        class="col-sm-5 px-0 mb-0"
                        v-model="$v.Seal.Seal3.ConditionSeal.$model"
                        :options="ConditionOptions"
                        :placeholder="$t('label.select')"
                        :is-valid="hasError($v.Seal.Seal3.ConditionSeal)"
                        @change="SelectCondition(Seal.Seal3, 3)"
                        :disabled="validateTypeYard"
                      />
                      <CButton
                        v-if="Seal.Seal3.ConditionSeal && !ConditionWithoutSeal(Seal.Seal3.ConditionSeal)"
                        square
                        size="sm"
                        class="ml-1"
                        @click="(SealJson=Seal.Seal3,ModalSealActive=true)"
                        style="padding: 0.15rem 0.4rem;"
                        :disabled="validateTypeYard"
                      >
                        <CIcon name="dobleChevron"/>
                      </CButton>
                    </div>
                  </div>
                  <div class="form-group form-row">
                    <label class="col-form-label-sm col-sm-12 col-lg-3 mb-0 text-right">{{`${$t('label.Seal')} 4`}}</label>
                    <div class="input-group col-sm-12 col-lg-8">
                      <CInput
                        size="sm"
                        class="select-adjust pr-1"
                        v-uppercase
                        :disabled="true"
                        v-model="$v.Seal.Seal4.Seal.$model"
                        :is-valid="hasError($v.Seal.Seal4.Seal)"
                        style="width: 20%;"
                      >
                      </CInput>
                      <CSelect
                        size="sm"
                        v-uppercase
                        class="col-sm-5 px-0 mb-0"
                        v-model="$v.Seal.Seal4.ConditionSeal.$model"
                        :options="ConditionOptions"
                        :placeholder="$t('label.select')"
                        :is-valid="hasError($v.Seal.Seal4.ConditionSeal)"
                        @change="SelectCondition(Seal.Seal4, 4)"
                        :disabled="validateTypeYard"
                      />
                      <CButton
                        v-if="Seal.Seal4.ConditionSeal && !ConditionWithoutSeal(Seal.Seal4.ConditionSeal)"
                        square
                        size="sm"
                        class="ml-1"
                        @click="(SealJson=Seal.Seal4,ModalSealActive=true)"
                        style="padding: 0.15rem 0.4rem;"
                        :disabled="validateTypeYard"
                      >
                        <CIcon name="dobleChevron"/>
                      </CButton>
                    </div>
                  </div>
                </CCol>
                <CCol sm="12" class="text-invalid-feedback text-center" 
                  v-if="!ValidateTpCargoStatus && Validate && !Seal.Seal1.Seal && !Seal.Seal2.Seal && !Seal.Seal3.Seal && !Seal.Seal4.Seal && ValidateVisitcargoId"
                >
                  <label>{{ $t('label.AtLeastSealRequired') }}</label>
                </CCol>
              </CRow>
            </CCard>
          </CTab>
        </CTabs>
      </CCol>
      <CCol sm="12" class="d-flex align-items-center justify-content-end mb-2" v-if="!(this.ContainerItem?.YardId != this.YardId || this.YardId == undefined)">
        <CButton
          square
          color="add"
          class="d-flex align-items-center"
          @click="Submit()"
          :disabled="isSubmit"
        >
          <CIcon name="checkAlt"/>{{$t('button.accept')}}
        </CButton>
      </CCol>
    </CRow>

    <ModalSeal
      :modal="ModalSealActive"
      :SealJson="SealJson"
      :isEdit="isEdit"
      @CloseModal="(ModalSealActive=false, SealJson={})"
      @UpdateSeal="UpdateSeal"
      @UpdateRegister="UpdateRegister"
    />

    <ModalImages
      :modal="ModalImagesActive"
      :ImagesJson="ImagesJson"
      :CargoJson.async="CargoJson"
      :DocumentType="DocumentType"
      :isEdit="isEdit"
      :isInfo="validateTypeYard"
      :titleModal="TitleModalImages"
      @CloseModal="(ModalImagesActive=false, ImagesJson=[], TitleModalImages='')"
      @UpdateImages="UpdateImages"
      @UpdateDispatch="$emit('UpdateDispatch')"
    />

    <ModalVehicleOrDriver
      :items="VehicleOrDriverList"
      :Type="TypeList"
      @SelectedInfo="SelectedInfo"
      @CloseModal="(VehicleOrDriverList=[], TypeList=0)"
    />

    <ModalDriver
      :modal="ModalNewDriver"
      :diverSelected="diverSelected"
      :CallMaster="true"
      :editModal="isEditMaster"
      :title="isEditMaster ? `${$t('label.edit')} ${$t('label.driver')} ${diverSelected?.DriverCi??''}` : `${$t('label.nuevo')} ${$t('label.driver')}`"
      @UpdatedDriver="getDriver"
      @updated-modal="(ModalNewDriver=false, isEditMaster=false)"
    />

    <ModalVehiculo
      :modal="ModalNewVehicle"
      :objectVehicle="objectVehicle"
      :CallMaster="true"
      :editModal="isEditMaster"
      :title="isEditMaster ? `${$t('label.edit')} ${$t('label.vehicle')} ${objectVehicle?.LicensePlate??''}` : `${$t('label.nuevo')} ${$t('label.vehicle')}`"
      @UpdatedVehicle="getVehicle"
      @updated-modal="(ModalNewVehicle=false, isEditMaster=false)"
    />

  </div>
</template>
<script>
import ModalMixin from '@/_mixins/modal';
import GeneralMixin from '@/_mixins/general';
import UpperCase from '@/_validations/uppercase-directive';
import DispatchRegisterValidations from '@/_validations/yard-management/container/dispatchRegisterValidations';
import { DateFormater } from '@/_helpers/funciones';
import { mapState } from 'vuex';
import ModalSeal from '../../../modal-seal';
import ModalImages from './modal-images';
import ModalVehicleOrDriver from './modal-vehicle-or-driver-list';
import ModalDriver from '@/pages/choferesvehiculos/chofer/modal-chofer'
import ModalVehiculo from '@/pages/choferesvehiculos/vehiculo/modal-vehiculo'

function data() {
  return {
    isSubmit: false,
    Destination: '',
    YardStatusSuggestTransactionId: '',
    ValidateVisitcargoId: true,
    TransactionList: [],
    MachineId: '',
    MachineList: [],
    TransportId: '',
    TransportList: [],
    YardDestinyId: '',
    YardWarehouseList: [],
    ModalNewDriver: false,
    isEditMaster: false,
    SearchDriver: '',
    numberIdLetter: 'V',
    DriverId: '',
    DriverName: '',
    diverSelected: {},
    ModalNewVehicle: false,
    SearchPlate: '',
    VehicleId: '',
    VehicleName: '',
    objectVehicle: {},
    VehicleOrDriverList: [],
    TypeList: 0,
    Eir: '',
    EirDocumentJson: [],
    TicketOut: '',
    TicketOutDocumentJson: [],
    DocumentType: 0,
    TitleModalImages: '',
    ModalSealActive: false,
    ModalImagesActive: false,
    Validate: false,
    SealJson: {},
    ImagesJson: [],
    Seal: {
      Seal1: {
        index: 1,
        CurrentSeal: '',
        Seal: '',
        NewSeal: '',
        ConditionSeal: '',
        SealAssignmentId: '',
        ObservationSeal: '',
        SealDocumentJson: [],
      },
      Seal2: {
        index: 2,
        CurrentSeal: '',
        Seal: '',
        NewSeal: '',
        ConditionSeal: '',
        SealAssignmentId: '',
        ObservationSeal: '',
        SealDocumentJson: [],
      },
      Seal3: {
        index: 3,
        CurrentSeal: '',
        Seal: '',
        NewSeal: '',
        ConditionSeal: '',
        SealAssignmentId: '',
        ObservationSeal: '',
        SealDocumentJson: [],
      },
      Seal4: {
        index: 4,
        CurrentSeal: '',
        Seal: '',
        NewSeal: '',
        ConditionSeal: '',
        SealAssignmentId: '',
        ObservationSeal: '',
        SealDocumentJson: [],
      },
    },
    ConditionList: [],
    Observation: '',
    ValidPreviousDate: '',
    ValidLaterDate: '',
    previousDate: '',
    laterDate: '',
    TransactionDate: '',
    CargoJson: {},
    Status: 0,
  };
}

async function getYardStatusSuggestTransaction() {
  await this.$http.get(`YardStatusSuggestTransaction-by-YardCargoId?YardCargoId=${this.ContainerItem.YardCargoId}&VisitCargoId=${''}&CargoId=${this.ContainerItem.CargoId}&FgInsert=${this.isEdit? 0 : 1}&EventId=${process.env.VUE_APP_EVENT_DISPATCH}`)
    .then(response => {
      let List = response.data.data;
      this.TransactionList = List&&List.length!=0 ? List : [];
    })
    .catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
}

async function SealConditionList() {
  await this.$http.get('SealCondition-list')
    .then(response => {
      let List = response.data.data;
      this.ConditionList = List&&List.length!=0 ? List : [];
    })
    .catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
}

async function YardMachine() {
  
  await this.$http.get('MachineYard-list', { YardId: this.ContainerItem.YardId, Filter: 'ACTIVO', CompanyBranchId: this.CompanyBranchId })
    .then(response => {
      let List = response.data.data;
      this.MachineList = List&&List.length!=0 ? List : [];
    })
    .catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
}

async function ClientListByActivity() {
  await this.$http.get('Client-list-by-activity', { TpClientId: process.env.VUE_APP_TP_CLIENT_LAND_TRANSPORT, Filter: 'ACTIVO' })
    .then(response => {
      let List = response.data.data;
      this.TransportList = List&&List.length!=0 ? List : [];
    })
    .catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
}

async function getDriverByCi() {
  if (this.SearchDriver.length <= 10) {
    this.$store.state.yardManagement.loading = true;
    await this.$http.get('Driver-by-Ci', { DriverCi: (this.numberIdLetter+this.SearchDriver) })
      .then(response => {
        let List = response.data.data;
        if (List&&List.length!=0) {
          if (List.length==1) {
            this.numberIdLetter = List[0]?.DriverCi?.charAt(0) ?? '';
            this.SearchDriver = List[0].DriverCi?.slice(1) ?? '';
            this.DriverId = List[0].DriverId;
            this.DriverName = List[0].DriverName;
            this.diverSelected = List[0];
          }else{
            this.TypeList = 2;
            this.DriverId = '';
            this.DriverName = '';
            this.diverSelected = {};
            this.VehicleOrDriverList = List;
          }
        }else{
          this.DriverId = '';
          this.DriverName = '';
          this.diverSelected = {DriverCi: (this.numberIdLetter+this.SearchDriver)};
          this.ModalNewDriver = true;
        }
      })
      .catch( err => {
        this.DriverId = '';
        this.DriverName = '';
        this.diverSelected = {};
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: err,
          type: "error"
        });
      })
      .finally(()=> {
        this.$store.state.yardManagement.loading = false;
      });
  }else{
    this.DriverId = '';
    this.DriverName = '';
    this.diverSelected = {};
    this.VehicleOrDriverList = [];
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: `${this.$t('validation.max')} ${10} ${this.$t('validation.chars')}`,
      type: "error"
    });
  }
}

async function getVehicleByLicensePlate() {
  if (this.SearchPlate&&this.SearchPlate.length <= 10) {
    this.$store.state.yardManagement.loading = true;
    await this.$http.get('Vehicle-by-LicensePlate', { LicensePlate: this.SearchPlate })
      .then(response => {
        let List = response.data.data;
        if (List&&List.length!=0) {
          if (List.length==1) {
            let Vehicle = List[0];
            let _lang = (this.$i18n.locale)?.charAt(0)?.toUpperCase() + (this.$i18n.locale)?.slice(1) ?? '';
            this.SearchPlate = Vehicle.LicensePlate;
            this.VehicleId = Vehicle.VehicleId;
            this.VehicleName = `${Vehicle['TpVehicleName'+_lang] ?? 'N/A'} - ${Vehicle['VehicleClassifName'+_lang] ?? 'N/A'}`;
            this.objectVehicle = List[0];
          }else{
            this.TypeList = 1;
            this.VehicleId = '';
            this.VehicleName = '';
            this.objectVehicle = {};
            this.VehicleOrDriverList = List;
          }
        }else{
          this.VehicleId = '';
          this.VehicleName = '';
          this.objectVehicle = { LicensePlate: this.SearchPlate };
          this.ModalNewVehicle = true;
        }
      })
      .catch( err => {
        this.VehicleId = '';
        this.VehicleName = '';
        this.objectVehicle = {};
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: err,
          type: "error"
        });
      })
      .finally(()=> {
        this.$store.state.yardManagement.loading = false;
      });
  }else{
    this.TypeList = 1;
    this.VehicleId = '';
    this.VehicleName = '';
    this.objectVehicle = {};
    this.VehicleOrDriverList = [];
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: `${this.$t('validation.max')} ${10} ${this.$t('validation.chars')}`,
      type: "error"
    });
  }
}

async function getYardInternalTransferList(loading = false) {
  this.$store.state.yardManagement.loading = true;
  await this.$http.get('YardInternalTransfer-list', { CompanyBranchId: this.CompanyBranchId, YardCargoId: this.ContainerItem?.YardCargoId, BlCargoId: this.ContainerItem?.BlCargoId ?? ''})
    .then(response => {
      this.YardWarehouseList = response.data.data ?? [];
    })
    .catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
    .finally(() => {
      this.$store.state.yardManagement.loading = loading;
    });
}

function Submit() {
  try { 
    this.isSubmit = true;
    this.$store.state.yardManagement.loading = true;
    this.Validate = true;
    this.$v.$touch();
    if (this.$v.$error){
      if (this.TransactionDate && DateFormater.formatDateTimeWithoutSlash(this.TransactionDate) < this.ValidPreviousDate ) {
        throw this.$t('label.TheDateCannotBeLessThanTheDateOfReception');
      }
      if (this.TransactionDate && DateFormater.formatDateTimeWithoutSlash(this.TransactionDate) > this.ValidLaterDate ) {
        throw this.$t('label.ErrorCurrentDatedPleaseCheck');
      }
      throw this.$t('label.errorsPleaseCheck');
    }


    let CargoJson = {
      YardCargoId: this.ContainerItem.YardCargoId,
      YardStatusSuggestTransactionId: this.YardStatusSuggestTransactionId ?? '',
      MachineId: this.MachineId,
      EirNumber: this.Eir,
      EirDocumentJson: this.EirDocumentJson.map(Document => {
        return{
          YardCargoEventDetailDocumentId: Document.YardCargoEventDetailDocumentId ?? '',
          DocumentRoute: Document.DocumentRoute ?? '',
          FgActYardCargoEventDetailDoc: 1,
          Status: 1,
        } 
      }),
      OutPass: this.TicketOut,
      OutPassDocumentJson: this.TicketOutDocumentJson.map(Document => {
        return{
          YardCargoEventDetailDocumentId: Document.YardCargoEventDetailDocumentId ?? '',
          DocumentRoute: Document.DocumentRoute ?? '',
          FgActYardCargoEventDetailDoc: 1,
          Status: 1,
        } 
      }),
      CarrierId: this.TransportId,
      DriverId: this.DriverId,
      VehicleId: this.VehicleId,
      Destiny: this.Destination,
      YardDestinyId: !this.ValidateVisitcargoId ? this.YardDestinyId : '',
      TransactionDate: DateFormater.formatDateTimeWithoutSlash(this.TransactionDate),
      Seal1: this.Seal.Seal1.Seal,
      Seal1ConditionId: this.Seal.Seal1.ConditionSeal,
      Seal1AssignmentId: this.Seal.Seal1.SealAssignmentId,
      ObservationSeal1: this.Seal.Seal1.ObservationSeal,
      Seal1DocumentJson: this.ConditionWithoutSeal(this.Seal.Seal1.ConditionSeal) ? [] :  
        this.Seal?.Seal1?.SealDocumentJson?.map(Document => {
          return{
            SealRoute: Document.SealRoute ?? '',
            FgActDocument: 1,
            Status: 1,
          } 
        }),
      Seal2: this.Seal.Seal2.Seal,
      Seal2ConditionId: this.Seal.Seal2.ConditionSeal,
      Seal2AssignmentId: this.Seal.Seal2.SealAssignmentId,
      ObservationSeal2: this.Seal.Seal2.ObservationSeal,
      Seal3DocumentJson: this.ConditionWithoutSeal(this.Seal.Seal2.ConditionSeal) ? [] :  
        this.Seal?.Seal2?.SealDocumentJson?.map(Document => {
          return{
            SealRoute: Document.SealRoute ?? '',
            FgActDocument: 1,
            Status: 1,
          } 
        }),
      Seal3: this.Seal.Seal3.Seal,
      Seal3ConditionId: this.Seal.Seal3.ConditionSeal,
      Seal3AssignmentId: this.Seal.Seal3.SealAssignmentId,
      ObservationSeal3: this.Seal.Seal3.ObservationSeal,
      Seal3DocumentJson: this.ConditionWithoutSeal(this.Seal.Seal3.ConditionSeal) ? [] :  
        this.Seal?.Seal3?.SealDocumentJson?.map(Document => {
          return{
            SealRoute: Document.SealRoute ?? '',
            FgActDocument: 1,
            Status: 1,
          } 
        }),
      Seal4: this.Seal.Seal4.Seal,
      Seal4ConditionId: this.Seal.Seal4.ConditionSeal,
      Seal4AssignmentId: this.Seal.Seal4.SealAssignmentId,
      ObservationSeal4: this.Seal.Seal4.ObservationSeal,
      Seal4DocumentJson: this.ConditionWithoutSeal(this.Seal.Seal4.ConditionSeal) ? [] :  
        this.Seal?.Seal4?.SealDocumentJson?.map(Document => {
          return{
            SealRoute: Document.SealRoute ?? '',
            FgActDocument: 1,
            Status: 1,
          }
        }),
      Observation: this.Observation.trim(),
    }

    if (this.isEdit) {
      CargoJson.Status = this.Status;
      CargoJson.YardCargoId = this.ContainerItem.YardCargoId;
      CargoJson.VisitCargoInspectionId = this.ContainerItem?.VisitCargoInspectionId ?? '';
    }

    let Method = this.isEdit ? 'PUT' : 'POST'
    let Route = this.isEdit ? 'YardCargoDispatch-update' : 'YardCargoDispatch'

    this.$http.ejecutar(Method, Route, CargoJson, { root: 'CargoJson' })
      .then((response) => {
        this.notifySuccess({text: response.data.data[0].Response});
        this.Reset();
        this.$emit("Update-list", false);
        this.isSubmit = false;
      }).catch((err) => {
        this.isSubmit = false;
        this.$store.state.yardManagement.loading = false;
        this.notifyError({text: err});
      })
  } catch (error) {
    this.isSubmit = false;
    this.$store.state.yardManagement.loading = false;
    this.notifyError({text: error});
  }
}

async function onChangeTransactionId() {
  this.Destination = '';
  this.YardDestinyId = '';
  this.YardWarehouseList = [];
  if (this.YardStatusSuggestTransactionId) {
    let transaction = this.TransactionList.find((item)=>this.YardStatusSuggestTransactionId===item.YardStatusSuggestTransactionId);
    this.ValidateVisitcargoId = transaction?.FgValidOut ?? false;
    if (!this.ValidateVisitcargoId) {
      this.getYardInternalTransferList()
    }
  }else {
    this.ValidateVisitcargoId = true;
  }
}

async function UpdateRegister(images, index) {
  switch (index) {
    case 1:
      this.CargoJson.Seal1DocumentJson = images;
      break;
    case 2:
      this.CargoJson.Seal2DocumentJson = images;
      break;
    case 3:
      this.CargoJson.Seal3DocumentJson = images;
      break;
    case 4:
      this.CargoJson.Seal4DocumentJson = images;
      break;
  }

  await this.$http.put('YardCargoDispatch-update', this.CargoJson, { root: 'CargoJson' })
    .then(async (response) => {
      await $emit('UpdateDispatch');
    })
    .catch((err) => {
      this.$store.state.yardManagement.loading = false;
      this.notifyError({text: err});
    })
}

function statusSelectColor() {
  return this.Status === 1;
}

function SelectCondition(Seal, index) {
  let WithoutSeal = this.ConditionWithoutSeal(Seal.ConditionSeal);
  if (process.env.VUE_APP_SEAL_CONDITION_INTACT == Seal.ConditionSeal.toUpperCase() || !Seal.ConditionSeal ||
    WithoutSeal) {
    let CurrentSeal = {
      index: index,
      CurrentSeal: Seal.CurrentSeal,
      Seal: WithoutSeal ? '' : Seal.CurrentSeal,
      NewSeal: '',
      ConditionSeal: Seal.ConditionSeal,
      SealAssignmentId: '',
      ObservationSeal: '',
      SealDocumentJson: Seal.SealDocumentJson,
    }
    this.UpdateSeal(CurrentSeal);
  }
}

function ConditionWithoutSeal(ConditionSeal) {
  if (typeof ConditionSeal == 'string') {
    return process.env.VUE_APP_SEAL_CONDITION_WITHOUT_SEAL == ConditionSeal.toUpperCase();
  }else{
    return false;
  }
}

function validateDateRange(date) {
  return date < this.previousDate || date > this.laterDate;
}

function UpdateSeal(Update) {
  switch (Update.index) {
    case 1:
      this.Seal.Seal1 = Update;
      break;
    case 2:
      this.Seal.Seal2 = Update;
      break;
    case 3:
      this.Seal.Seal3 = Update;
      break;
    case 4:
      this.Seal.Seal4 = Update;
      break;
    default:
      break;
  }
}

function UpdateImages(Update) {
  switch (this.DocumentType) {
    case 1:{
      this.EirDocumentJson = Update;
      break;
    }
    case 2:{
      this.TicketOutDocumentJson = Update;
      break;
    }
    default:
      break;
  }
}

function OpenImageModal(title, ImagenList, type) {
  this.DocumentType = type;
  this.TitleModalImages = title;
  this.ImagesJson = ImagenList;
  this.ModalImagesActive = true;
}

function SelectedInfo(info, Type) {
  if (Type == 1) {
    let _lang = (this.$i18n.locale)?.charAt(0)?.toUpperCase() + (this.$i18n.locale)?.slice(1) ?? '';
    this.SearchPlate = info.LicensePlate;
    this.VehicleId = info.VehicleId;
    this.VehicleName = `${info['TpVehicleName'+_lang] ?? 'N/A'} - ${info['VehicleClassifName'+_lang] ?? 'N/A'}`; 
    this.objectVehicle = info;
  }else{
    this.numberIdLetter = info.DriverCi?.charAt(0) ?? '';
    this.SearchDriver = info?.DriverCi?.slice(1) ?? '';
    this.DriverId = info.DriverId;
    this.DriverName = info.DriverName;
    this.diverSelected = info;
  }
}

function getDriver(Driver) {
  this.numberIdLetter = Driver.DriverCi?.charAt(0) ?? '';
  this.SearchDriver = Driver?.DriverCi?.slice(1) ?? '';
  this.DriverId = Driver.DriverId;
  this.DriverName = Driver.DriverName;
  this.diverSelected = Driver;
}

function getVehicle(Vehicle) {
  let _lang = (this.$i18n.locale)?.charAt(0)?.toUpperCase() + (this.$i18n.locale)?.slice(1) ?? '';
  this.SearchPlate = Vehicle.LicensePlate;
  this.VehicleId = Vehicle.VehicleId;
  this.VehicleName = `${Vehicle['TpVehicleName'+_lang] ?? 'N/A'} - ${Vehicle['VehicleClassifName'+_lang] ?? 'N/A'}`; 
  this.objectVehicle = Vehicle;
}

async function getdata(val) {
  let TransactionData = this.TransactionList.find(item => item.TpEirTransacId == val.TpEirTransacId);
  this.YardStatusSuggestTransactionId = TransactionData?.YardStatusSuggestTransactionId ?? '';
  this.ValidateVisitcargoId = TransactionData?.FgValidOut ?? false;
  if (!this.ValidateVisitcargoId) {
    this.YardDestinyId = val.YardDestinyId ?? '';
    await this.getYardInternalTransferList(true)
  }
  this.MachineId = val.MachineId;
  this.Eir = val.EirNumber ?? '';
  this.EirDocumentJson = val?.EirDocumentJson?.map(item =>{
    return {
      ...item,
      Status: 1,
    }
  }) ?? [];
  this.TicketOut = val.OutPass ?? '';
  this.TicketOutDocumentJson = val?.OutPassDocumentJson?.map(item =>{
    return {
      ...item,
      Status: 1,
    }
  }) ?? [];
  this.TransportId = val.CarrierId;
  this.numberIdLetter = val.DriverCi?.charAt(0)?.toUpperCase() ?? '';
  this.SearchDriver = val.DriverCi?.slice(1) ?? '';
  this.SearchPlate = val.LicensePlate ?? '';
  this.Destination = val.Destiny ?? '';
  this.TransactionDate = val.DispatchDate;
  this.Observation = val.Observation ?? '';
  this.Status = val.FgActYardCargo ? 1 : 0;
  
  //PRECINTO 1
  this.Seal.Seal1.CurrentSeal = val?.SealJson[0]?.Seal1Json[0]?.Seal ?? '';
  this.Seal.Seal1.Seal = val?.SealJson[0]?.Seal1Json[0]?.Seal ?? '';
  this.Seal.Seal1.NewSeal = val?.SealJson[0]?.Seal1Json[0]?.Seal ?? '';
  this.Seal.Seal1.ConditionSeal = val?.SealJson[0]?.Seal1Json[0]?.SealConditionId ?? process.env.VUE_APP_SEAL_CONDITION_WITHOUT_SEAL;
  this.Seal.Seal1.SealAssignmentId = val?.SealJson[0]?.Seal1Json[0]?.SealAssignmentId ?? '';
  this.Seal.Seal1.ObservationSeal = val?.SealJson[0]?.Seal1Json[0]?.ObservationSeal ?? '';
  this.Seal.Seal1.SealDocumentJson = val?.SealJson[0]?.Seal1Json[0]?.SealDocumentJson ?? [];
  //PRECINTO 2
  this.Seal.Seal2.CurrentSeal = val?.SealJson[0]?.Seal2Json[0]?.Seal ?? '';
  this.Seal.Seal2.Seal = val?.SealJson[0]?.Seal2Json[0]?.Seal ?? '';
  this.Seal.Seal2.NewSeal =val?.SealJson[0]?.Seal2Json[0]?.Seal ?? '';
  this.Seal.Seal2.ConditionSeal = val?.SealJson[0]?.Seal2Json[0]?.SealConditionId ?? process.env.VUE_APP_SEAL_CONDITION_WITHOUT_SEAL;
  this.Seal.Seal2.SealAssignmentId = val?.SealJson[0]?.Seal2Json[0]?.SealAssignmentId ?? '';
  this.Seal.Seal2.ObservationSeal = val?.SealJson[0]?.Seal2Json[0]?.ObservationSeal ?? '';
  this.Seal.Seal2.SealDocumentJson = val?.SealJson[0]?.Seal2Json[0]?.SealDocumentJson ?? [];
  //PRECINTO 3
  this.Seal.Seal3.CurrentSeal = val?.SealJson[0]?.Seal3Json[0]?.Seal ?? '';
  this.Seal.Seal3.Seal = val?.SealJson[0]?.Seal3Json[0]?.Seal ?? '';
  this.Seal.Seal3.NewSeal = val?.SealJson[0]?.Seal3Json[0]?.Seal ?? '';
  this.Seal.Seal3.ConditionSeal = val?.SealJson[0]?.Seal3Json[0]?.SealConditionId ?? process.env.VUE_APP_SEAL_CONDITION_WITHOUT_SEAL;
  this.Seal.Seal3.SealAssignmentId = val?.SealJson[0]?.Seal3Json[0]?.SealAssignmentId ?? '';
  this.Seal.Seal3.ObservationSeal = val?.SealJson[0]?.Seal3Json[0]?.ObservationSeal ?? '';
  this.Seal.Seal3.SealDocumentJson = val?.SealJson[0]?.Seal3Json[0]?.SealDocumentJson ?? [];
  //PRECINTO 4
  this.Seal.Seal4.CurrentSeal = val?.SealJson[0]?.Seal4Json[0]?.Seal ?? '';
  this.Seal.Seal4.Seal = val?.SealJson[0]?.Seal4Json[0]?.Seal ?? '';
  this.Seal.Seal4.NewSeal = val?.SealJson[0]?.Seal4Json[0]?.Seal ?? '';
  this.Seal.Seal4.ConditionSeal = val?.SealJson[0]?.Seal4Json[0]?.SealConditionId ?? process.env.VUE_APP_SEAL_CONDITION_WITHOUT_SEAL;
  this.Seal.Seal4.SealAssignmentId = val?.SealJson[0]?.Seal4Json[0]?.SealAssignmentId ?? '';
  this.Seal.Seal4.ObservationSeal = val?.SealJson[0]?.Seal4Json[0]?.ObservationSeal ?? '';
  this.Seal.Seal4.SealDocumentJson = val?.SealJson[0]?.Seal4Json[0]?.SealDocumentJson ?? [];

  this.CargoJson = {
    YardCargoId: this.ContainerItem.YardCargoId,
    VisitCargoInspectionId: val.VisitCargoInspectionId ?? '',
    YardStatusSuggestTransactionId: this.YardStatusSuggestTransactionId,
    MachineId: this.MachineId,
    EirNumber: this.Eir,
    EirDocumentJson: this.EirDocumentJson,
    OutPass: this.TicketOut,
    OutPassDocumentJson: this.TicketOutDocumentJson,
    CarrierId: this.TransportId,
    DriverId: val.DriverId,
    VehicleId: val.VehicleId,
    Destiny: this.Destination,
    TransactionDate: DateFormater.formatDateTimeWithoutSlash(this.TransactionDate),
    Seal1: this.Seal.Seal1.Seal,
    Seal1ConditionId: this.Seal?.Seal1?.ConditionSeal,
    Seal1AssignmentId: this.Seal.Seal1.SealAssignmentId,
    ObservationSeal1: this.Seal.Seal1.ObservationSeal,
    Seal1DocumentJson: this.ConditionWithoutSeal(this.Seal.Seal1.ConditionSeal) ? [] :
     this.Seal?.Seal1?.SealDocumentJson?.map(Document => {
        return{
          SealRoute: Document.SealRoute ?? '',
          FgActDocument: 1,
          Status: 1,
        } 
      }),
    Seal2: this.Seal.Seal2.Seal,
    Seal2ConditionId: this.Seal.Seal2?.ConditionSeal,
    Seal2AssignmentId: this.Seal.Seal2.SealAssignmentId,
    ObservationSeal2: this.Seal.Seal2.ObservationSeal,
    Seal2DocumentJson: this.ConditionWithoutSeal(this.Seal.Seal2.ConditionSeal) ? [] : 
      this.Seal?.Seal2?.SealDocumentJson?.map(Document => {
        return{
          SealRoute: Document.SealRoute ?? '',
          FgActDocument: 1,
          Status: 1,
        } 
      }),
    Seal3: this.Seal.Seal3.Seal,
    Seal3ConditionId: this.Seal.Seal3?.ConditionSeal,
    Seal3AssignmentId: this.Seal.Seal3.SealAssignmentId,
    ObservationSeal3: this.Seal.Seal3.ObservationSeal,
    Seal3DocumentJson: this.ConditionWithoutSeal(this.Seal.Seal3.ConditionSeal) ? [] : 
      this.Seal?.Seal3?.SealDocumentJson?.map(Document => {
        return{
          SealRoute: Document.SealRoute ?? '',
          FgActDocument: 1,
          Status: 1,
        } 
      }),
    Seal4: this.Seal.Seal4.Seal,
    Seal4ConditionId: this.Seal.Seal4?.ConditionSeal,
    Seal4AssignmentId: this.Seal.Seal4.SealAssignmentId,
    ObservationSeal4: this.Seal.Seal4.ObservationSeal,
    Seal4DocumentJson: this.ConditionWithoutSeal(this.Seal.Seal4.ConditionSeal) ? [] : 
      this.Seal?.Seal4?.SealDocumentJson?.map(Document => {
        return{
          SealRoute: Document.SealRoute ?? '',
          FgActDocument: 1,
          Status: 1,
        } 
      }),
    Observation: this.Observation.trim(),
    Status: this.Status,
  }
  
  val.DriverCi && val.DriverCi !='' && await this.getDriverByCi();
  val.LicensePlate && val.LicensePlate !='' && await this.getVehicleByLicensePlate();
  this.$v.$touch();
}

function getNewData() {

  let Seal1 = this.ContainerItem.SealJson?.[0].Seal1Json?.[0]
  let Seal2 = this.ContainerItem.SealJson?.[0].Seal2Json?.[0]
  let Seal3 = this.ContainerItem.SealJson?.[0].Seal3Json?.[0]
  let Seal4 = this.ContainerItem.SealJson?.[0].Seal4Json?.[0]
  
  //PRECINTO 1
  this.Seal.Seal1.CurrentSeal = Seal1?.Seal ?? '';
  this.Seal.Seal1.Seal = Seal1?.Seal ?? '';
  this.Seal.Seal1.ConditionSeal = this.Seal.Seal1.Seal ? 
    process.env.VUE_APP_SEAL_CONDITION_INTACT : process.env.VUE_APP_SEAL_CONDITION_WITHOUT_SEAL;
  this.Seal.Seal1.SealDocumentJson = Seal1?.SealDocumentJson ?? [];
  //PRECINTO 2
  this.Seal.Seal2.CurrentSeal = Seal2?.Seal ?? '';
  this.Seal.Seal2.Seal = Seal2?.Seal ?? '';
  this.Seal.Seal2.ConditionSeal = this.Seal.Seal2.Seal ? 
    process.env.VUE_APP_SEAL_CONDITION_INTACT : process.env.VUE_APP_SEAL_CONDITION_WITHOUT_SEAL;
  this.Seal.Seal2.SealDocumentJson = Seal2?.SealDocumentJson ?? [];
  //PRECINTO 3
  this.Seal.Seal3.CurrentSeal = Seal3?.Seal ?? '';
  this.Seal.Seal3.Seal = Seal3?.Seal ?? '';
  this.Seal.Seal3.ConditionSeal = this.Seal.Seal3.Seal ? 
    process.env.VUE_APP_SEAL_CONDITION_INTACT : process.env.VUE_APP_SEAL_CONDITION_WITHOUT_SEAL;
  this.Seal.Seal3.SealDocumentJson = Seal3?.SealDocumentJson ?? [];
  //PRECINTO 4
  this.Seal.Seal4.CurrentSeal = Seal4?.Seal ?? '';
  this.Seal.Seal4.Seal = Seal4?.Seal ?? '';
  this.Seal.Seal4.ConditionSeal = this.Seal.Seal4.Seal ? 
    process.env.VUE_APP_SEAL_CONDITION_INTACT : process.env.VUE_APP_SEAL_CONDITION_WITHOUT_SEAL;
  this.Seal.Seal4.SealDocumentJson = Seal4?.SealDocumentJson ?? [];

  this.$v.$reset();
}

function getDateRange() {
  let receptionDate = DateFormater.formatDateTimeWithoutSlash(this.ContainerItem.ReceptionDate)
  this.previousDate = DateFormater.formatDateRange(receptionDate);
  this.laterDate = new Date();
  this.ValidPreviousDate = receptionDate;
  this.ValidLaterDate = DateFormater.formatDateTimeWithoutSlash(DateFormater.formatTimeZoneToDateTime(this.laterDate));
}

function Reset() {
  this.CargoJson = {};
  this.Destination = '';
  this.YardStatusSuggestTransactionId = '';
  this.TransactionList = [];
  this.MachineId = '';
  this.MachineList = [];
  this.TransportId = '';
  this.TransportList = [];
  this.YardDestinyId = '';
  this.YardWarehouseList = [];
  this.SearchDriver = '';
  this.numberIdLetter = '';
  this.DriverId = '';
  this.DriverName = '';
  this.diverSelected = {};
  this.SearchPlate = '';
  this.VehicleId = '';
  this.VehicleName = '';
  this.objectVehicle = {};
  this.VehicleOrDriverList = [];
  this.TypeList = 0;
  this.Eir = '';
  this.EirDocumentJson = [];
  this.TicketOut = '';
  this.TicketOutDocumentJson = [];
  this.DocumentType = 0;
  this.TitleModalImages = '';
  this.Validate = false;
  this.SealJson = {};
  this.ImagesJson = [];
  this.Seal = {
    Seal1: {
      index: 1,
      CurrentSeal: '',
      Seal: '',
      NewSeal: '',
      ConditionSeal: '',
      SealAssignmentId: '',
      ObservationSeal: '',
      SealDocumentJson: [],
    },
    Seal2: {
      index: 2,
      CurrentSeal: '',
      Seal: '',
      NewSeal: '',
      ConditionSeal: '',
      SealAssignmentId: '',
      ObservationSeal: '',
      SealDocumentJson: [],
    },
    Seal3: {
      index: 3,
      CurrentSeal: '',
      Seal: '',
      NewSeal: '',
      ConditionSeal: '',
      SealAssignmentId: '',
      ObservationSeal: '',
      SealDocumentJson: [],
    },
    Seal4: {
      index: 4,
      CurrentSeal: '',
      Seal: '',
      NewSeal: '',
      ConditionSeal: '',
      SealAssignmentId: '',
      ObservationSeal: '',
      SealDocumentJson: [],
    },
  };
  this.ConditionList = [];
  this.Observation = '';
  this.ValidLaterDate = '';
  this.previousDate = '';
  this.laterDate = '';
  this.TransactionDate = '';
  this.Status = 0;
  this.$v.$reset();
  this.$emit("Close");
}

//Computeds:
function TransactionOptions() {
  if(this.TransactionList.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    let chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    let _lang = this.$i18n.locale;
    this.TransactionList.map(function(e){
      chart.push({
        value: e.YardStatusSuggestTransactionId,
        label: _lang=='en'? e.TpTransacEirNameEn : e.TpTransacEirNameEs,
      })
    })
    return chart;
  }
}

function MachineOptions() {
  if(this.MachineList.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    let chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.MachineList.map(function(e){
      chart.push({
        value: e.MachineId,
        label: e.MachineName,
      })
    })
    return chart;
  }
}

function TransportOptions() {
  if(this.TransportList.length === 0){
    return [{
      title: '', 
      value: this.$t('label.select'),
    }];
  }else{
    let chart = [{
      title: this.$t('label.select'), 
      value: '',
    }]
    this.TransportList.map(function(e){
      chart.push({
        title: e.ClientAlias,
        value: e.ClientTpId,
        Json: {
                  ...e
              }
      })
    })
    return chart;
  }
}

function YardWarehouseOptions() {
  let chart = [{
    label: this.$t('label.select'), 
    value: '',
  }]
  this.YardWarehouseList.map(function(e){
    chart.push({
      label: e.YardName,
      value: e.YardId,
    })
  })
  return chart;
}

function ConditionOptions() {
  if(this.ConditionList.length === 0){
    return [{
      value: '', 
      label: this.$t('label.condition'),
    }];
  }else{
    let chart = [{
      value: '', 
      label: this.$t('label.condition'),
    }]
    let _lang = this.$i18n.locale;
    this.ConditionList.map(function(e){
      chart.push({
        value: e.SealConditionId,
        label: _lang=='en' ? e.SealConditionNameEn : e.SealConditionNameEs,
      })
    })
    return chart;
  }
}

function CiTypeOptions() {
  return [
    {
      value: "V",
      label: "V",
    },
    {
      value: "P",
      label: "P",
    },
    {
      value: "E",
      label: "E",
    },
    {
      value: "J",
      label: "J",
    },
  ];
}

function ValidateTpCargoStatus() {
  return process.env.VUE_APP_TP_CARGO_STATUS_EMPTY == this.ContainerItem?.TpCargoStatusId?.toUpperCase();
}

function VerifySeal() {
  if (this.ValidateTpCargoStatus) {
    return {
      ConditionSeal1: false,
      ConditionSeal2: false,
      ConditionSeal3: false,
      ConditionSeal4: false,
    }
  } else if (!this.Seal.Seal1.Seal && !this.Seal.Seal2.Seal && !this.Seal.Seal3.Seal && !this.Seal.Seal4.Seal) {
      return {
        ConditionSeal1: true,
        ConditionSeal2: true,
        ConditionSeal3: true,
        ConditionSeal4: true,
      }
  } else {
    return {
      ConditionSeal1: this.Seal.Seal1.ConditionSeal&&this.ConditionWithoutSeal(this.Seal.Seal1.ConditionSeal) ? false : true,
      ConditionSeal2: this.Seal.Seal2.ConditionSeal&&this.ConditionWithoutSeal(this.Seal.Seal2.ConditionSeal) ? false : true,
      ConditionSeal3: this.Seal.Seal3.ConditionSeal&&this.ConditionWithoutSeal(this.Seal.Seal3.ConditionSeal) ? false : true,
      ConditionSeal4: this.Seal.Seal4.ConditionSeal&&this.ConditionWithoutSeal(this.Seal.Seal4.ConditionSeal) ? false : true,
    }
  }
}

function validateTypeYard(){ 
    return  (((this.ContainerItem?.YardId != this.YardId) && (this.YardId == undefined)) || this.isEdit)
}

export default {
  name: 'equipment-register',
  components: {
    ModalSeal,
    ModalImages,
    ModalVehicleOrDriver,
    ModalDriver,
    ModalVehiculo,
  },
  props: { Active: Boolean, ContainerItem: Object, isEdit: Boolean},
  data,
  mixins: [
    ModalMixin,
    GeneralMixin
  ],
  validations(){
    return DispatchRegisterValidations(this.VerifySeal, this.ValidPreviousDate, this.ValidLaterDate, this.ValidateVisitcargoId);
  },
  directives: UpperCase,
  methods: {
    getYardStatusSuggestTransaction,
    SealConditionList,
    YardMachine,
    ClientListByActivity,
    getDriverByCi,
    getVehicleByLicensePlate,
    getYardInternalTransferList,
    Submit,
    onChangeTransactionId,
    UpdateRegister,
    statusSelectColor,
    SelectCondition,
    ConditionWithoutSeal,
    validateDateRange,
    UpdateSeal,
    UpdateImages,
    OpenImageModal,
    SelectedInfo,
    getDriver,
    getVehicle,
    getdata,
    getNewData,
    getDateRange,
    Reset,
  },
  computed: {
    TransactionOptions,
    validateTypeYard,
    MachineOptions,
    TransportOptions,
    YardWarehouseOptions,
    ConditionOptions,
    CiTypeOptions,
    ValidateTpCargoStatus,
    VerifySeal,
    ...mapState({
      dataContainer: state => state.yardManagement.dataContainer,
      dropContainerMovement: state => state.yardManagement.dropContainerMovement,
      CompanyBranchId: state => state.auth.branch.CompanyBranchId,
      dataBl: state => state.yardManagement.dataBl,
      YardId: state => state.yardManagement.yardData.YardId,
    })
  },
  watch: {
    Active: async function(val){
      if (val) {

        this.$store.state.yardManagement.loading = true;
        await this.getDateRange();
        await this.getYardStatusSuggestTransaction();
        await this.SealConditionList();
        await this.YardMachine();
        await this.ClientListByActivity();
        if (this.isEdit) {
          await this.getdata(this.ContainerItem);
        } else {
          await this.getNewData();
        }

        this.$store.state.yardManagement.loading = false;
      }
    },
    TransactionDate: async function(val){
      try{
        if(val){
          if (DateFormater.formatDateTimeWithoutSlash(val) < this.ValidPreviousDate ) {
            throw this.$t('label.TheDateCannotBeLessThanTheDateOfReception');
          }
          if (DateFormater.formatDateTimeWithoutSlash(val) > this.ValidLaterDate ) {
            throw this.$t('label.ErrorCurrentDatedPleaseCheck');
          }
        }
      }catch(e){
        this.notifyError({text: e});
      }
    },
    dropContainerMovement: function (NewVal, OldVal) {
      if (OldVal == 2) {
        this.Reset();
      }
    },
  },
};
</script>
<style lang="scss">

.select-driver--correct {
    .vs__dropdown-toggle {
        border-color: #2eb85c !important;
    }
}
.select-driver--error {
    .vs__dropdown-toggle {
        border-color: #e55353 !important;
    }
}

</style>
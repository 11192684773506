<template>
  <CRow class="justify-content-center m-0 mt-3">
    <CCol v-if="!fgInfo" sm="12">
      <CCol sm="12" class="mb-2">
        <label class="col-sm-12 col-lg-auto px-0 m-0 text-right">
          <b>{{$t('label.packaging')}}</b>
        </label>
      </CCol>
      <CRow class="m-0">
        <CCol sm="12" lg="3">
          <div class="form-group form-row">
            <label class="text-right col-sm-12 col-lg-6 required col-form-label-sm mb-0">{{`${$t('label.quantity')} ${$t('label.AbbreviatedPlanned')}`}}</label>
            <div class="input-group col-sm-12 col-lg-6 input-group-sm">
              <money
                size="sm"
                v-bind="measure" 
                class="form-control"
                v-model="Packaging.Quantity"
                maxlength= "13"
                disabled
              >
              </money>
            </div>
          </div>
        </CCol>
        <CCol sm="12" lg="3">
          <div class="form-group form-row">
            <label class="text-right col-sm-12 col-lg-6 col-form-label-sm mb-0">{{`${$t('label.quantity')} ${$t('label.AbbreviatedReception')}`}}</label>
            <div class="input-group col-sm-12 col-lg-6 input-group-sm">
              <money
                size="sm"
                v-bind="measure" 
                class="form-control"
                v-model="Packaging.QuantityReceived"
                addLabelClasses="text-right"
                maxlength= "13"
                disabled
              >
              </money>
            </div>
          </div>
        </CCol>
        <CCol sm="12" lg="3">
          <div class="form-group form-row">
            <label class="text-right col-sm-12 col-lg-6 col-form-label-sm mb-0">{{`${$t('label.Serialized')}`}}</label>
            <div class="input-group col-sm-12 col-lg-6 input-group-sm">
              <money
                size="sm"
                v-bind="measure" 
                class="form-control"
                v-model="Packaging.SerializedQuantity"
                maxlength= "13"
                disabled
              >
              </money>
            </div>
          </div>
        </CCol>
        <CCol sm="12" lg="3">
          <div class="form-group form-row">
            <label class="text-right col-sm-12 col-lg-6 col-form-label-sm mb-0">{{`${$t('label.weight')} (KGM)`}}</label>
            <div class="input-group col-sm-12 col-lg-6 input-group-sm">
              <money
                size="sm"
                v-bind="measure" 
                class="form-control"
                v-model="Packaging.Weight"
                maxlength= "13"
                disabled
              >
              </money>
            </div>
          </div>
        </CCol>
        <CCol sm="12" lg="3">
          <div class="form-group form-row">
            <label class="text-right col-sm-12 col-lg-6 col-form-label-sm mb-0">{{`${$t('label.volume')} (M³)`}}</label>
            <div class="input-group col-sm-12 col-lg-6 input-group-sm">
              <money
                size="sm"
                v-bind="measure" 
                class="form-control"
                v-model="Packaging.Volumen"
                maxlength= "13"
                disabled
              >
              </money>
            </div>
          </div>
        </CCol>
      </CRow>
    </CCol>

    <CCol v-if="!fgInfo" sm="12">
      <CTabs :active-tab="TabIndex" @update:activeTab="TabIndex=$event" variant="tabs" class="m-0">
        <CTab :title="$t('label.generalInfo')" :class="vForm.serialization.$anyError ? 'tab-error' : ''">
          <div class="border border-top-0 rounded-bottom pt-3 m-0">
            <CRow class="m-0">
              <CCol sm="12" class="px-2">
                <div class="form-group form-row">
                  <label class="text-right col-sm-12 col-lg-auto col-form-label-sm mb-0">{{`${$t('label.automaticCalculation')} (M³)`}}</label>
                  <div class="col-sm-12 col-lg-auto d-flex justify-content-end">
                    <CSwitch
                      color="watch"
                      variant="3d"
                      type="checkbox"
                      :checked.sync="FgAutomatic"
                      @update:checked="form.serialization.Length = 0, form.serialization.Width = 0, form.serialization.Height = 0, form.serialization.Volumen = 0"
                    />
                  </div>
                </div>
              </CCol>
              <CCol sm="12" lg="3" class="px-2">
                <CSelect
                  size="sm"
                  v-uppercase
                  :label="$t('label.packaging')"
                  :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                  :options="PackagingOptions"
                  addLabelClasses="required text-right"
                  v-model="vForm.serialization.PackagingId.$model"
                  :is-valid="hasError(vForm.serialization.PackagingId)"
                  :invalid-feedback="errorMessage(vForm.serialization.PackagingId)" 
                  @change="onChagePackagingId($event.target.value)"
                />
              </CCol>
              <CCol sm="12" lg="3" class="px-2">
                <CInput
                  v-uppercase
                  size="sm"
                  :placeholder="$t('label.commodity')"
                  addLabelClasses="text-right"
                  :label="$t('label.commodity')"
                  :horizontal="{label: 'col-sm-12 col-lg-4', input: 'col-sm-12 col-lg-8'}"
                  v-model="Packaging.CommodityName"
                  disabled
                />
              </CCol>
              <CCol sm="12" lg="3" class="px-2">
                <CInput
                  v-uppercase
                  size="sm"
                  :placeholder="$t('label.serial')"
                  addLabelClasses="text-right required"
                  :label="$t('label.serial')"
                  :horizontal="{label: 'col-sm-12 col-lg-4', input: 'col-sm-12 col-lg-8'}"
                  v-model="vForm.serialization.Serial.$model"
                  :is-valid="hasError(vForm.serialization.Serial)"
                  :invalid-feedback="errorMessage(vForm.serialization.Serial)"
                />
              </CCol>
              <CCol sm="12" lg="3" class="px-2">
                <div class="form-group form-row">
                  <label class="text-right col-sm-12 col-lg-5 required col-form-label-sm mb-0">{{`${$t('label.weight')} (KGM)`}}</label>
                  <div class="input-group col-sm-12 col-lg-7 input-group-sm">
                    <money
                      size="sm"
                      v-bind="QuantityMeasure" 
                      :class="!vForm.serialization.Weight.$dirty ? 'form-control' : (vForm.serialization.Weight.$error ? 'form-control is-invalid' : 'form-control is-valid')"
                      v-model="vForm.serialization.Weight.$model"
                      maxlength= "13"
                    >
                    </money>
                    <div class="invalid-feedback" v-if="vForm.serialization.Weight.$error&&!CleanInformation">
                      {{ errorMessage(vForm.serialization.Weight) }}
                    </div>
                  </div>
                </div>
              </CCol>
              <CCol sm="12" lg="3" class="px-2">
                <div class="form-group form-row">
                  <label class="text-right col-sm-12 col-lg-5 col-form-label-sm mb-0">{{`${$t('label.volume')} (M³)`}}</label>
                  <div class="input-group col-sm-12 col-lg-7 input-group-sm">
                    <money
                      v-if="FgAutomatic"
                      size="sm"
                      v-bind="measure" 
                      :class="!vForm.serialization.Length.$dirty || !vForm.serialization.Width.$dirty || !vForm.serialization.Height.$dirty ? 'form-control' : 'form-control is-valid'"
                      v-model="Meters.Volumen"
                      maxlength= "13"
                      disabled
                    >
                    </money>
                    <money
                      v-else
                      size="sm"
                      v-bind="measure" 
                      :class="!vForm.serialization.Volumen.$dirty ? 'form-control' : (vForm.serialization.Volumen.$error ? 'form-control is-invalid' : 'form-control is-valid')"
                      v-model="vForm.serialization.Volumen.$model"
                      maxlength= "13"
                    >
                    </money>
                    <div class="invalid-feedback" v-if="vForm.serialization.Volumen.$error&&!CleanInformation">
                      {{ errorMessage(vForm.serialization.Volumen) }}
                    </div>
                  </div>
                </div>
              </CCol>
              <CCol sm="12" lg="3" class="px-2">
                <div class="form-group form-row">
                  <label class="text-right col-sm-12 col-lg-4 col-form-label-sm">{{`${$t('label.length')} (M)`}}</label>
                  <div class="input-group col-sm-12 col-lg-8 input-group-sm">
                    <money
                      size="sm"
                      v-bind="measure" 
                      :class="!vForm.serialization.Length.$dirty ? 'form-control' : (vForm.serialization.Length.$error ? 'form-control is-invalid' : 'form-control is-valid')"
                      v-model="vForm.serialization.Length.$model"
                      addLabelClasses="text-right"
                      maxlength= "13"
                      :disabled="!FgAutomatic"
                    >
                    </money>
                    <div class="invalid-feedback" v-if="vForm.serialization.Length.$error&&!CleanInformation">
                      {{ errorMessage(vForm.serialization.Length) }}
                    </div>
                  </div>
                </div>
              </CCol>
              <CCol sm="12" lg="3" class="px-2">
                <div class="form-group form-row">
                  <label class="text-right col-sm-12 col-lg-4 col-form-label-sm">{{`${$t('label.width')} (M)`}}</label>
                  <div class="input-group col-sm-12 col-lg-8 input-group-sm">
                    <money
                      size="sm"
                      v-bind="measure" 
                      :class="!vForm.serialization.Width.$dirty ? 'form-control' : (vForm.serialization.Width.$error ? 'form-control is-invalid' : 'form-control is-valid')"
                      v-model="vForm.serialization.Width.$model"
                      addLabelClasses="text-right"
                      maxlength= "13"
                      :disabled="!FgAutomatic"
                    >
                    </money>
                    <div class="invalid-feedback" v-if="vForm.serialization.Width.$error&&!CleanInformation">
                      {{ errorMessage(vForm.serialization.Width) }}
                    </div>
                  </div>
                </div>
              </CCol>
              <CCol sm="12" lg="3" class="px-2">
                <div class="form-group form-row">
                  <label class="text-right col-sm-12 col-lg-4 col-form-label-sm">{{`${$t('label.height')} (M)`}}</label>
                  <div class="input-group col-sm-12 col-lg-8 input-group-sm">
                    <money
                      size="sm"
                      v-bind="measure" 
                      :class="!vForm.serialization.Height.$dirty ? 'form-control' : (vForm.serialization.Height.$error ? 'form-control is-invalid' : 'form-control is-valid')"
                      v-model="vForm.serialization.Height.$model"
                      addLabelClasses="text-right"
                      maxlength= "13"
                      :disabled="!FgAutomatic"
                    >
                    </money>
                    <div class="invalid-feedback" v-if="vForm.serialization.Height.$error&&!CleanInformation">
                      {{ errorMessage(vForm.serialization.Height) }}
                    </div>
                  </div>
                </div>
              </CCol>
              <CCol sm="12" lg="3" class="px-2">
                <CTextarea
                  :label="$t('label.description')"
                  :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                  size="sm"
                  v-uppercase
                  v-model.trim="vForm.serialization.DescriptionOfGoods.$model"
                  addLabelClasses="d-flex text-right"
                  rows="2"
                  :is-valid="hasError(vForm.serialization.DescriptionOfGoods)"
                  :invalid-feedback="errorMessage(vForm.serialization.DescriptionOfGoods)"
                />
              </CCol>
            </CRow>
          </div>
        </CTab>
        <CTab :title="$t('label.imdg')">
          <div class="border border-top-0 rounded-bottom pt-3 m-0">
            <ImdgTab
              :form="form"
              :imdgItems="imdgItems"
              :Tab="Tab"
              :fgPackagingTab="true"
              :Clean="CleanInformation"
              @deleteImdg="(event) => deleteImdg(event)"
              @imdgUpdateItems="(event)=>{submitImdg(event)} "
            />
          </div>
        </CTab>
      </CTabs>
      <CCol sm="12 px-0">
        <div class="col-sm-12 col-form-label-sm text-right px-0" style="text-align: left;">
          <CButton
            color="add"
            size="sm"
            class="mr-1"
            v-c-tooltip="{content: $t('label.add'), placement: 'top-end'}" 
            :disabled="isSubmit"
            @click="SubmitSerialization"
          >
            <CIcon name="checkAlt"/>
          </CButton>
          <CButton
            color="wipe"
            class="justify-content-end"
            size="sm"
            v-c-tooltip="{content: $t('label.cleanInformation'), placement: 'top-end'}" 
            @click="Clear(false)"
          >
            <CIcon name="cil-brush-alt" />
          </CButton>
        </div>
      </CCol>
    </CCol>

    <CCol sm="12">
      <dataTableExtended
        class="align-center-row-datatable"
        :items="computedSerializationList"
        :fields="serializationfields"
        :items-per-page="5"
        column-filter
        pagination
        :table-filter="tableText.tableFilterText"
        :items-per-page-select="tableText.itemsPerPageText"
        :noItemsView="tableText.noItemsViewText"
        sorter
      >
        <template #Status="{ item }">
          <td class="text-center">
            <CBadge :color="getBadge(item.Status)">
              {{ $t('label.'+item.Status) }}
            </CBadge>
          </td>
        </template>
        <template #Serial="{ item }">
          <td class="text-center">
            {{ `${item.Serial} ${item?.TpCargoDetailCode ? `(${item.TpCargoDetailCode ?? ''})` : ''}`.trim() }}
          </td>
        </template>
        <template #Quantity="{ item }">
          <td class="text-center">
            {{ FormatNumber(item.Quantity, 2) }}
          </td>
        </template>
        <template #QuantityReceived="{ item }">
          <td class="text-center">
            {{ FormatNumber(item.QuantityReceived, 2) }}
          </td>
        </template>
        <template #Weight="{ item }">
          <td class="text-center">
            {{ FormatNumber(item.Weight, 2) }}
          </td>
        </template>
        <template #Volumen="{ item }">
          <td class="text-center">
            {{ FormatNumber(item.Volumen, 2) }}
          </td>
        </template>
        <template #options="{ item }">
          <td class="text-center">
            <CButton 
              v-if="!ValidatePackagingContainer(item.PackagingId) && !fgInfo"
              color="edit"
              square
              size="sm"
              class="mr-1"
              v-c-tooltip="{
                content: $t('label.edit')+' '+$t('label.packaging'),
                placement: 'top-end'
              }"
              @click="EditSerialization(item)"
            >
              <CIcon name="pencil" />
            </CButton>
            <CButton
              v-if="!ValidatePackagingContainer(item.PackagingId) && !fgInfo"
              class="btn btn-sm btn-wipe"
              v-c-tooltip="{
                content: $t('label.delete')+$t('label.packaging'),
                placement: 'top-end',
              }"
              @click="DeletePackaging(item)"
            >
              <CIcon name="cil-trash" />
            </CButton>
          </td>
        </template>
      </dataTableExtended>
      <div class="d-flex justify-content-end text-right fw-bold">
        {{ `${$t('label.totalQuantity')}: ${FormatNumber(form.SerializationList.reduce((accumulator, currentValue) => accumulator + (currentValue?.Status != 0 && currentValue?.Quantity ? currentValue.Quantity : 0), 0), 2)}` }} -
        {{ `${$t('label.totalWeight')}: ${FormatNumber(form.SerializationList.reduce((accumulator, currentValue) => accumulator + (currentValue?.Status != 0 && currentValue?.Weight ? currentValue.Weight : 0), 0), 2)}` }} -
        {{ `${$t('label.TotalVolume')}: ${FormatNumber(form.SerializationList.reduce((accumulator, currentValue) => accumulator + (currentValue?.Status != 0 && currentValue?.Volumen ? currentValue.Volumen : 0), 0), 2)}` }}
      </div>
      <div class="text-invalid-feedback text-center" v-if="vForm.SerializationList.$error">
        {{ $t('validation.RequiredDetail') }}
      </div>
    </CCol>
  </CRow>
</template>
<script>
import ModalMixin from '@/_mixins/modal';
import GeneralMixin from '@/_mixins/general';
import UpperCase from '@/_validations/uppercase-directive';
import ImdgTab from '@/pages/yard-management/container/bl/tabs/imdg-tab';
import { Money } from "v-money";
import { NumberFormater } from "@/_helpers/funciones";

function data() {
  return {
    TabIndex: 0,
    FgAutomatic: true,
    CleanInformation: false,
    isSubmit: false,
    Packaging: {
      Weight: 0,
      Volumen: 0,
      Quantity: 0,
      QuantityReceived: 0,
      SerializedQuantity: 0,
      commodityName: '',
    },
    measure: {
      decimal: ",",
      thousands: ".",
      prefix: "",
      suffix: "",
      precision: 2,
      masked: false,
      maxlength: 18,
    },
    QuantityMeasure: {
      decimal: ",",
      thousands: ".",
      prefix: "",
      suffix: "",
      precision: 0,
      masked: false,
      maxlength: 6,
    },
    imdgItems: [],
  };
}

//methods
function getYardCargoById(Container) {
  this.$store.state.yardManagement.loading = true;
  this.$http.get("YardCargo-by-Id", { YardCargoId: Container.YardCargoId, YardId: Container.YardId })
  .then(response => {
    let Container = response?.data?.data?.[0] ?? undefined;
    this.$store.state.yardManagement.dataContainer.ContainerTabIndex = 0;
    if (Container) {
      this.$store.state.yardManagement.dataContainer = Container;
    }else {
      this.$store.state.yardManagement.dataContainer = {};
    }
  }).catch( err => {
      this.$store.state.yardManagement.dataContainer = {};
      this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
      });
  })
  .finally(() => {
    this.$store.state.yardManagement.loading = false;
  });
}

async function SubmitSerialization() {
  try {
    this.isSubmit = true;
    this.vForm.serialization.$touch();
    if (this.vForm.serialization.$error){
      throw this.$t('label.errorsPleaseCheck');
    }
    
    let PackagingData = this.form.PackagingList.find(item => item.PackagingId == this.form.serialization.PackagingId && item.FgRequiredSerialization && item.Status == 1)

    if (!this.form.serialization.Id && (this.form.SerializationList.filter(item => item.PackagingId == this.form.serialization.PackagingId && item.Status == 1).length == PackagingData.Quantity)) {
      throw this.$t('label.PackagingIsFullySerialized');
    }

    if (this.form.SerializationList.some(item => item.PackagingId == this.form.serialization.PackagingId && item.Serial == this.form.serialization.Serial && item.IdX != this.form.serialization.Id && item.Status == 1)) {
      throw this.$t('label.ItemPreviouslyRegistered');
    }

    let Submit = {
      IdX: this.form.serialization.Id != '' ? this.form.serialization.Id : (this.form.SerializationList.length > 0 ? this.form.SerializationList[this.form.SerializationList.length - 1].IdX + 1 : 1),
      PackagingId: this.form.serialization.PackagingId ?? '',
      PackagingNameEs: PackagingData?.PackagingNameEs ?? '',
      PackagingNameEn: PackagingData?.PackagingNameEn ?? '',
      CommodityName: this.form.serialization.CommodityName ?? '',
      Serial: this.form.serialization.Serial ?? '',
      DescriptionOfGoods: this.form.serialization.DescriptionOfGoods ?? '',  
      Length: NumberFormater.setNum(this.form.serialization.Length),
      Width: NumberFormater.setNum(this.form.serialization.Width),
      Height: NumberFormater.setNum(this.form.serialization.Height),
      Weight: NumberFormater.setNum(this.form.serialization.Weight),
      Volumen: this.FgAutomatic ? NumberFormater.setNum(this.Meters.Volumen) : NumberFormater.setNum(this.form.serialization.Volumen),
      Quantity: 1,
      ImdgJson: this.imdgItems,
      Status: 1,
    }
    if (this.form.serialization.Id=='') {
      this.form.SerializationList.push(Submit);
    }else{
      this.form.SerializationList = this.form.SerializationList.map(item => {
        if (item.IdX == this.form.serialization.Id) {
          return {
            ...item,
            ...Submit,
          }
        }else{
          return item;
        }
      });
    }
    
    if (this.form.serialization.Id=='' ? PackagingData.Quantity == (this.Packaging.SerializedQuantity + 1) : PackagingData.Quantity == this.Packaging.SerializedQuantity) {
      let accumulator = { Weight: 0, Volumen: 0 };
      this.form.SerializationList.map(item => {
        if (item?.Status != 0 && item.PackagingId == this.form.serialization.PackagingId) {
          accumulator.Weight += item.Weight;
          accumulator.Volumen += item.Volumen;
        }
      })
      this.form.PackagingList = this.form.PackagingList.map(item => {
        if (item.PackagingId == this.form.serialization.PackagingId && item.FgRequiredSerialization && item.Status == 1) {
          return {
            ...item,
            Weight: accumulator.Weight,
            Volumen: accumulator.Volumen,
          }
        }else{
          return item;
        }
      })
    }

    await this.Clear(false);
    this.isSubmit = false;
  }catch (error) {
    this.isSubmit = false;
    this.notifyError({text: error});
  }
}

function EditSerialization(item) {
  this.form.serialization = {
    Id: item.IdX,
    PackagingId: item.PackagingId ?? '',
    CommodityName: item.CommodityName ?? '',
    Serial: item.Serial ?? '',
    DescriptionOfGoods: item.DescriptionOfGoods ?? '',
    Length: item.Length ?? 0,
    Width: item.Width ?? 0,
    Height: item.Height ?? 0,
    Weight: item.Weight ?? 0, 
  };
  this.imdgItems = item?.ImdgJson ?? [];
  this.onChagePackagingId(this.form.serialization.PackagingId);
  this.vForm.serialization.$touch();
}

function DeletePackaging(item) {
  this.$swal
    .fire(this.alertProperties({
        text: `${this.$t('label.deleteQuestion')}?`,
    }))
    .then((result) => {
      if (result.isConfirmed) {
        if (item.VisitBillOfLadingPackagingId) {
          this.form.SerializationList = this.form.SerializationList.map(Packaging => {
            if (Packaging.VisitBillOfLadingPackagingId == item.VisitBillOfLadingPackagingId) {
              return {
                ...Packaging,
                Status: 0,
              };
            } else {
              return Packaging;
            }
          });
        }else{
          this.form.SerializationList = this.form.SerializationList.filter(e => e.IdX != item.IdX);
        }
        this.vForm.SerializationList.$touch();
      }
    });
}

function onChagePackagingId(id) {
  this.form.serialization.PackagingId = id;
  if (this.form.serialization.PackagingId) {
    let PackagingData = this.form.PackagingList.find(item => item.PackagingId == this.form.serialization.PackagingId && item.Status == 1);
    this.Packaging = {
      Weight: PackagingData?.Weight ?? 0,
      Volumen: PackagingData?.Volumen ?? 0,
      Quantity: PackagingData?.Quantity ?? 0,
      QuantityReceived: PackagingData?.QuantityReceived ?? 0,
      SerializedQuantity: this.form.SerializationList?.filter(item => item.PackagingId == this.form.serialization.PackagingId && item.Status == 1)?.length,
      CommodityName: PackagingData?.CommodityName ?? '',
    };
  }else{
    this.Packaging = {
      Weight: 0,
      Volumen: 0,
      Quantity: 0,
      QuantityReceived: 0,
      SerializedQuantity: 0,
      CommodityName: '',
    };
  }
}

function submitImdg(item) {
  if (this.form.imdg.Id == 0) 
    this.imdgItems.push(item);
  else
    this.imdgItems = this.imdgItems.map((e) =>{
      if (e.IdX == this.form.imdg.Id) {
        return {
          ...e,
          ...item
        }
      }else {
        return e
      }
    });
}

function deleteImdg(item) {
  this.$swal
    .fire(this.alertProperties({
        text: `${this.$t('label.deleteQuestion')}?`,
    }))
    .then((result) => {
    if (result.isConfirmed) {
      this.imdgItems = this.imdgItems.filter(e => e.IdX != item.IdX);
    }
  });
}

function ValidatePackagingContainer(PackagingId) {
  return PackagingId.toUpperCase() == process.env.VUE_APP_PACKAGING_ID_CONTAINER;
}

function FormatNumber(number, decimal) {
  return NumberFormater.formatNumber(number, decimal);
}

function Clear(All) {
  this.FgAutomatic = true;
  this.CleanInformation = true;
  this.form.serialization = {
    Id: '',
    PackagingId: '',
    CommodityName: '',
    Serial: '',
    DescriptionOfGoods: '',
    Length: 0,
    Width: 0,
    Height: 0,
    Weight: 0, 
    Volumen: 0,
  };
  this.Packaging = {
    Weight: 0,
    Volumen: 0,
    Quantity: 0,
    QuantityReceived: 0,
    SerializedQuantity: 0,
    commodityName: '',
  };
  this.imdgItems = [];
  if (All) {
    this.form.SerializationList = [];
  }
  setTimeout(() => {
    this.vForm.serialization.$reset();
    this.CleanInformation = false;
  }, 10);
}

//computed
function PackagingOptions() {
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  let chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
  this.form.PackagingList.filter(item => item.PackagingId && item.FgRequiredSerialization && item.Status == 1).forEach((e) => {
    chart.push({
      value: e.PackagingId,
      label: e['PackagingName'+_lang],
    })
  })
  return chart;
}

function CommodityOptions() {
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  let chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
  this.CommodityList.map(function(e){
    chart.push({
      value: e.CommodityId,
      label: e['CommodityName'+_lang],
    })    
  })
  return chart;
}

function Meters() {
  return {
    Volumen: NumberFormater.setNum(this.form.serialization.Length) * NumberFormater.setNum(this.form.serialization.Width) * NumberFormater.setNum(this.form.serialization.Height),
  }
}

function computedSerializationList() {
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  let List = this.form.SerializationList.filter(item => item.Status != 0);

  return List.map((item, index) => {
    return {
      ...item,
      Nro: index+1,
      PackagingName: item['PackagingName'+_lang] ?? this.$t('label.BreakBulk'),
      YardCargoBlMasterDetailSerialStatusDs: item?.[`YardCargoBlMasterDetailSerialStatusDs${_lang}`] ?? '',
    }
  });
}

function serializationfields(){
  return [
    { key: 'options', label: '', _style: 'width: 1%; min-width:90px;', sorter: false, filter: false },
    { key: 'Nro', label: '#', _style: 'min-width: 45px;', _classes: 'text-center', filter: false },
    { key: 'PackagingName', label: this.$t('label.packaging'),  _classes: 'text-uppercase text-center', _style: 'min-width: 200px'},
    { key: 'Serial', label: this.$t('label.serial'),  _classes: 'text-uppercase text-center', _style: 'min-width: 180px'},
    { key: 'Quantity', label: this.$t('label.quantity'), _classes: 'text-uppercase text-center', _style: 'min-width: 160px'},
    { key: 'QuantityReceived', label: this.$t('label.ReceivedQuantity'), _classes: 'text-uppercase text-center', _style: 'min-width: 160px'},
    { key: 'Weight', label: `${this.$t('label.weight')} (KGM)`, _style:'min-width: 160px;', _classes: 'text-uppercase text-center'},
    { key: 'Volumen', label: `${this.$t('label.volume')} (M³)`, _style:'min-width: 160px', _classes:"center-cell text-center"},
    { key: 'YardCargoBlMasterDetailSerialStatusDs', label: this.$t('label.status'), _classes: 'text-uppercase text-center text-bold', _style: 'min-width: 180px'},
  ];
}

export default {
  name: 'serialization-tab',
  components: {
    ImdgTab,
    Money
  },
  data,
  props: {
    form: {
      type: Object,
      required: true,
      default: () => {},
    },
    CommodityList: {
      type: Array,
      default: () => [],
    },
    vForm: Object,
    showModal: Boolean,
    isEdit: Boolean,
    Tab: Boolean,
    fgInfo: Boolean,
  },
  mixins: [
    ModalMixin, 
    GeneralMixin
  ],
  directives: UpperCase,
  methods: {
    getYardCargoById,
    SubmitSerialization,
    EditSerialization,
    DeletePackaging,
    onChagePackagingId,
    submitImdg,
    deleteImdg,
    ValidatePackagingContainer,
    FormatNumber,
    Clear,
  },
  computed:{
    PackagingOptions,
    CommodityOptions,
    Meters,
    computedSerializationList,
    serializationfields,
  },
  watch: {
    showModal: function (Newval) {
      if (!Newval) {
        this.TabIndex = 0;
        this.Clear(true);
      }
    },
    Tab: function (Newval) {
      if (Newval) {

      }
    },
  }
};
</script>
<template>
  <CModalExtended
    :title="$t('label.Implement')"
    color="dark"
    size="lg"
    :show.sync="modalActive"
    :closeOnBackdrop="false"
    @update:show="resetForm(true)"
  >
    <div>
      <CRow class="m-0">
        <CCol sm="12" lg="6">
          <CSelect
            size="sm"
            v-uppercase
            :label="$t('label.group')"
            :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
            :options="GpoImplementOptions"
            addLabelClasses="required text-right"
            v-model="vForm.Implement.GpoImplementId.$model"
            :is-valid="hasError(vForm.Implement.GpoImplementId)"
            :invalid-feedback="errorMessage(vForm.Implement.GpoImplementId)"
            @change="onChangeGpoImplementId()"
          />
        </CCol>
        <CCol sm="12" lg="6">
          <CSelect
            size="sm"
            v-uppercase
            :label="$t('label.implement')"
            :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
            :options="ImplementOptions"
            addLabelClasses="required text-right"
            v-model="vForm.Implement.ImplementId.$model"
            :is-valid="hasError(vForm.Implement.ImplementId)"
            :invalid-feedback="errorMessage(vForm.Implement.ImplementId)"
          />
        </CCol>
        <CCol sm="12" lg="6">
          <div class="form-group form-row">
            <label class="text-right col-sm-12 col-lg-4 m-0 required col-form-label-sm">{{`${$t('label.quantity')}`}}</label>
            <div class="input-group col-sm-12 col-lg-8 input-group-sm">
              <money
                size="sm"
                v-bind="QuantityMeasure" 
                :class="!vForm.Implement.Quantity.$dirty ? 'form-control' : (vForm.Implement.Quantity.$error ? 'form-control is-invalid' : 'form-control is-valid')"
                v-model="vForm.Implement.Quantity.$model"
              >
              </money>
              <div class="invalid-feedback" v-if="vForm.Implement.Quantity.$error&&!CleanInformation">
                {{ errorMessage(vForm.Implement.Quantity) }}
              </div>
            </div>
          </div>
        </CCol>
        <CCol sm="12" lg="6">
          <CTextarea
            size="sm"
            rows="2"
            :label="$t('label.observation')"
            addLabelClasses="text-right"
            :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
            :placeholder="$t('label.observation')"
            v-model="vForm.Implement.Observation.$model"
            v-uppercase
            :is-valid="hasError(vForm.Implement.Observation)"
            :invalid-feedback="errorMessage(vForm.Implement.Observation)"
          />
        </CCol>
        <CCol sm="12" class="mb-3">
          <div class="text-right">
            <CButton
              color="add"
              size="sm"
              class="mr-1"
              v-c-tooltip="{content: $t('label.add'), placement: 'top-end'}" 
              :disabled="isSubmit"
              @click="submit()"
            >
              <CIcon name="checkAlt"/>
            </CButton>
            <CButton
              color="wipe"
              class="justify-content-end"
              size="sm"
              v-c-tooltip="{content: $t('label.cleanInformation'), placement: 'top-end'}" 
              @click="resetForm(false)"
            >
              <CIcon name="cil-brush-alt" />
            </CButton>
          </div>
        </CCol>
      </CRow>
      <CCol sm="12">
        <dataTableExtended
          class="align-center-row-datatable"
          :items="computedImplementList"
          :fields="implementFields"
          :items-per-page="5"
          column-filter
          pagination
          :table-filter="tableText.tableFilterText"
          :items-per-page-select="tableText.itemsPerPageText"
          :noItemsView="tableText.noItemsViewText"
          sorter
        >
          <template #WeigthParent="{ item }">
            <td class="text-center">
              {{ formatNumber(item.WeigthParent, 2) }}
            </td>
          </template>
          <template #Quantity="{ item }">
            <td class="text-center">
              {{ formatNumber(item.Quantity, 2) }}
            </td>
          </template>
          <template #QuantityReceived="{ item }">
            <td class="text-center">
              {{ formatNumber(item.QuantityReceived, 2) }}
            </td>
          </template>
          <template #Weight="{ item }">
            <td class="text-center">
              {{ formatNumber(item.Weight, 2) }}
            </td>
          </template>
          <template #Volumen="{ item }">
            <td class="text-center">
              {{ formatNumber(item.Volumen, 2) }}
            </td>
          </template>
          <template #SquareMeters="{ item }">
            <td class="text-center">
              {{ formatNumber(item.SquareMeters, 2) }}
            </td>
          </template>
          <template #options="{ item }">
            <td class="text-center">
              <CButton 
                color="edit"
                square
                size="sm"
                class="mr-1"
                v-c-tooltip="{
                  content: `${$t('label.edit')} ${$t('label.Implement')}`,
                  placement: 'top-start'
                }"
                @click="editImplement(item)"
              >
                <CIcon name="pencil" />
              </CButton>
              <CButton
                color="wipe"
                size="sm"
                v-c-tooltip="{
                  content: $t('label.delete')+$t('label.Implement'),
                  placement: 'top-start',
                }"
                @click="deleteImplement(item)"
              >
                <CIcon name="cil-trash" />
              </CButton>
            </td>
          </template>
        </dataTableExtended>
        <div class="text-invalid-feedback text-center" v-if="vForm.DetailJson.$error">
          {{ $t('validation.RequiredDetail') }}
        </div>
      </CCol>
    </div>
    

    <template #footer>
      <CButton
        square
        color="add"
        class="d-flex align-items-center"
        @click.stop="Update"
        :disabled="isSubmit"
      >
        <CIcon name="checkAlt"/>{{$t('button.accept')}}
      </CButton>
      <CButton
        square
        color="wipe"
        class="d-flex align-items-center"
        :disabled="isSubmit"
        @click="resetForm(true)"
      >
        <CIcon name="x" /><span class="ml-1"
          >{{$t('button.cancel')}}</span
        >
      </CButton>
    </template>
    </CModalExtended>
</template>
<script>
import { mapState } from 'vuex';
import ModalMixin from '@/_mixins/modal';
import GeneralMixin from '@/_mixins/general';
import UpperCase from '@/_validations/uppercase-directive';
import { NumberFormater } from "@/_helpers/funciones";

function data() {
  return {
    modalActive: false,
    isSubmit: false,
    CleanInformation: false,
    GpoImplementList: [],
    ImplementOptionList: [],
    measure: {
      decimal: ",",
      thousands: ".",
      prefix: "",
      suffix: "",
      precision: 2,
      masked: false,
      maxlength: 18,
    },
    QuantityMeasure: {
      decimal: ",",
      thousands: ".",
      prefix: "",
      suffix: "",
      precision: 0,
      masked: false,
      maxlength: 6,
    },
  };
}

//methods
async function getGpoImplementGeneralCargoList() {
  this.$store.state.yardManagement.loading = true;
  await this.$http.get('GpoImplementGeneralCargo-list')
    .then((responses) => {
      this.GpoImplementList = responses.data.data ?? [];
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .finally(() => {
      this.$store.state.yardManagement.loading = false;
    });
}

async function getImplementGeneralCargoList() {
  this.$store.state.yardManagement.loading = true;
  await this.$http.get('ImplementGeneralCargo-list', { GpoImplementId: this.form.Implement.GpoImplementId })
    .then((responses) => {
      this.ImplementOptionList = responses.data.data ?? [];
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .finally(() => {
      this.$store.state.yardManagement.loading = false;
    });
}

function submit() {
  try {
    this.isSubmit = true;
    this.vForm.Implement.$touch();

    if (this.vForm.Implement.$error) {
      throw this.$t('label.errorsPleaseCheck');
    }

    let GpoImplementData = this.GpoImplementList.find(e => e.GpoImplementId == this.form.Implement.GpoImplementId);
    let ImplementData = this.ImplementOptionList.find(e => e.ImplementId == this.form.Implement.ImplementId);
    if (this.form.Implement.Id=='') {
      this.$emit('updateImplementJson', {
        IdX: this.form.ImplementJson.length > 0 ? this.form.ImplementJson[this.form.ImplementJson.length - 1].IdX + 1 : 1,
        GpoImplementId: this.form.Implement.GpoImplementId,
        GpoImplementNameEn: GpoImplementData?.GpoImplementNameEn ?? '',
        GpoImplementNameEs: GpoImplementData?.GpoImplementNameEs ?? '',
        ImplementId: this.form.Implement.ImplementId,
        ImplementNameEn: ImplementData?.ImplementNameEn ?? '',
        ImplementNameEs: ImplementData?.ImplementNameEs ?? '',
        Quantity: NumberFormater.setNum(this.form.Implement.Quantity),
        Observation: this.form.Implement.Observation,
      })
    } else {
      this.$emit('updateImplementJson', {
        GpoImplementId: this.form.Implement.GpoImplementId,
        GpoImplementNameEn: GpoImplementData?.GpoImplementNameEn ?? '',
        GpoImplementNameEs: GpoImplementData?.GpoImplementNameEs ?? '',
        ImplementId: this.form.Implement.ImplementId,
        ImplementNameEn: ImplementData?.ImplementNameEn ?? '',
        ImplementNameEs: ImplementData?.ImplementNameEs ?? '',
        Quantity: NumberFormater.setNum(this.form.Implement.Quantity),
        Observation: this.form.Implement.Observation,
      })
    }
    this.resetForm();
    this.isSubmit = false;
  } catch (e) {
    this.isSubmit = false;
    this.notifyError({text: e});
  }
}

function Update() {
  this.isSubmit = true;
  this.$store.state.yardManagement.loading = true;
  setTimeout(async() => {
    if (this.PackagingItem?.id == '') {
      await this.$emit('submit')
    } else {
      await this.$emit('updateImplementOnly')
    }
    this.resetForm(true);
    this.isSubmit = false;
    this.$store.state.yardManagement.loading = false;
  }, 10)
 
  
}

async function editImplement(item) {
  this.form.Implement = {
    id: item.IdX,
    GpoImplementId: item.GpoImplementId,
    ImplementId: item.ImplementId,
    Quantity: item.Quantity,
    Observation: item.Observation,
  }
  this.ImplementOptionList = [];
  await this.getImplementGeneralCargoList();
  this.vForm.Implement.$touch();
}

function deleteImplement(item) {
  this.$emit('deleteImplement', item);
}

function onChangeGpoImplementId() {
  if (this.form.Implement.GpoImplementId) {
    this.getImplementGeneralCargoList();
  }
}

function formatNumber(number, decimal) {
  return NumberFormater.formatNumber(number, decimal);
}

function resetForm(all = false) {
  this.CleanInformation = true;
  this.form.Implement = {
    id: '',
    GpoImplementId: '',
    ImplementId: '',
    Quantity: 0,
    Observation: '',
  }
  this.ImplementOptionList = [];
  setTimeout(() => {
    this.vForm.Implement.$reset();
    this.CleanInformation = false;
  }, 30)
  if (all) {
    this.GpoImplementList = [];
    this.$emit('close');
  }
}

//computed
function GpoImplementOptions() {
  let _lang = (this.$i18n.locale)?.charAt(0)?.toUpperCase() + (this.$i18n.locale)?.slice(1);
  let chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
  this.GpoImplementList.map(e => {
    chart.push({
      value: e.GpoImplementId, 
      label: e[`GpoImplementName${_lang}`],
    })
  })
  return chart;
}

function ImplementOptions() {
  let _lang = (this.$i18n.locale)?.charAt(0)?.toUpperCase() + (this.$i18n.locale)?.slice(1);
  let chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
  this.ImplementOptionList.map(e => {
    chart.push({
      value: e.ImplementId,
      label: e[`ImplementName${_lang}`],
    })
  })
  return chart;
}

function computedImplementList() {
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  let List = this.PackagingItem?.ImplementJson?.filter(e => e.Status != 0);
  return List?.map((e, index) => {
    return {
      ...e,
      Nro: index+1,
      GpoImplementName: e?.['GpoImplementName'+_lang] ?? '',
      ImplementName: e?.['ImplementName'+_lang] ?? '',
    }
  });
}

function implementFields(){
  return [
    { key: 'options', label: '', _style: 'width: 1%; min-width:90px;', sorter: false, filter: false },
    { key: 'Nro', label: '#', _style: 'min-width: 45px;', _classes: 'text-center', filter: false },
    { key: 'GpoImplementName', label: this.$t('label.group'),  _classes: 'text-uppercase text-center', _style: 'min-width: 200px;'},
    { key: 'ImplementName', label: this.$t('label.implement'),  _classes: 'text-uppercase text-center', _style: 'min-width: 180px;'},
    { key: 'Quantity', label: this.$t('label.quantity'), _classes: 'text-uppercase text-center', _style: 'min-width: 160px;'},
  ]
}

export default {
  name: 'modalImplement',
  props: {
    form: {
      type: Object,
      required: true,
      default: () => {},
    },
    vForm: {
      type: Object,
      required: true,
      default: () => {},
    },
    PackagingItem: {
      type: Object,
      default: () => {},
    },
    modal: Boolean,
  },
  data,
  mixins: [
    ModalMixin,
    GeneralMixin,
  ],
  directives: UpperCase,
  methods: {
    getGpoImplementGeneralCargoList,
    getImplementGeneralCargoList,
    submit,
    Update,
    editImplement,
    deleteImplement,
    onChangeGpoImplementId,
    formatNumber,
    resetForm,
  },
  computed:{
    GpoImplementOptions,
    ImplementOptions,
    computedImplementList,
    implementFields,
    ...mapState({
      branch: state => state.auth.branch,
      yardData: state => state.yardManagement.yardData,
      dataBl: state => state.yardManagement.dataBl,
    })
  },
  watch: {
    modal: function (NewVal) {
      this.modalActive = NewVal;
      this.vForm.Implement.$reset();
      if (this.modalActive) {
        this.getGpoImplementGeneralCargoList();
      }
    }
  },
};
</script>

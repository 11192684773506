<template>
  <div>
    <CRow class="justify-content-center mt-3" >
      <CCol sm="12" lg="5">
        <CInput
          size="sm"
          :label="$t('label.bl')"
          :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
          :placeholder="''"
          v-uppercase 
          addLabelClasses="required text-right"
          v-model="vForm.dataBasic.bl.$model"
          :is-valid="hasError(vForm.dataBasic.bl)"
          :invalid-feedback="errorMessage(vForm.dataBasic.bl)" 
          :disabled="fgInfo"
        >
        </CInput>
      </CCol>
      <!-- Caso resgistro bl con bls previamente registrados -->
      <!-- <CCol sm="12" lg="5" v-if="items.length > 0  && !isEdit">
        <CSelect
          horizontal
          size="sm"
          :label="$t('label.blType')"
          :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
          addLabelClasses="required text-right"
          class="remove-space" 
          :disabled="checkChargeBlType || fgInfo"
          :options="blTypeOptions"
          v-model.trim="vForm.dataBasic.TpBlId.$model"
          :value.sync="form.dataBasic.TpBlId"
          :is-valid="hasError(vForm.dataBasic.TpBlId)"
          :invalid-feedback="errorMessage(vForm.dataBasic.TpBlId)"
          @change="changeBlType($event)"
        />
      </CCol> -->
      <!-- Caso resgistro bl sin bls previemente registrados -->
      <!-- <CCol sm="12" lg="5" v-if="items.length == 0 && !isEdit">
        <CInput
          horizontal
          size="sm"
          :label="$t('label.blType')"
          :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
          addLabelClasses="text-right"
          class="remove-space" 
          disabled
          :value.sync="form.dataBasic.TpBlId"
          :is-valid="hasError(vForm.dataBasic.TpBlId)"
          :invalid-feedback="errorMessage(vForm.dataBasic.TpBlId)"
          v-model="blMasterName" 
        >
        </CInput>
      </CCol> -->
        <!-- Caso edicion bl con tipo de bl master registrado  previamente-->
      <CCol sm="12" lg="5" v-if="isEdit  && this.form.dataBasic.TpBlId === this.masterId.toUpperCase()">
        <CInput
          horizontal
          size="sm"
          :label="$t('label.blType')"
          :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
          addLabelClasses="text-right"
          class="remove-space" 
          disabled
          :value.sync="form.dataBasic.TpBlId"
          :is-valid="hasError(vForm.dataBasic.TpBlId)"
          :invalid-feedback="errorMessage(vForm.dataBasic.TpBlId)"
          v-model="blMasterName" 
        >
        </CInput>
      </CCol>
        <!-- Caso edicion bl con tipo de bl house registrado previamente -->
      <CCol sm="12" lg="5" v-if="isEdit  && this.form.dataBasic.TpBlId === this.houseId.toUpperCase()">
        <CInput
          horizontal
          size="sm"
          :label="$t('label.blType')"
          :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
          addLabelClasses="text-right"
          class="remove-space" 
          disabled
          :value.sync="form.dataBasic.TpBlId"
          :is-valid="hasError(vForm.dataBasic.TpBlId)"
          :invalid-feedback="errorMessage(vForm.dataBasic.TpBlId)"
          v-model="blHouseName" 
        >
        </CInput>
      </CCol>

      <CCol sm="12" lg="5">
        <CInput
          size="sm"
          :label="$t('label.nBooking')"
          :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
          :placeholder="''"
          v-uppercase 
          addLabelClasses="text-right" 
          v-model="vForm.dataBasic.BookingNro.$model"
          :is-valid="hasError(vForm.dataBasic.BookingNro)"
          :invalid-feedback="errorMessage(vForm.dataBasic.BookingNro)" 
          :disabled="fgInfo"
        >
        </CInput>
      </CCol>
      <CCol sm="12" lg="5">
        <CInput
            horizontal
            size="sm"
            :label="$t('label.blCargoMaster')"
            :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
            :addLabelClasses="`${fgHouse ? 'required' : ''} text-right`.trim()"
            :disabled="true"
            v-uppercase
            v-model.trim="vForm.dataBasic.MasterBlNro.$model"
            :is-valid="hasError(vForm.dataBasic.BlCargoMasterId)"
            :invalid-feedback="errorMessage(vForm.dataBasic.BlCargoMasterId)"
          />
      </CCol>

      <CCol sm="12" lg="5">
        <CRow class="justify-content-center px-3">
          <CCol sm="12" lg="4" class="px-2">
            <label class="col-form-label-sm col-sm-12 text-right required px-0">{{`${$t('label.weight')} (KGM)`}}</label>
          </CCol>
          <CCol sm="12" lg="8" class="pl-1 pr-0">
            <money
              size="sm"
              v-bind="QuantityMeasure" 
              class="form-control col-sm-12"
              style="max-height: 80%; font-size: small;"
              v-model.trim="vForm.dataBasic.Weight.$model"
              @blur="vForm.dataBasic.Weight.$touch()"
              addLabelClasses="required text-right"
              :class="ValidateFormControlWeight(form.dataBasic.Weight)" 
              value="0"
              maxlength= "9"
              disabled
            ></money>
            <CCol class="col-sm-12 col-lg-12 text-invalid-feedback px-0" v-if="((this.form.dataBasic.Weight) < 0)">
              {{ $t('label.ValueNotNegative') }}
            </CCol>
            <CCol class="col-sm-12 col-lg-12 text-invalid-feedback px-0" v-if="((this.form.dataBasic.Weight == 0 || this.form.dataBasic.Weight == '0,00') && valid==true)">
              {{$t('label.required')}}
            </CCol>
          </CCol>
        </CRow>
      </CCol>
      <CCol sm="12" lg="5">
        <CInput
          size="sm"
          :label="$t('label.Origin')"
          :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
          :placeholder="''"
          v-uppercase 
          addLabelClasses="text-right"
          v-model="vForm.dataBasic.Procedence.$model"
          :is-valid="hasError(vForm.dataBasic.Procedence)"
          :invalid-feedback="errorMessage(vForm.dataBasic.Procedence)" 
          :disabled="fgInfo"
        >
        </CInput>
      </CCol>

      <CCol sm="12" lg="5">
        <CRow class="justify-content-center px-3">
          <CCol sm="12" lg="4" class="px-2">
            <label class="col-form-label-sm col-sm-12 text-right px-0" :class="fgHouse ? 'required' : ''">{{`${$t('label.volume')} (M³)`}}</label>
          </CCol>
          <CCol sm="12" lg="8" class="pl-1 pr-0">
            <money
              size="sm"
              v-bind="measure" 
              :class="!vForm.dataBasic.Volumen.$dirty ? 'form-control' : (vForm.dataBasic.Volumen.$error ? 'form-control is-invalid' : 'form-control is-valid')"
              style="max-height: 80%; font-size: small;"
              v-model="vForm.dataBasic.Volumen.$model"
              addLabelClasses="text-right"
              value="0"
              maxlength= "9"
              @blur="vForm.dataBasic.Volumen.$touch()"
              disabled
            ></money>
            <div class="invalid-feedback" v-if="vForm.dataBasic.Volumen.$error">
              {{ errorMessage(vForm.dataBasic.Volumen) }}
            </div>
          </CCol>
        </CRow>
      </CCol>
      <CCol sm="12" lg="5">
        <div class="form-group form-row">
          <label class="text-right col-sm-12 col-lg-4 required col-form-label-sm mb-0">{{`${$t('label.quantity')}`}}</label>
          <div class="input-group col-sm-12 col-lg-8 input-group-sm">
            <money
              size="sm"
              v-bind="QuantityMeasure"
              :class="!vForm.dataBasic.Quantity.$dirty ? 'form-control' : (vForm.dataBasic.Quantity.$error ? 'form-control is-invalid' : 'form-control is-valid')"
              v-model.trim="vForm.dataBasic.Quantity.$model"
              value="0"
              maxlength= "12"
              disabled
            >
            </money>
            <div class="invalid-feedback" v-if="vForm.dataBasic.Quantity.$error">
              {{ errorMessage(vForm.dataBasic.Quantity) }}
            </div>
          </div>
        </div>
      </CCol>

      <CCol sm="12" lg="5">
        <div class="position-relative">
          <div class="form-group form-row">
            <label class="text-right required col-sm-12 col-lg-4 col-form-label-sm mb-0">{{`${$t('label.commodity')}`}}</label>
            <div class="col-sm-12 col-lg-8 px-1 " :class="fgInfo ? 'display_info' : ''">
              <multiselect 
                v-model.trim="vForm.dataBasic.headingJson.$model"
                :options="CommodityList"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :placeholder="$t('label.select')"
                :label="$i18n.locale=='en' ? 'CommodityNameEn' : 'CommodityNameEs'"
                track-by="CommodityId"
                class="col-sm-12 p-0" 
                :class="!vForm.dataBasic.headingJson.$dirty ? '' : (vForm.dataBasic.headingJson.$error ? 'no-valido' : 'valido')"       
                style="min-height: 30px;"
                :disabled="fgInfo"
              >
                <template slot="selection" slot-scope="{ values, isOpen }" v-if="!fgInfo">
                  <span class="multiselect__single m-0" v-if="values.length &amp;&amp; !isOpen">
                  {{ values.length}} {{$t('label.commodity')}}{{('(S)')}}
                  </span>
                </template>
              </multiselect> 
              <div class="invalid-feedback d-block" v-if="vForm.dataBasic.headingJson.$error">
                {{ errorMessage(vForm.dataBasic.headingJson) }}
              </div>
            </div>
          </div>
        </div>
      </CCol>
      <CCol sm="12" lg="5">
        <CSelect
          horizontal
          size="sm"
          :label="$t('label.customsRegime')"
          :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
          :addLabelClasses="`${fgHouse ? 'required' : ''} text-right`.trim()"
          class="remove-space" 
          :options="customsRegimeOptions"
          v-model.trim="vForm.dataBasic.customsRegime.$model"
          :value.sync="form.dataBasic.customsRegime"
          :is-valid="hasError(vForm.dataBasic.customsRegime)"
          :invalid-feedback="errorMessage(vForm.dataBasic.customsRegime)"
          :disabled="fgInfo || fgHouse"
        />
      </CCol>

      <CCol sm="12" lg="5">
        <CInput
          size="sm"
          :label="`${$t('label.BolipuertoCertificateEntry')}`"
          :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
          :placeholder="''"
          v-uppercase
          addLabelClasses="text-right"
          v-model="vForm.dataBasic.ReceptionBpAct.$model"
          :is-valid="hasError(vForm.dataBasic.ReceptionBpAct)"
          :invalid-feedback="errorMessage(vForm.dataBasic.ReceptionBpAct)"
          :disabled="fgInfo"
        >
        </CInput>
      </CCol>
      <CCol sm="12" lg="5">
        <CInput
          size="sm"
          :label="`${$t('label.nDua')}`"
          :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
          :placeholder="''"
          v-uppercase
          addLabelClasses="text-right"
          v-model="vForm.dataBasic.Dua.$model"
          :is-valid="hasError(vForm.dataBasic.Dua)"
          :invalid-feedback="errorMessage(vForm.dataBasic.Dua)"
          :disabled="fgInfo"
        >
        </CInput>
      </CCol>

      <CCol sm="12" lg="5" v-if="!fgInfo && isEdit && !validateBl">
        <CSelect
          :options="statusOptions"
          :value.sync="form.dataBasic.Status"
          :label="$t('label.status')"
          :disabled="validateBl"
          :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
          addLabelClasses="required text-right"
          :is-valid="statusSelectColor"
          size="sm"
        />
      </CCol>
      <CCol sm="12" lg="5" v-if="!fgInfo && isEdit && validateBl">
        <CInput
          :options="statusOptions"
          :value.sync="form.dataBasic.Status"
          :label="$t('label.status')"
          disabled
          v-model="activoName"
          :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
          addLabelClasses="required text-right"
          :is-valid="statusSelectColor"
          size="sm"
        >
        </CInput>
      </CCol>
      <CCol sm="12" lg="5">
      </CCol>
    </CRow>
  </div>
</template>
<script>
import ModalMixin from '@/_mixins/modal';
import GeneralMixin from '@/_mixins/general';
import UpperCase from '@/_validations/uppercase-directive';
import { mapState } from 'vuex';
import { Money } from "v-money";

function data() {
  return {
    masterId: process.env.VUE_APP_TP_BL_ID_MASTER,
    houseId: process.env.VUE_APP_TP_BL_ID_HOUSE,
    measure: {
      decimal: ",",
      thousands: ".",
      prefix: "",
      suffix: "",
      precision: 2,
      masked: false,
      maxlength: 18,
    },
    QuantityMeasure: {
      decimal: ",",
      thousands: ".",
      prefix: "",
      suffix: "",
      precision: 0,
      masked: false,
      maxlength: 6,
    },
    blCargoMasterItems: [],
    blHouseName: '',
    blMasterName: '',
    GroupList: [],
    CommodityList: [],
    tpBLItems: [],
    CustomOperationItems: [],
    activoName: this.$t('label.ACTIVO'),
  };
}

//-----------------------   Method    ------------------------

function ValidateFormControlWeight(value) {
  if ((((value == 0 || value == '0,00') && this.valid==true) || value < 0 )) {
    return 'form-control is-invalid';
  }else{
    if((value == 0 || value == '0,00') && this.valid==false){
      return 'form-control';
    }else{
      return 'form-control is-valid';
    }
  }
}

function ValidateFormControlVolumen(value) { 
  if ((((value == 0 || value == '0,0000') && this.valid==true) || value < 0 )) {
    return 'form-control is-valid';
  }else{
    if((value == 0 || value == '0,0000') && this.valid==false){
      return 'form-control';
    }else{
      return 'form-control is-valid';
    }
  }
}

async function resetForm() {
  //dataBasic
  this.form.dataBasic.bl = '';
  this.form.dataBasic.BlCargoId = '';
  this.form.dataBasic.TpBlId = '';
  this.form.dataBasic.BlCargoMasterId = '';
  this.form.dataBasic.MasterBlNro = '';
  this.form.dataBasic.BookingNro = '';
  this.form.dataBasic.Weight = '';
  this.form.dataBasic.Procedence = '';
  this.form.dataBasic.Volumen = '';
  this.form.dataBasic.Quantity = '';
  this.form.dataBasic.headingJson = [];
  this.form.dataBasic.customsRegime = '';
  this.form.dataBasic.ReceptionBpAct = '';
  this.form.dataBasic.Dua = '';
  this.form.dataBasic.Status = 1;
  this.blHouseName = '';
  this.form.dataBasic.TpBlId = '';
  this.form.dataBasic.TpCargoId = '';
  this.form.dataBasic.PortActivityId = '';

  //data array
  this.GroupList = [];
  this.CommodityList = [];
  this.tpBLItems = [];
  this.CustomOperationItems = [];
  this.blCargoMasterItems = [];
}

async function getListApisDataBasic() {
  this.$store.state.yardManagement.loading = true;
  let peticiones =  [
    this.$http.ejecutar("GET", "BlCargoGeneral-info", {CargoId: this.ContainerCargoId ?? this.CargoId}),
    this.$http.ejecutar("GET", "CustomOperation-list"),
    this.$http.ejecutar("GET", "YardBlTpCargoConfig-list"),
  ];
   await Promise.all(peticiones)
    .then(async(responses) => {
      let listado = responses[0].data.data;
      let array = listado.find((val) => val.BlCargoId == this.BlCargoId);

     //listar grupo
      let GeneralCargoList = responses[2]?.data?.data?.[0] ?? {};
      this.GroupList = GeneralCargoList?.TpCargoJson ?? [];
      
      //listar tipo de bl
      this.tpBLItems = array.TpBlJson;
      this.blMasterName = this.tpBLItems?.find((val) => val.TpBlId == this.masterId)?.TpBlName ?? '';
      this.blHouseName = this.tpBLItems?.find((val) => val.TpBlId == this.houseId)?.TpBlName ?? '';
      // if(this.items.length > 0){
      //   this.form.dataBasic.TpBlId = this.tpBLItems[0].TpBlId;
      // }
      //listar regimen aduanal
      this.CustomOperationItems = responses[1].data.data;
      //listar bl cargo master
      this.blCargoMasterItems = array.BlMasterListJson;

      if (this.isEdit) {
        await this.getCommodity(true);
      }

      this.$store.state.yardManagement.loading = false;
    })
    .catch((err) => {
      this.$store.state.yardManagement.loading = false;
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
}

async function getCommodity(Loading = false) {
  if (this.form.dataBasic.TpCargoId) {
    this.$store.state.yardManagement.loading = true;
    await this.$http.get("Commodity-by-TpcargoId", { TpCargoId: this.form.dataBasic.TpCargoId })
      .then((response) => {
        this.CommodityList = response?.data?.data ?? [];
      })
      .catch((err) => {
        this.$notify({
          group: "container",
          title: "¡Error!",
          text: err,
          type: "error",
        });
      }).finally(() => {
        this.$store.state.yardManagement.loading = Loading;
      });
  }
}

//-----------------------   Computed   ------------------------

function changeBlType(event) {
  this.form.dataBasic.BlCargoMasterId = '';
  this.blCargoMasterItems = [];
  this.form.dataBasic.TpBlId = event.target.value;
  if((this.form.dataBasic.TpBlId.toUpperCase() == this.houseId)){
    this.getListApisDataBasic();
    this.$emit('validCargoMaster', true)
  }else{
    this.$emit('validCargoMaster', false)
  }
}

function checkChargeBlType() {
  return  ((this.form.dataBasic.TpBlId == this.masterId.toUpperCase()) && (this.edit))  ?  true : false;
}

function statusSelectColor() {
  return this.form.dataBasic.Status === 1;
}

function GroupOptions() {
  let chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
  this.GroupList.map(e => {
    chart.push({
      value: e.TpCargoId, 
      label: e.TpCargoName
    })    
  })
  return chart;
}

function blTypeOptions() {
  var chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
   this.tpBLItems.map(function(e){
    chart.push({
      value: e.TpBlId, 
      label: e.TpBlName,
    })    
  })
  return chart;
}

function blCargoMasterOptions() {
  if(this.blCargoMasterItems.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    !this.checkCharge && this.blCargoMasterItems.map(function(e){
      chart.push({
        value: e.BlCargoMasterId,
        label: e.BlNro
      })    
    })
    return chart;
  }
}

function checkCharge() {
  return  this.form.dataBasic.TpBlId === this.houseId.toUpperCase() ?  false : true;
}

function customsRegimeOptions() {
  let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  if(this.CustomOperationItems.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.CustomOperationItems.map(function(e){
      chart.push({
        value: e.CustomOperationId,
        label: e[`CustomOperationDs${_lang}`]
      })    
    })
    return chart;
  }
}

function validateBl() {
  return process.env.VUE_APP_BL_STATUS_ID_CERTIFICATE == this.ContainerItem?.BlStatus?.toUpperCase();
}

export default {
  name: 'dataBasic-tab',
  components: {
      Money
  },
  data,
  props: {
    form: {
      type: Object,
      required: true,
      default: () => {},
    },
    vForm: Object,
    ContainerItem: Object,
    isEdit: Boolean,
    fgInfo: Boolean,
    fgHouse: Boolean,
    showModal: Boolean,
    valid: Boolean,
    validCargoMaster: Boolean,
    Tab: Boolean,
    items: Array,
    ContainerCargoId: String,
    rubroVal: Boolean,
  },
  mixins: [
    ModalMixin, 
    GeneralMixin
  ],
  directives: UpperCase,
  methods: {
    getListApisDataBasic,
    getCommodity,
    changeBlType,
    ValidateFormControlWeight,
    statusSelectColor,
    resetForm
  },
  computed:{
    customsRegimeOptions,
    checkChargeBlType,
    GroupOptions,
    blTypeOptions,
    blCargoMasterOptions,
    checkCharge,
    validateBl,
    ...mapState({
      BlCargoMasterId: state => state.yardManagement.BlCargoMasterId,
      CargoId: state => state.yardManagement.dataContainer.CargoId,
      loading: state => state.yardManagement.loading,
    })
  },
  watch: {
    showModal: async function (Newval) {
      if (!Newval) {
        this.resetForm();
      }
    },
    Tab: async function (Newval) {
      if (Newval) 
        await this.getListApisDataBasic();
    },
  }
};
</script>
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-2"},[_c('CRow',[_c('CCol',{attrs:{"col":"12","xl":"12"}},[_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"hover":"","sorter":"","small":"","details":"","column-filter":"","items":_vm.formatedItems,"fields":_vm.fields,"noItemsView":_vm.tableText.noItemsViewText,"items-per-page":_vm.tableText.itemsPerPage,"pagination":""},scopedSlots:_vm._u([{key:"Nro",fn:function({ index }){return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(index+1)+" ")])]}},{key:"Status",fn:function({ item }){return [_c('td',{staticClass:"cell-center text-center"},[_c('CBadge',{attrs:{"color":_vm.getBadge(item.Status)}},[_vm._v(" "+_vm._s(_vm.$t('label.'+item.Status))+" ")])],1)]}},{key:"Detalle",fn:function({item}){return [_c('td',{staticClass:"text-center"},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                content: _vm.$t('label.edit')+' '+_vm.$t('label.bl'),
                placement: 'top-start'
                }),expression:"{\n                content: $t('label.edit')+' '+$t('label.bl'),\n                placement: 'top-start'\n                }"}],staticClass:"mr-1",attrs:{"color":"edit","square":"","size":"sm"},on:{"click":function($event){return _vm.toggleBlEdit(item)}}},[_c('CIcon',{attrs:{"name":"pencil"}})],1),_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                  content: _vm.$t('label.see')+' '+_vm.$t('label.document'),
                  placement: 'top-start'
              }),expression:"{\n                  content: $t('label.see')+' '+$t('label.document'),\n                  placement: 'top-start'\n              }"}],attrs:{"square":"","size":"sm","color":"watch"},on:{"click":function($event){return _vm.viewDocument(item)}}},[_c('CIcon',{attrs:{"name":"eye"}})],1)],1)]}}])})],1)],1),_c('ModalBl',{attrs:{"modal":_vm.ModalBl,"Bl":_vm.Bl,"items":Object.keys(_vm.Bl).length != 0 ? [_vm.Bl] : [],"title":_vm.titleModal,"isEdit":_vm.isEdit,"fgInfo":!_vm.FgEdit},on:{"update:modal":function($event){_vm.ModalBl=$event},"close":_vm.closeModal,"Update-list":_vm.Update}}),_c('ModalBlDocument',{attrs:{"modal":_vm.ModalBlDocument,"title":_vm.titleDocumentsModal,"documentList":_vm.documentList},on:{"update:modal":function($event){_vm.ModalBlDocument=$event},"close":_vm.closeModal}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
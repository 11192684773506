<template>
  <CModalExtended
    :title="`${$t('label.module')}: ${Module?.YardAreaName ?? ''}`"
    color="dark"
    size="xl"
    :close-on-backdrop="false"
    :show.sync="modalActive"
    @update:show="$emit('close')"
  >
    <div class="yard-body" style="height: 100%; overflow-y: hidden; overflow-x: hidden">
      <div class="wrapper d-flex align-items-stretch">
        <nav id="sidebar-Detail">
          <div class="list-menu">
            <CRow>
              <CCol sm="12">
                <img :src="imageSrc(Module?.TpCargoRoute)" style="width: 100%; border-top-left-radius: 1rem;" class="img text-center" @error="setAltImg"/>
              </CCol>
            </CRow>
          </div>
          <div class="p-3">
            <ul class="list-unstyled components mb-5">
              <li>
                <div class="list-menu">
                  <CRow>
                    <CCol sm="12">
                      <div class="border-title d-flex align-items-center justify-content-center py-1 mb-2" style="height: auto">
                        <b><label class="mb-0" style='font-size: 9px'>{{ `${$t('label.blCargoMaster')}:  ${isMaster ? Module?.BlNro : Module?.MasterBlNro}`}}</label></b>
                      </div>

                      <div v-if="!isMaster" class="border-title d-flex align-items-center justify-content-center py-1 mb-2" style="height: auto">
                        <b><label class="mb-0" style='font-size: 9px'>{{ `${$t('label.BlHouse')}:  ${Module?.BlNro}`}}</label></b>
                      </div>

                      <div class="border-title d-flex align-items-center justify-content-center py-1 mb-2" style="height: auto">
                        <b class="mr-2"><label class="mb-0" style='font-size: 9px'>{{ `${$t('label.activity')}: ${$i18n?.locale=='en' ? Module?.PortActivityNameEn : Module?.PortActivityNameEs}`}}</label></b> 
                        <CIcon size='lg' name="checkListCircleGreen"/>
                      </div>

                      <div class="border-title d-flex align-items-center justify-content-center py-1 mb-2" style="height: auto">
                        <b><label class="mb-0" style='font-size: 9px'>{{ `${valiPortActivity ? this.$t('label.importer') : this.$t('label.exporter')}:  ${Module.ClientName ?? ''}`}}</label></b>
                      </div>

                      <div class="border-title d-flex align-items-center justify-content-center py-1 mb-2" style="height: auto">
                        <b><label class="mb-0" style='font-size: 9px'>{{ `${this.$t('label.weight')}:  ${formatNumber(Module.Weigth, 2)}`}}</label></b>
                      </div>

                      <div class="border-title d-flex align-items-center justify-content-center py-1 mb-2" style="height: auto">
                        <b><label class="mb-0" style='font-size: 9px'>{{ `${this.$t('label.volume')}:  ${formatNumber(Module.Volumen, 2)}`}}</label></b>
                      </div>

                      <div class="border-title d-flex align-items-center justify-content-center py-1 mb-2" style="height: auto">
                        <b><label class="mb-0" style='font-size: 9px'>{{ `${this.$t('label.quantity')}:  ${formatNumber(Module.Quantity, 2)}`}}</label></b>
                      </div>

                      <div class="border-title d-flex align-items-center justify-content-center py-1 mb-2" style="height: auto">
                        <b><label class="mb-0" style='font-size: 9px'>{{ `${this.$t('label.Acta')}:  ${Module?.DocumentCode ?? 'N/A'}`}}</label></b>
                      </div>

                      <div class="border-title d-flex align-items-center justify-content-center py-1 mb-2" :style="`height: auto; background: ${Module?.BlStatusColor}`">
                        <b><label class="mb-0 mr-1" style='font-size: 9px'>{{ `${$t('label.status')}:`}}</label></b> 
                        <b><label class="mb-0" style='font-size: 9px'>{{ `${$i18n?.locale=='en' ? Module?.BlStatusEn : Module?.BlStatusEs}`}}</label></b> 
                      </div>

                      <div class="border-title d-flex align-items-center justify-content-center py-1 mb-2" :style="`height: auto; background: ${Module?.YardCargoBlStatusColor}`">
                        <b><label class="mb-0" style='font-size: 9px'>{{ `${$i18n?.locale=='en' ? Module?.YardCargoBlStatusEn : Module?.YardCargoBlStatusEs}`}}</label></b> 
                      </div>
                    </CCol>
                  </CRow>
                </div>
              </li>
            </ul>
          </div>
        </nav>

        <div>
          <!-- Contenido Interno -->
          <div class="container-fluid contenido-especial pt-3" >
            <div class="border-title border-gray mb-3">
              <label class="m-0"><b>{{ $t('label.basicData') }}</b></label>
            </div>
            <CRow>
              <div class='row' style='font-size: 11px'>
                <div class='col-sm-4 px-2'>
                  <div class='d-flex'>
                    <b class='col-sm-4 text-right pl-0 pr-1'>{{this.$t('label.visit')}}:</b>{{Module.VesselName ?? ''}} - {{Module.Voyage ?? ''}}
                  </div>
                </div>
                <div class='col-sm-4 px-2'>
                  <div class='d-flex'>
                    <b class='col-sm-4 text-right pl-0 pr-1'>{{Module.LabelArrival}}:</b>{{Module.Arrival ? formatDateTimeWithSlash(Module.Arrival) : ''}}
                  </div>
                </div>
                
                <div class='col-sm-4 px-2'>
                  <div class='d-flex'>
                    <b class='col-sm-4 text-right pl-0 pr-1'>{{this.$t('label.group')}}:</b>{{textLang('TpCargoName') ?? ''}}
                  </div>
                </div>
                <div class='col-sm-4 px-2'>
                  <div class='d-flex'>
                    <b class='col-sm-4 text-right pl-0 pr-1'>{{this.$t('label.customBroker')}}:</b>{{Module.CustomBrokerName ?? ''}}
                  </div>
                </div>
                <div class='col-sm-4 px-2'>
                  <div class='d-flex'>
                    <b class='col-sm-4 text-right pl-0 pr-1'>{{this.$t('label.commodity')}}:</b>{{RemoveSpecialCharacters(Module.DescriptionOfGoods)}}
                  </div>
                </div>
                <div class='col-sm-4 px-2'>
                  <div class='d-flex'>
                    <b class='col-sm-4 text-right pl-0 pr-1'>{{this.$t('label.status')}}:</b>{{textLang('YardCargoModuleStatus')  ?? ''}}
                  </div>
                </div>
              </div>
            </CRow>
            <CTabs variant="tabs" class="mt-2 p-3">
              <CTab :title="$t('label.packagings')">
                <CRow>
                  <CCol sm="12" style="overflow-y: auto; overflow-x: hidden">
                    <dataTableExtended
                        class="align-center-row-datatable"
                        :items-per-page="5"
                        :items="ComputedPackaging"
                        :fields="Packagingfields"
                        column-filter
                        pagination
                        :table-filter="tableText.tableFilterText"
                        :items-per-page-select="tableText.itemsPerPageText"
                        :noItemsView="tableText.noItemsViewText"
                        sorter
                    >
                        <template #Status="{ item }">
                            <td class="text-center">
                                <CBadge :color="getBadge(item.Status)">
                                    {{ $t('label.'+item.Status) }}
                                </CBadge>
                            </td>
                        </template>
                        <template #details="{item}">
                            <div class="p-2" style="background-color: #e0e2e6"  v-if="(item.SerialJson?.filter(item => item.Status !== 0) ?? [])?.length>0">
                                <dataTableExtended
                                    class="align-center-row-datatable lv-2"
                                    small
                                    :items="formatedLevel2(item, item.SerialJson)"
                                    :fields="level2Fields"
                                    :noItemsView="tableText.noItemsViewText"
                                    :items-per-page="tableText.itemsPerPage"
                                >
                                
                                </dataTableExtended>
                            </div>
                        </template>
                    </dataTableExtended>
                  </CCol>
                </CRow>
              </CTab>
            </CTabs>
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <CButton
        shape="square"
        color="wipe"
        class="d-flex align-items-center"
        @click.stop="$emit('close')"
      >
        <CIcon name="x"/>
        <span class="ml-1">{{ $t('button.exit') }}</span>
      </CButton>
    </template>
  </CModalExtended>
</template>

<script>
import GeneralMixin from '@/_mixins/general';
import UpperCase from '@/_validations/uppercase-directive';
import { DateFormater, NumberFormater, trimRemoveSpecialCharacters } from '@/_helpers/funciones';
import { CRow } from '@coreui/vue';

//Data
function data() {
  return {
    modalActive: false,
    PackagingJsonList: []
  }
}

//Methods
function formatDateTimeWithSlash(date) {
  return date ? DateFormater.formatDateTimeWithSlash(date) : 'N/A';
}

function imageSrc(Src){
  return `${this.$store.getters["connection/getBase"]}${Src}`;
}

function setAltImg(event){
  event.target.src = '/img/errorimage.jpg';
}

function formatNumber(number, decimal) {
  return  NumberFormater.formatNumber(number, decimal);
}

function RemoveSpecialCharacters(text) {
  return  trimRemoveSpecialCharacters(text, 50)
}

function textLang(text) {
  let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);

  return  this.Module?.[`${text}${_lang}`]
}

function isMaster() {
  return this.Module?.TpBlId == process.env.VUE_APP_TP_BL_ID_MASTER
}

//Computed
function valiPortActivity() {
  return  this.Module.PortActivityId == process.env.VUE_APP_PORT_ACTIVITY_ID_IMPORT
}

function Packagingfields(){
    return [
      { key: 'Nro', label: '#', _style: 'width:3%; text-align:left', _classes: 'text-center', filter: false },
      { key: 'PackagingName', label: this.$t('label.packaging'), _classes: 'text-uppercase text-center', _style: 'min-width:150px;', sorter: true, filter: true},
      { key: 'Quantity', label: this.$t('label.quantity'), _style:'min-width:150px;', _classes:"center-cell text-center", sorter: true, filter: true},
      { key: 'ComputedWeight', label: `${this.$t('label.weight')} (KGM)`, _classes: 'text-uppercase text-center', _style: 'min-width:150px;', sorter: true, filter: true},
      { key: 'ComputedVolume', label: `${this.$t('label.volume')} (M³)`, _classes: 'text-uppercase text-center', _style: 'min-width:150px;', sorter: true, filter: true},
    ];
}

function level2Fields(){
    return [
        { key: 'Nro', label: '#', _style: 'width:3%; text-align:left', _classes: 'text-center', filter: false },
        { key: 'Serial', label: this.$t('label.serial'),  _classes: 'text-uppercase text-center', _style: 'width:20%;', sorter: true, filter: true},
        { key: 'Quantity', label: this.$t('label.quantity'), _classes: 'text-uppercase text-center', _style: 'width:20%;', sorter: true, filter: true},
        { key: 'ComputedWeight', label: `${this.$t('label.weight')} (KGM)`, _style:'width:20%;', _classes: 'text-uppercase text-center', sorter: true, filter: true},
        { key: 'ComputedVolume', label: `${this.$t('label.volume')} (M³)`, _style:'width:20%;', _classes:"center-cell text-center", sorter: true, filter: true},
    ];
}


function ComputedPackaging() {
    let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
    let List = this.PackagingJsonList?.filter(item => item.Status !== 0) ?? []
    return List.map((item, index) => {
        return {
            ...item,
            Nro: index+1,
            PackagingName: item[`PackagingName${_lang}`] ?? 'N/A',
            VesselUbicationName: item.YardAreaName ?? 'N/A',
            ComputedWeight: item.Weigth ? NumberFormater.formatNumber(item.Weigth, 2) : '0,00',
            ComputedLength: item.Length ? NumberFormater.formatNumber(item.Length, 2) : '0,00',
            ComputedWidth: item.Width ? NumberFormater.formatNumber(item.Width, 2) : '0,00',
            ComputedHeight: item.Height ? NumberFormater.formatNumber(item.Height, 2) : '0,00',
            ComputedVolume: item.Volumen ? NumberFormater.formatNumber(item.Volumen, 2) : '0,00',
            Quantity: item.Quantity ? NumberFormater.formatNumber(item.Quantity, 2) : '0,00',
        }
    });
}

function formatedLevel2(item, SerialJson) {

  let List = SerialJson?.filter(item => item.Status !== 0) ?? [];

  return List.map((item, index) => {
      return {
      ...item,
      Nro: index+1,
      Quantity: item.Quantity ? NumberFormater.formatNumber(item.Quantity, 2) : '0,00',
      ComputedWeight: item.Weigth ? NumberFormater.formatNumber(item.Weigth, 2) : '0,00',
      ComputedLength: item.Length ? NumberFormater.formatNumber(item.Length, 2) : '0,00',
      ComputedWidth: item.Width ? NumberFormater.formatNumber(item.Width, 2) : '0,00',
      ComputedHeight: item.Height ? NumberFormater.formatNumber(item.Height, 2) : '0,00',
      ComputedVolume: item.Volumen ? NumberFormater.formatNumber(item.Volumen, 2) : '0,00',
      Seals: ` ${(item.Seal1 && item.Seal1 != '')? ` S1: (${item.Seal1})`: ''}${(item.Seal1 && item.Seal1 != '')? `,`: ''} 
              ${(item.Seal2 && item.Seal2 != '')? ` S2: (${item.Seal2})`: ''}${(item.Seal2 && item.Seal2 != '')? `,`: ''} 
              ${(item.Seal3 && item.Seal3 != '')? ` S3: (${item.Seal3})`: ''}${(item.Seal3 && item.Seal3 != '')? `,`: ''} 
              ${(item.Seal4 && item.Seal4 != '')? ` S4: (${item.Seal4})`: ''}
            `,
      }
  });
}


export default {
  name: 'modal-blSearch',
  props: {
    modal: Boolean,
    Module: {
      type: Object,
      default: () => {},
    }
  },
  data,
  mixins: [GeneralMixin],
  directives: UpperCase,
  methods: {
    formatDateTimeWithSlash,
    formatNumber,
    textLang,
    RemoveSpecialCharacters,
    imageSrc,
    setAltImg,
    formatedLevel2
  },
  computed: {
    Packagingfields,
    level2Fields,
    valiPortActivity,
    isMaster,
    ComputedPackaging,
  },
  watch: {
    modal: function(NewVal) {
      this.modalActive = NewVal;
      if(NewVal){
        this.PackagingJsonList = this.Module?.DetailJson 
      }
    },
  }
}
</script>

<template>
    <div class="mb-3">
        <div v-show="showIndex==0">
            <CRow class="mb-3">
                <CCol sm="12" lg="12" xl="12" class="d-flex align-items-center justify-content-start">
                    <h6 class="mt-2"><CIcon name='cil-list' size='lg' class="titulo-icono" /> {{ `${this.$t('label.blsHousePendingReceive')}`}} </h6>
                </CCol>
            </CRow>
            <CRow class="mt-2">
            <CCol sm="12">
                <dataTableExtended
                    class="align-center-row-datatable"
                    :items="computedListContainer"
                    :fields="fields"
                    :items-per-page="5"
                    column-filter
                    pagination
                    :table-filter="tableText.tableFilterText"
                    :items-per-page-select="tableText.itemsPerPageText"
                    :noItemsView="tableText.noItemsViewText"
                    sorter
                >
                    <template #YardCargoBlStatus="{ item }">
                        <td class="cell-center text-center">
                            <CBadge :color="getBadge(item.YardCargoBlStatus)">
                            {{ item.YardCargoBlStatus }}
                            </CBadge>
                        </td>
                    </template>
                    <template #VisitBillOfLadingStatus="{ item }">
                        <td class="cell-center text-center">
                            <CBadge :color="getBadge(item.VisitBillOfLadingStatus)">
                            {{ item.VisitBillOfLadingStatus }}
                            </CBadge>
                        </td>
                    </template>
                    <template #options="{ item }">
                        <td class="text-center">
                            <CButton
                            square
                            size="sm"
                            color="watch"
                            class="d-flex align-items-center"
                            @click="toggleAdd(item)"
                            v-c-tooltip="{
                                content: $t('label.reception'),
                                placement: 'top-start'
                            }"
                            >
                            <CIcon name='cil-list' />
                            </CButton>
                        
                        </td>
                    </template>

                    <template #Weight="{ item }">
                        <td class="text-center">
                            {{ NumberFormat(item.Weight, 2) }}
                        </td>
                    </template>
                </dataTableExtended>
            </CCol>
            </CRow>
        </div>
        <div v-show="showIndex==1">
            <BlRecepcion
                :Active="showIndex==1"
                :ContainerItem="ContainerItem"
                :isEdit = "false"
                @Close="showIndex=0, ContainerItem={}"
                @Update-list="Update"
            />
        </div>
    </div>
</template>
<script>
    import { mapState } from 'vuex';
    import General from '@/_mixins/general';
    import BlRecepcion from './BlReception/reception';
    import { DateFormater, NumberFormater } from '@/_helpers/funciones';

    //data
    function data() {
        return {
            Items: [],
            ContainerItem:{},
            dataBlHouseContainer: [],
            showIndex: 0,
        };
    }

    //methods
    function NumberFormat(number, decimal) {
        return NumberFormater.formatNumber(number, decimal);
    }

    async function getBlHousePendingsReception(loading) {
        this.$store.state.yardManagement.loading = true;
        await this.$http.get('YardWBlCargoHousePackaging-by-YardCargoId', { YardCargoId: this.dataContainer.YardCargoId })
            .then((response) => {
                let List = response.data.data;
                this.dataBlHouseContainer = List&&List.length!=0 ? List : [];
            })
            .catch( err => {
                this.$notify({
                    group: 'container',
                    title: '¡Error!',
                    text: err,
                    type: "error"
                });
            })
            .finally(async() => {
                this.$store.state.yardManagement.loading = loading;
                if (this.FgPositioning) {
                    this.$store.state.yardManagement.BlCollapse = 1;
                    this.$store.state.yardManagement.FgBlNavigation = true;
                    this.$store.state.yardManagement.blSelected = this.$store.state.yardManagement.dataBl.BlCargoId;
                }
            });
    }

    async function Update(loading) {
        await this.getBlHousePendingsReception(loading);
    }

    function toggleAdd(item) {
        this.ContainerItem = {...item, isProfileBl: false};
        this.showIndex = 1;
    }

    function getBadge(status) {
        switch (status) {
            case 'INACTIVO':
            return 'danger'
            default:
            return 'success'
        }
    }
    
    //computed
    function computedListContainer() {
        let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
        return this.dataBlHouseContainer.map((item, index) => {
            let Detail =  item.DetailJson?.map((e)=> {return e[`PackagingName${_lang}`]})?.join(', ') ?? ''

            return {
                ...item,
                Nro: index+1,
                TpBlName: item.TpBlName ?? 'N/A',
                ComputedWeight: item.Weigth ? NumberFormater.formatNumber(item.Weigth, 2) : '0,00',
                ComputedVolume: item.Volumen ? NumberFormater.formatNumber(item.Volumen, 2) : '0,00',
                Planned: item.PlannedQuantityReceived ? NumberFormater.formatNumber(item.PlannedQuantityReceived, 2) : '0,00',
                Received: item.QuantityReceived ? NumberFormater.formatNumber(item.QuantityReceived, 2) : '0,00',
                RemainReceived: item.QuantityRemainReceived ? NumberFormater.formatNumber(item.QuantityRemainReceived, 2) : '0,00',
                Packaging: Detail,
                _classes: 'color-gradient',
                _style: `background:${item.YardCargoBlStatusColor}`,
            };
        })
    }

    function fields(){ 
        return [
            { key: 'options', label: '', _style: 'min-width:45px;', sorter: false, filter: false },
            { key: 'Nro', label: '#', _style: 'width:50px; text-align:center', _classes: 'text-center', filter: false },
            { key: 'BlNro', label: this.$t('label.bl'),  _style:'min-width:180px; text-align:center;', _classes:" text-center" },
            { key: 'TpBlName', label: this.$t('label.blType'),  _classes: 'text-uppercase text-center', _style: 'min-width:120px; text-align:center;' },
            { key: 'MasterBlNro', label: this.$t('label.Master'),  _style:'min-width:180px; text-align:center;', _classes:" text-center" },
            { key: 'Packaging', label: this.$t('label.packaging'),  _classes: 'text-uppercase text-center', _style: 'min-width:260px; text-align:center;' },  
            { key: 'Planned', label: this.$t('label.plannedQuantity'), _classes: 'text-uppercase text-center', _style: 'min-width: 180px;'},
            { key: 'Received', label: this.$t('label.ReceivedQuantity'), _classes: 'text-uppercase text-center', _style: 'min-width: 200px;'},
            { key: 'RemainReceived', label: this.$t('label.CertifiedPendingReceived'), _classes: 'text-uppercase text-center', _style: 'min-width: 200px;'},
            { key: 'ComputedWeight', label: `${this.$t('label.weight')} (KGM)`,  _classes: 'text-uppercase text-center', _style: 'min-width:180px; text-align:center;' },
            { key: 'ComputedVolume', label: `${this.$t('label.volume')} (M³)`, _style:'min-width: 180px;', _classes:"center-cell text-center"}     
        ];
    }

    export default{
        name: 'pendingsBls',
        data,
        mixins: [General],
        components: {
            BlRecepcion,
        },
        methods: {
            toggleAdd,
            getBlHousePendingsReception,
            Update,
            NumberFormat,
            getBadge
        },
        computed: {
            fields,
            computedListContainer,
            ...mapState({
                globalSelectBl: state => state.yardManagement.globalSelectBl,
                CompanyBranchId: state => state.auth.branch.CompanyBranchId,
                YardId: state => state.yardManagement.yardData.YardId,
                FgPositioning: state => state.yardManagement.FgPositioning,
                dropItemEvent: state => state.yardManagement.dropItemEvent,
                dataBl: state => state.yardManagement.dataBl,
                dataContainer: state => state.yardManagement.dataContainer,
            })
        },
        watch:{
            dropItemEvent: function (val) {
                if (val==3) {
                    this.showIndex = 0;
                    this.dataBlHouseContainer = [];
                    this.getBlHousePendingsReception(false);
                }
            }
        }
    }
</script>
<style lang="scss">
      .icon{
          background-color: null; 
          border: 0px;
      }
      .table-index {
          table {
              td {
              vertical-align: middle !important;
              }
          }
      }
</style>
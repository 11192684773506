<template>
    <div class="mb-3">
        <div v-show="showIndex==0" class="my-3">
            <CRow class="mb-3">
                <CCol sm="12" lg="12" class="d-flex align-items-start justify-content-start">
                    <CRow class="align-items-center justify-content-start w-100 m-0">
                        <h6 class="mt-2"><CIcon name='cil-list' size='lg' class="titulo-icono" /> {{ `${this.$t('label.blsHousePendingReceive')}`}} </h6>
                    </CRow>
                </CCol>
            </CRow>
            <CRow>
            <CCol sm="12">
                <dataTableExtended
                    class="align-center-row-datatable"
                    :items="computedListContainer"
                    :fields="fields"
                    :items-per-page="5"
                    column-filter
                    pagination
                    :table-filter="tableText.tableFilterText"
                    :items-per-page-select="tableText.itemsPerPageText"
                    :noItemsView="tableText.noItemsViewText"
                    sorter
                >
                    <template #YardCargoBlStatus="{ item }">
                        <td class="cell-center text-center">
                            <CBadge :color="getBadge(item.YardCargoBlStatus)">
                            {{ item.YardCargoBlStatus }}
                            </CBadge>
                        </td>
                    </template>
                    <template #VisitBillOfLadingStatus="{ item }">
                        <td class="cell-center text-center">
                            <CBadge :color="getBadge(item.VisitBillOfLadingStatus)">
                            {{ item.VisitBillOfLadingStatus }}
                            </CBadge>
                        </td>
                    </template>
                    <template #options="{ item }">
                        <td class="text-center">
                            <CButton
                                square
                                size="sm"
                                color="watch"
                                class="d-flex align-items-center"
                                @click="toggleAdd(item)"
                                v-c-tooltip="{
                                    content: $t('label.reception'),
                                    placement: 'top-start'
                                }"
                            >
                                <CIcon name='cil-list' />
                            </CButton>
                        
                        </td>
                    </template>
                </dataTableExtended>
            </CCol>
            </CRow>
        </div>
        <div v-show="showIndex==1">
            <BlRecepcion
                :Active="showIndex==1"
                :ContainerItem="ContainerItem"
                :isGeneralList = true
                :isEdit = "false"
                @Close="showIndex=0, ContainerItem={}"
                @Update-list="Update"
            />
        </div>
    </div>
</template>
<script>
    import { mapState } from 'vuex';
    import General from '@/_mixins/general';
    import BlRecepcion from '@/pages/bl-query/taps/movements/events/pendingHouse/BlReception/reception'
    import { DateFormater, NumberFormater } from '@/_helpers/funciones';

    //data
    function data() {
        return {
            Items: [],
            ContainerItem:{},
            dataDesconsolited: [],
            dataBlHouse: [],
            showIndex: 0,
            YardCargoEventDetailId: ''
        };
    }

    //methods
    async function getBlHousePendingsReception(loading, dataLocalBl) {
        await this.$http.get('BlCargoHousePendingReception-list', { YardId: this.YardId })
            .then((response) => {
                let List = response.data.data;
                this.dataBlHouse = List&&List.length!=0 ? List : [];
            })
            .catch( err => {
                this.$notify({
                    group: 'container',
                    title: '¡Error!',
                    text: err,
                    type: "error"
                });
            })
            .finally(async() => {
                this.$store.state.yardManagement.loading = loading;
                if (this.FgPositioning) {
                    if(dataLocalBl)
                        this.$store.state.yardManagement.blSelected = dataLocalBl.BlCargoId;
                    else
                        this.$store.state.yardManagement.blSelected = this.$store.state.yardManagement.dataBl.BlCargoId;
                }
            });
    }

    async function getYardBlCargo(dataLocalBl) {
        this.$store.state.yardManagement.loading = true;
        await this.$http.get('BlCargo-by-Id', {BlCargoId: dataLocalBl.BlCargoId})
            .then(response => {
            let res = response.data.data;
            if (res && res?.length>0) {
                this.$store.state.yardManagement.dataBl = res[0];
            }
            })
            .catch( err => {
            this.$store.state.yardManagement.loading = false;
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
            })
        this.$store.state.yardManagement.loading = false;
    }

    async function Update(loading, dataLocalBl) {
        this.showIndex=0;
        this.ContainerItem={};
        await this.getBlHousePendingsReception(loading, dataLocalBl);
    }

    function toggleAdd(item) {
        this.ContainerItem = {...item, YardCargoEventDetailId : item.YardCargoEventDetailId};
        this.showIndex = 1;
    }

    function getBadge(status) {
        switch (status) {
            case 'INACTIVO':
            return 'danger'
            default:
            return 'success'
        }
    }
    
    //computed
    function computedListContainer() {
        let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
        return this.dataBlHouse.map((item, index) => {
            let Detail =  item.DetailJson?.map((e)=> {return e[`PackagingName${_lang}`]})?.join(', ') ?? ''

            return {
                ...item,
                Nro: index+1,
                TpCargoName: item[`TpCargoName${_lang}`] ?? 'N/A',
                PortActivityName: item[`PortActivityName${_lang}`] ?? 'N/A',
                VesselVoyage: `${item.VesselName} - ${item.Voyage}`,
                Ata: item.Ata ? DateFormater.formatDateTimeWithSlash(item.Ata) : 'N/A',
                Atd: item.Atd ? DateFormater.formatDateTimeWithSlash(item.Atd) : 'N/A',
                FirstEventName: item[`FirstEventName${_lang}`] ?? 'N/A',
                FirstEventDate: item.FirstEventDate ? DateFormater.formatDateTimeWithSlash(item.FirstEventDate) : '',
                LastEventName: item[`LastEventName${_lang}`] ?? 'N/A',
                LastEventDate: item.LastEventDate ? DateFormater.formatDateTimeWithSlash(item.LastEventDate) : '',
                YardCargoBlStatus: item[`YardCargoBlStatus${_lang}`] ?? 'N/A',
                BlStatus: item[`BlStatus${_lang}`] ?? 'N/A',
                TpBlName: item.TpBlName ?? '',
                QuantityReceived: item.QuantityReceived ? NumberFormater.formatNumber(item.QuantityReceived, 2) : '0,00',
                WeigthInYard: item.WeigthInYard ? NumberFormater.formatNumber(item.WeigthInYard, 2) : '0,00',
                DocumentCode: item.DocumentCode ?? '',
                ClientName: item.ClientName ?? '',
                DaysInYard: item.DaysInYard ?? '',
                MasterBlNro: item.MasterBlNro ?? '',
                _classes: 'color-gradient',
                _style: `background:${item.YardCargoBlStatusColor}`
            };
        })
    }

    function fields(){ 
        return [
            { key: 'options', label: '', _style: 'min-width:45px;', sorter: false, filter: false },
            { key: 'Nro', label: '#', _style: 'width:50px', _classes: 'text-center', filter: false },
            { key: 'BlNro', label: this.$t('label.bl'),  _style:'min-width:180px;', _classes:" text-center" , sorter: true, filter: true},
            { key: 'MasterBlNro', label: this.$t('label.Master'),  _style:'min-width:180px;', _classes:" text-center" , sorter: true, filter: true},
            { key: 'TpBlName', label: this.$t('label.blType'),  _classes: 'text-uppercase text-center', _style: 'min-width:140px;', sorter: true, filter: true},
            { key: 'DocumentCode', label: this.$t('label.Acta'),  _classes: 'text-uppercase text-center', _style: 'min-width:180px;', sorter: true, filter: true},
            { key: 'ClientName', label: this.$t('label.client'), _style:'min-width:200px', _classes:'text-uppercase text-center center-cell' },
            { key: 'TpCargoName',label: this.$t('label.group'), _style:'min-width:230px;', _classes:'text-center' },
            { key: 'PortActivityName',label: this.$t('label.activity'), _style:'min-width:230px;', _classes:'text-center' },
            { key: 'VesselVoyage', label: this.$t('label.visit'),  _classes: 'text-uppercase text-center', _style: 'min-width:235px;', sorter: true, filter: true},
            { key: 'Ata', label: 'ATA',  _classes: 'text-uppercase text-center', _style: 'min-width:150px;', sorter: true, filter: true},
            { key: 'QuantityReceived', label: this.$t('label.ReceivedQuantity'),  _classes: 'text-uppercase text-center', _style: 'min-width:160px;', sorter: true, filter: true},
            { key: 'WeigthInYard', label: `${this.$t('label.weight')} (KGM)`,  _classes: 'text-uppercase text-center', _style: 'min-width:180px;', sorter: true, filter: true},
            { key: 'DaysInYard', label: `${this.$t('label.daysInYard')}`,  _classes: 'text-uppercase text-center', _style: 'min-width:160px;', sorter: true, filter: true},
            { key: 'FirstEventName', label: this.$t('label.firstEvent'),  _classes: 'text-uppercase text-center', _style: 'min-width:200px;', sorter: true, filter: true},
            { key: 'FirstEventDate', label: this.$t('label.dateFierstEvent'),  _classes: 'text-uppercase text-center', _style: 'min-width:200px;', sorter: true, filter: true},
            { key: 'LastEventName', label: this.$t('label.LastEvent'),  _classes: 'text-uppercase text-center', _style: 'min-width:200px;', sorter: true, filter: true},
            { key: 'LastEventDate', label: this.$t('label.DateLastEvent'),  _classes: 'text-uppercase text-center', _style: 'min-width:200px;', sorter: true, filter: true},
            { key: 'YardCargoBlStatus', label: this.$t('label.statusYardWarehouse'),  _classes: 'text-uppercase text-center', _style: 'min-width:260px;', sorter: true, filter: true},  
            { key: 'BlStatus', label: this.$t('label.statusCertificate'),  _classes: 'text-uppercase text-center', _style: 'min-width:260px;', sorter: true, filter: true},
        ];
    }

    export default{
        name: 'pendingsBls',
        data,
        mixins: [General],
        components: {
            BlRecepcion,
        },
        methods: {
            toggleAdd,
            getBlHousePendingsReception,
            Update,
            getYardBlCargo,
            getBadge
        },
        computed: {
            fields,
            computedListContainer,
            ...mapState({
                globalSelectBl: state => state.yardManagement.globalSelectBl,
                CompanyBranchId: state => state.auth.branch.CompanyBranchId,
                yardData: state => state.yardManagement.yardData,
                YardId: state => state.yardManagement.yardData.YardId,
                FgPositioning: state => state.yardManagement.FgPositioning,
                dropItemMovementList: state => state.yardManagement.dropItemMovementList,
                dataBl: state => state.yardManagement.dataBl,
                dataContainer: state => state.yardManagement.dataContainer,
            })
        },
        watch:{
            dropItemMovementList: async function (val) {
                if (val==5) {
                    this.$store.state.yardManagement.loading = true;
                    this.showIndex = 0;
                    this.dataBlHouse = [];
                    await this.getBlHousePendingsReception(false);
                    this.$store.state.yardManagement.loading = false;
                }
            }
        }
    }
</script>
<style lang="scss">
      .icon{
          background-color: null; 
          border: 0px;
      }
      .table-index {
          table {
              td {
              vertical-align: middle !important;
              }
          }
      }
</style>
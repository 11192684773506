<template>
    <div class="mb-3">
        <div v-show="showIndex==0">
            <CRow class="mb-3">
                <CCol sm="12" lg="6" xl="6" class="d-flex align-items-center justify-content-start">
                    <h6 class="mt-2"><CIcon name='cil-list' size='lg' class="titulo-icono" /> {{ `${this.$t('label.receiptList')}`}} </h6>
                </CCol>
                <CCol sm="12" lg="6" xl="6" class="d-flex align-items-center justify-content-end">
                    <CButton
                        color="excel"
                        class="mr-1" 
                        size="sm"
                        @click="onBtnExport(true)"
                    >
                    <CIcon name="cil-file-excel"/>&nbsp; XSLX
                    </CButton>
                </CCol>
            </CRow>
            <CRow class="mb-3">
                <CCol sm="12">
                    <CTabs variant="tabs" :active-tab="tabIndex" @update:activeTab="handleTab">
                        <CTab :title="$t('label.packaging')">
                            <CRow class="mt-2">
                                <CCol sm="12">
                                    <dataTableExtended
                                        class="align-center-row-datatable"
                                        :items="computedListReceptionBl"
                                        :fields="blFields"
                                        :items-per-page="5"
                                        column-filter
                                        pagination
                                        :table-filter="tableText.tableFilterText"
                                        :items-per-page-select="tableText.itemsPerPageText"
                                        :noItemsView="tableText.noItemsViewText"
                                        sorter
                                    >
                                        <template #Status="{item}">
                                            <td class='text-center'>
                                            <CBadge :color="getBadge(item.Status)">
                                                {{ $t('label.'+item.Status) }}
                                            </CBadge>
                                            </td>
                                        </template>
                                        <template #options="{ item }">
                                            <td class="text-center">
                                                <CButton
                                                    square
                                                    size="sm"
                                                    color="edit"
                                                    class="mr-1"
                                                    @click="toggleAdd(item, false)"
                                                    v-c-tooltip="{
                                                        content: $t('label.reception'),
                                                        placement: 'top-start'
                                                    }"
                                                    v-if="item.Status != 'INACTIVO' && item.Status != 'INACTIVE' && ((!fgCertificate && YardId) ||  !YardId)"
                                                >
                                                    <CIcon name="pencil" />
                                                </CButton>
                                                <CButton
                                                    size="sm"
                                                    square
                                                    color="danger"
                                                    @click="togglePdf(item)"
                                                    v-c-tooltip="{
                                                        content: $t('label.OrderOfEntry'),
                                                        placement: 'top-start'
                                                    }"
                                                >
                                                    <CIcon name="cil-file-pdf" />
                                                </CButton>
                                            </td>
                                        </template>

                                        <template #Weight="{ item }">
                                            <td class="text-center">
                                                {{ NumberFormat(item.Weight, 2) }}
                                            </td>
                                        </template>
                                    </dataTableExtended>
                                </CCol>
                            </CRow>
                        </CTab>
                        <CTab :title="$t('label.Containers')">
                            <CRow class="mt-2">
                                <CCol sm="12">
                                    <dataTableExtended
                                        class="align-center-row-datatable"
                                        :items="computedContainerListContainer"
                                        :fields="Containerfields"
                                        :items-per-page="5"
                                        column-filter
                                        pagination
                                        :table-filter="tableText.tableFilterText"
                                        :items-per-page-select="tableText.itemsPerPageText"
                                        :noItemsView="tableText.noItemsViewText"
                                        sorter
                                    >
                                        <template #Status="{item}">
                                            <td class='text-center'>
                                            <CBadge :color="getBadge(item.Status)">
                                                {{ $t('label.'+item.Status) }}
                                            </CBadge>
                                            </td>
                                        </template>
                                        <template #options="{ item }">
                                            <td class="text-center">
                                                <CButton
                                                    square
                                                    size="sm"
                                                    color="edit"
                                                    class="mr-1"
                                                    @click="toggleAdd(item, true)"
                                                    v-c-tooltip="{
                                                        content: $t('label.reception'),
                                                        placement: 'top-start'
                                                    }"
                                                    v-if="item.Status != 'INACTIVO' && item.Status != 'INACTIVE' && ((!fgCertificate && YardId) ||  !YardId)"
                                                >
                                                <CIcon name="pencil" />
                                                </CButton>
                                                <CButton
                                                    size="sm"
                                                    square
                                                    color="danger"
                                                    @click="togglePdf(item)"
                                                    v-c-tooltip="{
                                                        content: $t('label.OrderOfEntry'),
                                                        placement: 'top-start'
                                                    }"
                                                >
                                                    <CIcon name="cil-file-pdf" />
                                                </CButton>
                                            </td>
                                        </template>

                                        <template #Weight="{ item }">
                                            <td class="text-center">
                                                {{ NumberFormat(item.Weight, 2) }}
                                            </td>
                                        </template>
                                    </dataTableExtended>
                                </CCol>
                            </CRow>
                        </CTab>
                    </CTabs>
                </CCol>
            </CRow>
        </div>
        <div v-show="showIndex==1">
            <BlRecepcion
                v-show="!this.isInternalTranfer"
                :Active="showIndex==1 && !this.isInternalTranfer"
                :ContainerItem="ContainerItem"
                :isEdit = "true"
                @Close="showIndex=0, ContainerItem={}"
                @Update-list="Update"
            />
      
            <BlInternalRecepcion
                v-show="this.isInternalTranfer"
                :Active="showIndex==1 && this.isInternalTranfer"
                :FgInternalTransfer="true"
                :isEdit="true"
				:ContainerItem="ContainerItem"
                :isOfContainer="false"
				@Close="showIndex = 0, ContainerItem={}, isInternalTranfer = false, isEdit=false"
				@Update-list="Update"
            /> 
        </div>
    </div>
</template>
<script>
    import { mapState } from 'vuex';
    import General from '@/_mixins/general';
    import YardManagement from '@/_mixins/yardManagement';
    import BlRecepcion from './BlReception/reception';
    import BlInternalRecepcion from '@/pages/yard-management/container/movements/reception/internal-transfer-register';
    import { DateFormater, NumberFormater, removeTags } from '@/_helpers/funciones';

    //data
    function data() {
        return {
            tabIndex: 0,
            Items: [],
            ContainerItem:{},
            dataBls: [],
            ReportJson: [],
            showIndex: 0,
            isInternalTranfer: false,
        };
    }

    //methods
    function NumberFormat(number, decimal) {
        return NumberFormater.formatNumber(number, decimal);
    }

    async function getYardBlCargo() {
        this.$store.state.yardManagement.loading = true;
        await this.$http.get('BlCargo-by-Id', {BlCargoId: this.dataBl.BlCargoId})
            .then(response => {
            let res = response.data.data;
            if (res && res?.length>0) {
                this.$store.state.yardManagement.dataBl = res[0];
            }
            })
            .catch( err => {
            this.$store.state.yardManagement.loading = false;
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
            })
        this.$store.state.yardManagement.loading = false;
    }

    async function getBlReception(loading) {
        this.$store.state.yardManagement.loading = true;
        await this.$http.get('YardWCargoReception-by-BlCargoYardId', { BlCargoId: this.dataBl.BlCargoId, YardId: this.YardId ?? ''})
            .then((response) => {
                let List = response.data.data;
                this.dataBls = List&&List.length!=0 ? List[0] : []

                if(this.dataBls.ReportJson)
                    this.ReportJson = this.dataBls.ReportJson
            })
            .catch( err => {
                this.$notify({
                    group: 'container',
                    title: '¡Error!',
                    text: err,
                    type: "error"
                });
            })
            .finally(async() => {
                this.$store.state.yardManagement.loading = loading;
                if (this.FgPositioning) {
                    this.$store.state.yardManagement.ContainerTabIndex = 3;
                    this.$store.state.yardManagement.dropBlMovement = 6;
                    this.$store.state.yardManagement.blSelected = this.$store.state.yardManagement.dataBl.BlCargoId;
                }
            });
    }

    function togglePdf(item) {
        this.$store.state.yardManagement.loading = true;
        this.$http.get("YardDocumentRecoveryOrderReport", { YardDocumentId: item.YardDocumentId })
        .then(async(response) => {
            let pdf = response.data.data?.[0] ?? undefined;
            if (pdf) {
                await this.getPassPdf(pdf, this.$t('label.OrderOfEntry'), 2);
            }
        }).catch( err => {
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: err,
                type: "error"
            });
        })
        .finally(() => {
            this.$store.state.yardManagement.loading = false;
        });
    }

    async function Update(loading) {
        this.showIndex=0;
        this.ContainerItem={};
        await this.getBlReception(loading);
        await this.getYardBlCargo();

        if(this.dataBls?.ContainerJson?.length>0 && this.computedContainerListContainer.some((e)=>{return (e.Status == "ACTIVO" || e.Status == "ACTIVE")}))
            return false

        if(this.dataBls?.PackagingJson?.length>0 && this.computedListReceptionBl.some((e)=>{return (e.Status == "ACTIVO" || e.Status == "ACTIVE")}))
            return false

        this.$store.state.yardManagement.yardCollapse = 1;
        this.$store.state.yardManagement.FgBlWarehouse = true;
        this.$store.state.yardManagement.loading = false;
    }

    function toggleAdd(item, isTranfer = false) {
        this.ContainerItem = item;
        this.isInternalTranfer = isTranfer;
        this.showIndex = 1;
    }

    function getBadge(status) {
        switch (status) {
            case 'INACTIVO':
            return 'danger'
            default:
            return 'success'
        }
    }

    function handleTab(tab) {
        this.tabIndex = tab;
    }

    //----- Excel section -------
    async function onBtnExport(valor){
        let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
        let dataList = [];
        let moreOpcions = {
            backgroundColorInTitle: '#4e5a70',
            colorLetterInTitle: '#ffffff',
        }

        dataList = this.ReportJson;

        let filters = [
            this.$t('label.printed')+": "+DateFormater.formatTimeZoneToDateTime(new Date()),
            this.$t('label.user')+": "+this.user.UserName+(this.user.UserLastName ? ' '+this.user.UserLastName : ''),
        ]

        dataList = dataList.map((e)=>{  
            return {...e,
                TransactionDate: e.TransactionDate ? DateFormater.formatDateTimeWithSlash(e.TransactionDate) : '',
                TransaRegDate: e.TransaRegDate ? DateFormater.formatDateTimeWithSlash(e.TransaRegDate) : '',
                Vehicle: e.LicensePlate ? `${e.LicensePlate} - ${e['TpVehicleName'+_lang]}` :  '',
                Driver: e.DriverCi ? `${e.DriverCi} - ${e.DriverName}` :  '',
                DocumentCode: e.DocumentCode ?? ''
            }
        });
        
        await this.excel([this.excelHeader()], [dataList], this.$t('label.receiptList'), this.BranchName, `${this.$t('label.ReceptionByBl')}`, [filters], [], false, this.PersonalfilterCell, this.GroupTitle, moreOpcions);
        this.$store.state.yardManagement.loading = false;
    }

    function PersonalfilterCell (worksheet, cellMerge, cellStyles, CellNumber) {
        let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
        let cell = {};

        //Data of Rows
        let custom = [
            [
            `${this.$t('label.bl')}:`, `${this.dataBl.BlNro??''}`, '',
            `${this.$t('label.Master')}:`, `${this.dataBl?.MasterBlNro??''}`,'',
            `${this.$t('label.consignee')}:`, `${this.dataBl?.ConsigneeName??''}`, '', 
            `${this.$t('label.status')}:`, `${this.dataBl[`YardCargoBlStatus${_lang}`]??''}`, '', 
            `${this.$t('label.plannedQuantity')}:`, this.dataBl.QuantityPlanning, '', 
            `${this.$t('label.plannedWeight')}:`, this.dataBl.WeigthPlanning, '',
            `${this.$t('label.plannedVolumen')}:`, this.dataBl.VolumenPlanning, '',
            `${this.$t('label.DescriptionOfGoods')}:`, `${removeTags(this.dataBl.DescriptionOfGoods??'')}`,
            ],
            [
            `${this.$t('label.type')}:`, `${this.dataBl.TpBlName??''}`, '',
            `${this.$t('label.PackagingOrigin')}:`,  `${this.dataBl?.BlMasterJson?.[0]?.[`PackagingName${_lang}`]??''}`,'',
            `${this.$t('label.CustomBroker')}:`,  `${this.dataBl.CustomBrokerName??''}`, '', 
            `${this.$t('label.Acta')}:`, `${this.dataBl?.DocumentYardJson?.[0]?.DocumentCode??''}`, '', 
            `${this.$t('label.ReceivedQuantity')}:`, this.dataBl.QuantityReceived, '', 
            `${this.$t('label.receivedWeight')}:`, this.dataBl.WeigthReception, '',
            `${this.$t('label.receivedVolumen')}:`, this.dataBl.VolumenReception
            ],
            [
            `${this.$t('label.status')}:`, `${this.dataBl[`BlStatus${_lang}`]??''}`, '',
            `${this.$t('label.SerialOrigin')}:`, `${this.dataBl?.BlMasterJson?.[0]?.Serial??''}`,'',
            `${this.$t('label.Ship')}:`,  `${this.dataBl?.VesselName??''}`, '', 
            `${this.$t('label.statusCertificate')}:`, `${this.dataBl?.DocumentYardJson?.[0]?.[`YardDocumentStatus${_lang}`]??''}`, '', 
            `${this.$t('label.CertifiedQuantity')}:`, this.dataBl.QuantityCertified, '', 
            `${this.$t('label.CertifiedWeight')}:`, this.dataBl.WeigthCertified, '',
            `${this.$t('label.CertifiedVolumen')}:`, this.dataBl.VolumenCertified
            ],
            [
            `${this.$t('label.portActivity')}:`, `${this.dataBl[`PortActivityName${_lang}`]??''}`, '',
            `${this.$t('label.type')}:`,  `${this.dataBl?.BlMasterJson?.[0]?.[`TpCargoName${_lang}`]??''}`,'',
            `${this.$t('label.visit')}:`,  `${this.dataBl.Voyage} - ${this.dataBl.Arrival ? DateFormater.formatDateTimeWithSlash(this.dataBl.Arrival) : ''}`, '', 
            `${this.$t('label.firstEvent')}:`, `${this.dataBl[`FirstEventName${_lang}`]??''}`, '', 
            `${this.$t('label.dispachedQuantity')}:`, this.dataBl.QuantityDispatched, '', 
            `${this.$t('label.dispachedWeight')}:`, this.dataBl.WeigthDispatch, '',
            `${this.$t('label.dispachedVolumen')}:`, this.dataBl.VolumenDispatch
            ],
            [
            `${this.$t('label.group')}:`, `${this.dataBl[`TpCargoName${_lang}`]??''}`, '',
            `${this.$t('label.receptionDate')}:`, `${this.dataBl?.BlMasterJson?.[0]?.ReceptionDate ? DateFormater.formatDateTimeWithSlash(this.dataBl?.BlMasterJson?.[0]?.ReceptionDate) : ''}`,'',
            `${'POL'}:`,  `${this.dataBl.LoadPortName??''}`, '', 
            `${this.$t('label.dateFierstEvent')}:`, `${this.dataBl.FirstEventDate ? DateFormater.formatDateTimeWithSlash(this.dataBl.FirstEventDate) : ''}`, ''
            ],
            [
            `${this.$t('label.commodity')}:`, `${this.dataBl[`CommodityName${_lang}`]??''}`, '',
            `${this.$t('label.dispatchDate')}:`, `${this.dataBl?.BlMasterJson?.[0]?.DispatchDate ? DateFormater.formatDateTimeWithSlash(this.dataBl?.BlMasterJson?.[0]?.DispatchDate) : ''}`,'',
            `${'POD'}:`,  `${this.dataBl.DischargePortName??''}`, '', 
            `${this.$t('label.LastEvent')}:`, `${this.dataBl[`LastEventName${_lang}`]??''}`
            ],
            [
            `${this.$t('label.Procedence')}:`, `${this.dataBl.Procedence??''}`, '',
            `${this.$t('label.status')}:`,  `${this.dataBl?.BlMasterJson?.[0]?.[`YardCargoBlMasterDetailSerialStatusDs${_lang}`]??''}`,'',
            `${this.$t('label.dua')}:`,  `${this.dataBl.Dua??''}`, '', 
            `${this.$t('label.DateLastEvent')}:`, `${this.dataBl.LastEventDate ? DateFormater.formatDateTimeWithSlash(this.dataBl.LastEventDate) : ''}`
            ],
            [
            '', '', '',
            `${this.$t('label.Day')}:`,   `${this.dataBl?.BlMasterJson?.[0]?.DaysInYard??''}`,'',
            `${this.$t('label.BolipuertoCertificateEntry')}:`,  `${this.dataBl.ReceptionBpAct??''}`
            ]
        ]

        //Row Injection
        for (let i = 0; i <= 7; i++) {
            worksheet.addRow(custom[i])
        }

        //Merge of cells
        worksheet.mergeCells(cellMerge(1, 14, 2, 14));
        worksheet.mergeCells(cellMerge(3, 7, 3, 14));
        worksheet.mergeCells(cellMerge(6, 7, 6, 14));
        worksheet.mergeCells(cellMerge(9, 7, 9, 14));
        worksheet.mergeCells(cellMerge(10, 14, 11, 14));
        worksheet.mergeCells(cellMerge(12, 7, 12, 14));
        worksheet.mergeCells(cellMerge(13, 11, 14, 14));
        worksheet.mergeCells(cellMerge(15, 7, 15, 14));
        worksheet.mergeCells(cellMerge(16, 11, 17, 14));
        worksheet.mergeCells(cellMerge(18, 7, 18, 14));
        worksheet.mergeCells(cellMerge(19, 11, 20, 14));
        worksheet.mergeCells(cellMerge(21, 7, 21, 14));
        worksheet.mergeCells(cellMerge(22, 7, 22, 14));
        worksheet.mergeCells(cellMerge(23, 7, 24, 14));

        //Styles of Cells
        //COL A
        cellStyles(worksheet, 1, 7, true, 10, 26, "",  "000000", 11, false, 'right');
        cellStyles(worksheet, 1, 8, true, 10, 26, "",  "000000", 11, false, 'right');
        cellStyles(worksheet, 1, 9, true, 10, 26, "",  "000000", 11, false, 'right');
        cellStyles(worksheet, 1, 10, true, 10, 26, "",  "000000", 11, false, 'right');
        cellStyles(worksheet, 1, 11, true, 10, 26, "",  "000000", 11, false, 'right');
        cellStyles(worksheet, 1, 12, true, 10, 26, "",  "000000", 11, false, 'right');
        cellStyles(worksheet, 1, 13, true, 10, 26, "",  "000000", 11, false, 'right');

        //COL D
        cellStyles(worksheet, 4, 7, true, 10, 26, "",  "000000", 11, false, 'right');
        cellStyles(worksheet, 4, 8, true, 10, 26, "",  "000000", 11, false, 'right');
        cellStyles(worksheet, 4, 9, true, 10, 26, "",  "000000", 11, false, 'right');
        cellStyles(worksheet, 4, 10, true, 10, 26, "",  "000000", 11, false, 'right');
        cellStyles(worksheet, 4, 11, true, 10, 26, "",  "000000", 11, false, 'right');
        cellStyles(worksheet, 4, 12, true, 10, 26, "",  "000000", 11, false, 'right');
        cellStyles(worksheet, 4, 13, true, 10, 26, "",  "000000", 11, false, 'right');
        cellStyles(worksheet, 4, 14, true, 10, 26, "",  "000000", 11, false, 'right');

        //COL G
        cellStyles(worksheet, 7, 7, true, 10, 26, "",  "000000", 11, false, 'right');
        cellStyles(worksheet, 7, 8, true, 10, 26, "",  "000000", 11, false, 'right');
        cellStyles(worksheet, 7, 9, true, 10, 26, "",  "000000", 11, false, 'right');
        cellStyles(worksheet, 7, 10, true, 10, 26, "",  "000000", 11, false, 'right');
        cellStyles(worksheet, 7, 11, true, 10, 26, "",  "000000", 11, false, 'right');
        cellStyles(worksheet, 7, 12, true, 10, 26, "",  "000000", 11, false, 'right');
        cellStyles(worksheet, 7, 13, true, 10, 26, "",  "000000", 11, false, 'right');
        cellStyles(worksheet, 7, 14, true, 10, 26, "",  "000000", 11, false, 'right');

        //COL J
        cellStyles(worksheet, 10, 7, true, 10, 26, "",  "000000", 11, false, 'right');
        cellStyles(worksheet, 10, 8, true, 10, 26, "",  "000000", 11, false, 'right');
        cellStyles(worksheet, 10, 9, true, 10, 26, "",  "000000", 11, false, 'right');
        cellStyles(worksheet, 10, 10, true, 10, 26, "",  "000000", 11, false, 'right');
        cellStyles(worksheet, 10, 11, true, 10, 26, "",  "000000", 11, false, 'right');
        cellStyles(worksheet, 10, 12, true, 10, 26, "",  "000000", 11, false, 'right');
        cellStyles(worksheet, 10, 13, true, 10, 26, "",  "000000", 11, false, 'right');

        //COL M
        cellStyles(worksheet, 13, 7, true, 10, 26, "",  "000000", 11, false, 'right');
        cell = worksheet.getCell(CellNumber(14, 7))
        cell.numFmt = '#,##0.00;[Red]\-#,##0.00';
        cell.alignment = { horizontal: 'left', vertical: 'middle', wrapText: true };
        cellStyles(worksheet, 13, 8, true, 10, 26, "",  "000000", 11, false, 'right');
        cell = worksheet.getCell(CellNumber(14, 8))
        cell.numFmt = '#,##0.00;[Red]\-#,##0.00';
        cell.alignment = { horizontal: 'left', vertical: 'middle', wrapText: true };
        cellStyles(worksheet, 13, 9, true, 10, 26, "",  "000000", 11, false, 'right');
        cell = worksheet.getCell(CellNumber(14, 9))
        cell.numFmt = '#,##0.00;[Red]\-#,##0.00';
        cell.alignment = { horizontal: 'left', vertical: 'middle', wrapText: true };
        cellStyles(worksheet, 13, 10, true, 10, 26, "",  "000000", 11, false, 'right');
        cell = worksheet.getCell(CellNumber(14, 10))
        cell.numFmt = '#,##0.00;[Red]\-#,##0.00';
        cell.alignment = { horizontal: 'left', vertical: 'middle', wrapText: true };

        //COL P
        cellStyles(worksheet, 16, 7, true, 10, 26, "",  "000000", 11, false, 'right');
        cell = worksheet.getCell(CellNumber(17, 7))
        cell.numFmt = '#,##0.00;[Red]\-#,##0.00';
        cell.alignment = { horizontal: 'left', vertical: 'middle', wrapText: true };
        cellStyles(worksheet, 16, 8, true, 10, 26, "",  "000000", 11, false, 'right');
        cell = worksheet.getCell(CellNumber(17, 8))
        cell.numFmt = '#,##0.00;[Red]\-#,##0.00';
        cell.alignment = { horizontal: 'left', vertical: 'middle', wrapText: true };
        cellStyles(worksheet, 16, 9, true, 10, 26, "",  "000000", 11, false, 'right');
        cell = worksheet.getCell(CellNumber(17, 9))
        cell.numFmt = '#,##0.00;[Red]\-#,##0.00';
        cell.alignment = { horizontal: 'left', vertical: 'middle', wrapText: true };
        cellStyles(worksheet, 16, 10, true, 10, 26, "",  "000000", 11, false, 'right');
        cell = worksheet.getCell(CellNumber(17, 10))
        cell.numFmt = '#,##0.00;[Red]\-#,##0.00';
        cell.alignment = { horizontal: 'left', vertical: 'middle', wrapText: true };
        
        //COL S
        cellStyles(worksheet, 19, 7, true, 10, 26, "",  "000000", 11, false, 'right');
        cell = worksheet.getCell(CellNumber(20, 7))
        cell.numFmt = '#,##0.00;[Red]\-#,##0.00';
        cell.alignment = { horizontal: 'left', vertical: 'middle', wrapText: true };
        cellStyles(worksheet, 19, 8, true, 10, 26, "",  "000000", 11, false, 'right');
        cell = worksheet.getCell(CellNumber(20, 8))
        cell.numFmt = '#,##0.00;[Red]\-#,##0.00';
        cell.alignment = { horizontal: 'left', vertical: 'middle', wrapText: true };
        cellStyles(worksheet, 19, 9, true, 10, 26, "",  "000000", 11, false, 'right');
        cell = worksheet.getCell(CellNumber(20, 9))
        cell.numFmt = '#,##0.00;[Red]\-#,##0.00';
        cell.alignment = { horizontal: 'left', vertical: 'middle', wrapText: true };
        cellStyles(worksheet, 19, 10, true, 10, 26, "",  "000000", 11, false, 'right');
        cell = worksheet.getCell(CellNumber(20, 10))
        cell.numFmt = '#,##0.00;[Red]\-#,##0.00';
        cell.alignment = { horizontal: 'left', vertical: 'middle', wrapText: true };

        //COL V
        cellStyles(worksheet, 22, 7, true, 10, 26, "",  "000000", 11, false,);

        //COL w
        cellStyles(worksheet, 23, 7, true, 10, 26, "",  "000000", 11, false, false, false);

        //CONTROL ROWS
        let fieldLast = 9;
        worksheet.addRow([]);
        
        return fieldLast
    }

    function GroupTitle (worksheet, fieldLast, cellMerge, cellStyles) {
        let custom = ['', '', '', '', '', this.$t('label.packagings'), '', '', '',   this.$t('label.serials'), '', '', '', '', '', '', '', '', '',         '', '', '', '', '', '']
        worksheet.addRow(custom)
        worksheet.mergeCells(cellMerge(6, fieldLast, 9, fieldLast));
        cellStyles(worksheet, 6, fieldLast, true, 10, 26, "ed7d31", "ffffff", 12, true);
        worksheet.mergeCells(cellMerge(10, fieldLast, 18, fieldLast));
        cellStyles(worksheet, 10, fieldLast, true, 10, 26, "1f3864", "ffffff", 12, true);
    }

    function excelHeader() {
        let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);

        return [   
            { text: '#', value: "Nro", aux: "", json: "", isNotFormater: true },
            { text: this.$t('label.receptionDate'), value: "TransactionDate", aux: "", json: "" },
            { text: this.$t('label.ubication'), value: "YardName", aux: "", json: "" },
            { text: this.$t('label.transaction'), value: "TpTransacEirAcronym", aux: "", json: "" },    
            { text: this.$t('label.machine'), value: "MachineCode", aux: "", json: "" },    
            { text: this.$t('label.packaging'), value: `PackagingName${_lang}`, aux: "", json: "DetailJson" },
            { text: this.$t('label.quantity'), value: "Quantity", aux: "", json: "DetailJson" },
            { text: `${this.$t('label.weight')} (KGM)`, value: "Weigth", aux: "", json: "DetailJson"},
            { text: `${this.$t('label.volume')} (M³)`, value: "Volumen", aux: "", json: "DetailJson"},
            { text: this.$t('label.serial'), value: "Serial", aux: "", json: "DetailJson", json2: "SerialJson" },
            { text: this.$t('label.imdg'), value: "ImdgCod", aux: "", json: "DetailJson", json2: "SerialJson"},
            { text: this.$t('label.quantity'), value: "Quantity", aux: "", json: "DetailJson", json2: "SerialJson" },
            { text: `${this.$t('label.weight')} (KGM)`, value: "Weigth", aux: "", json: "DetailJson", json2: "SerialJson" },
            { text: `${this.$t('label.length')}`, value: "Length", aux: "", json: "DetailJson", json2: "SerialJson" },
            { text: `${this.$t('label.width')}`, value: "Width", aux: "", json: "DetailJson", json2: "SerialJson" },
            { text: `${this.$t('label.volume')} (M³)`, value: "Volumen", aux: "", json: "DetailJson", json2: "SerialJson" },
            { text: this.$t('label.Seal'), value: "Seal", aux: "", json: "DetailJson", json2: "SerialJson" },
            { text: this.$t('label.DescriptionOfGoods'), value: "DescriptionOfGoods", json: "DetailJson", json2: "SerialJson" },
            { text: this.$t('label.transport'), value: "CarrierName", aux: "", json: ""},
            { text: this.$t('label.vehicle'), value: "Vehicle", aux: "", json: ""},
            { text: this.$t('label.driver'), value: "Driver", aux: "", json: ""},
            { text: this.$t('label.device'), value: "DeviceCod", aux: "", json: ""},
            { text: this.$t('label.user'), value: "TransaLogin", aux: "", json: ""},
            { text: this.$t('label.registerDate'), value: "TransaRegDate", aux: "", json: ""},
        ]
    }

    //---------------------------
    
    //computed
    function computedListReceptionBl() {
        let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);

        let blList = this.dataBls.PackagingJson ?? []

        return blList.map((item, index) => {
            let packagings = '';
            let serials = '';

            item?.DetailJson?.map((e,i)=>{
                packagings = `${packagings}${(i>0)? ', ' : ''}${e[`PackagingName${_lang}`]}(${e.Quantity})`

                e?.SerialJson?.map((e,it)=>{
                    serials= `${serials}${(it>0)? ', ' : ''}${e[`Serial`]}`
                })
            })

            return {
                ...item,
                Nro: index+1,
                YardName: item.YardName ? item.YardName : '',
                YardAreaName: item.YardAreaName ? item.YardAreaName : '',
                MachineCode: item.MachineCode ? item.MachineCode : '',
                TransactionDate: item.TransactionDate ? DateFormater.formatDateTimeWithSlash(item.TransactionDate) : '',
                TransaLogin: item.TransaLogin ? item.TransaLogin : '',
                TransaRegDate: item.TransaRegDate ? DateFormater.formatDateTimeWithSlash(item.TransaRegDate): '',
                packagings: packagings,
                ComputedWeight: item.Weigth ? NumberFormater.formatNumber(item.Weigth, 2) : '0,00',
                ComputedVolume: item.Volumen ? NumberFormater.formatNumber(item.Volumen, 2) : '0,00',
                Quantity: item.Quantity ? NumberFormater.formatNumber(item.Quantity, 2) : '0,00',
                CarrierName: item.CarrierName ?? 'N/A',
                Vehicle: item.LicensePlate ? `${item.LicensePlate} - ${item['TpVehicleName'+_lang]}` :  'N/A',
                Driver: item.DriverCi ? `${item.DriverCi} - ${item.DriverName}` :  'N/A',
                serials: serials,
                _classes: item.Status != 'ACTIVO' ? 'table-danger' : '',
            };
        })
    }

    function computedContainerListContainer() {
        let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
        
        let containerList = this.dataBls.ContainerJson?? []

        return containerList.map((item, index) => {
            let packagings = '';
            let serials = '';

            item?.DetailJson?.map((e,i)=>{
                packagings = `${packagings}${(i>0)? ', ' : ''}${e[`PackagingName${_lang}`]}(${e.Quantity})`

                e?.SerialJson?.map((e,it)=>{
                    serials= `${serials}${(it>0)? ', ' : ''}${e[`Serial`]}`
                })
            })

            return {
                ...item,
                Nro: index+1,
                YardName: item.YardName ? item.YardName : '',
                MachineCode: item.MachineCode ? item.MachineCode : '',
                TransactionDate: item.TransactionDate ? DateFormater.formatDateTimeWithSlash(item.TransactionDate) : '',
                TransaLogin: item.TransaLogin ? item.TransaLogin : '',
                TransaRegDate: item.TransaRegDate ? DateFormater.formatDateTimeWithSlash(item.TransaRegDate): '',
                ComputedWeight: item.Weigth ? NumberFormater.formatNumber(item.Weigth, 2) : '0,00',
                ComputedVolume: item.Volumen ? NumberFormater.formatNumber(item.Volumen, 2) : '0,00',
                _classes: item.Status != 'ACTIVO' ? 'table-danger' : '',
                ContainerCode: item.ContainerCode ?? '',
            };
        })
    }

    function blFields(){ 
        return [
            { key: 'options', label: '', _style: 'min-width:90px;', sorter: false, filter: false },
            { key: 'Nro', label: '#',_style: 'min-width: 45px; text-align:center;', filter: false, _classes: 'text-uppercase text-center'},
            { key: 'YardName',label: this.$t('label.ubication'), _style:'min-width: 220px;', _classes: 'text-uppercase text-center'},
            { key: 'packagings',label: this.$t('label.packagings'), _style:'min-width: 250px;', _classes: 'text-uppercase text-center'},
            { key: 'Quantity', label: this.$t('label.quantity'), _classes: 'text-uppercase text-center', _style: 'min-width: 160px;', sorter: true, filter: true},
            { key: 'ComputedWeight', label: `${this.$t('label.weight')} (KGM)`, _classes: 'text-uppercase text-center', _style:'min-width: 180px;',sorter: true, filter: true},
            { key: 'ComputedVolume', label: `${this.$t('label.volume')} (M³)`, _classes:"center-cell text-center ", _style:'min-width: 180px;', sorter: true, filter: true},
            { key: 'DocumentCode',label: this.$t('label.OrderOfEntry'), _style:'min-width: 190px;', _classes: 'text-uppercase text-center'},
            { key: 'serials',label: this.$t('label.serial'), _style:'min-width: 200px;', _classes: 'text-uppercase text-center'},
            { key: 'TpTransacEirAcronym',label: this.$t('label.transaction'), _style:'min-width: 140px; text-align:center;', _classes: 'text-uppercase text-center'},
            { key: 'MachineCode', label: this.$t('label.machine'), _style:'min-width: 160px;', _classes: 'text-uppercase text-center'},
            { key: 'TransactionDate', label: this.$t('label.receptionDate'), _style:'min-width: 155px;', _classes: 'text-uppercase text-center' },
            { key: 'CarrierName', label: this.$t('label.transport'), _style:'min-width: 180px;', _classes:"text-center"},
            { key: 'Vehicle', label: this.$t('label.vehicle'), _style:'min-width: 180px;', _classes:"text-center"},
            { key: 'Driver', label: this.$t('label.driver'), _style:'min-width: 180px;', _classes:"text-center"},
            { key: 'DeviceCod', label: this.$t('label.device'), _style:'min-width: 180px;', _classes:"text-center"},
            { key: 'TransaLogin', label: this.$t('label.user'), _style:'min-width: 140px;', _classes:"text-center"},
            { key: 'TransaRegDate', label: this.$t('label.registerDate'),  _style:'min-width: 150px; text-align:center;', _classes:"text-center" },
            { key: 'Status', label: this.$t('label.status'),  _style:'min-width: 130px; text-align:center;', _classes:"text-center" },
        ];
    }

    function Containerfields(){ 
        return [
            { key: 'options', label: '', _style: 'min-width:90px;', sorter: false, filter: false },
            { key: 'Nro', label: '#',_style: 'min-width: 45px; text-align:center;', filter: false, _classes: 'text-uppercase text-center'},
            { key: 'ContainerCode', label: this.$t('label.container'), _classes: 'text-center', _style: 'min-width: 125px;'},
            { key: 'YardName',label: this.$t('label.yard'), _style:'min-width: 220px;', _classes: 'text-uppercase text-center'},
            { key: 'TpTransacEirAcronym',label: this.$t('label.transaction'), _style:'min-width: 160px; text-align:center;', _classes: 'text-uppercase text-center'},
            { key: 'ComputedWeight', label: `${this.$t('label.weight')} (KGM)`, _classes: 'text-uppercase text-center', _style:'min-width: 180px;',sorter: true, filter: true},
            { key: 'ComputedVolume', label: `${this.$t('label.volume')} (M³)`, _classes:"center-cell text-center ", _style:'min-width: 180px;', sorter: true, filter: true},
            { key: 'DocumentCode',label: this.$t('label.OrderOfEntry'), _style:'min-width: 190px;', _classes: 'text-uppercase text-center'},
            { key: 'MachineCode', label: this.$t('label.machine'), _style:'min-width: 160px;', _classes: 'text-uppercase text-center'},
            { key: 'TransactionDate', label: this.$t('label.receptionDate'), _style:'min-width: 150px;', _classes: 'text-uppercase text-center' },
            { key: 'DeviceCod', label: this.$t('label.device'), _style:'min-width: 180px;', _classes:"text-center"},
            { key: 'TransaLogin', label: this.$t('label.user'), _style:'min-width: 140px;', _classes:"text-center"},
            { key: 'TransaRegDate', label: this.$t('label.registerDate'),  _style:'min-width: 150px; text-align:center;', _classes:"text-center" },
            { key: 'Status', label: this.$t('label.status'),  _style:'min-width: 130px; text-align:center;', _classes:"text-center" }
        ];
    }

    export default{
        name: 'reception',
        data,
        mixins: [General, YardManagement],
        props: {
            fgCertificate: Boolean,
        },
        components: {
            BlRecepcion,
            BlInternalRecepcion
        },
        methods: {
            toggleAdd,
            getBlReception,
            togglePdf,
            Update,
            NumberFormat,
            getBadge,
            getYardBlCargo,
            handleTab,
            onBtnExport,
            PersonalfilterCell,
            GroupTitle,
            excelHeader
        },
        computed: {
            blFields,
            Containerfields,
            computedListReceptionBl,
            computedContainerListContainer,
            ...mapState({
                globalSelectBl: state => state.yardManagement.globalSelectBl,
                CompanyBranchId: state => state.auth.branch.CompanyBranchId,
                YardId: state => state.yardManagement.yardData.YardId,
                FgPositioning: state => state.yardManagement.FgPositioning,
                dropItemMovementList: state => state.yardManagement.dropItemMovementList,
                dropBlMovement: state => state.yardManagement.dropBlMovement,
                dataBl: state => state.yardManagement.dataBl,
                user: state => state.auth.user,
            })
        },
        watch:{
            dropBlMovement: function (val) {
                if (val==1) {
                    this.showIndex = 0;
                    this.dataBls = [];
                    this.getBlReception(false);
                }
            },
        }
    }
</script>
<style lang="scss">
      .icon{
          background-color: null; 
          border: 0px;
      }
      .table-index {
          table {
              td {
              vertical-align: middle !important;
              }
          }
      }
</style>
<template>
  <div class="mb-3">
    <div>
      <CRow class="mb-3">
        <CCol sm="12" lg="12" xl="12" class="d-flex align-items-center justify-content-start">
          <h6 class="mt-2"><CIcon name='cil-list' size='lg' class="titulo-icono" /> {{ `${this.$t('label.ContainersInInventory')}`}} </h6>
        </CCol>
      </CRow>
      <CRow class="mt-2">
        <CCol sm="12">
          <dataTableExtended
            class="align-center-row-datatable"
            :items="computedListContainer"
            :fields="fields"
            :items-per-page="5"
            column-filter
            pagination
            :table-filter="tableText.tableFilterText"
            :items-per-page-select="tableText.itemsPerPageText"
            :noItemsView="tableText.noItemsViewText"
            sorter
          >
            <template #ReceptionDate="{ item }">
              <td class="text-center">
                {{ formatDateTimeWithSlash(item.ReceptionDate) }}
              </td>
            </template>
            <template #AtaEta="{ item }">
              <td class="text-center">
                {{ formatDateTimeWithSlash(item.Ata ?? item.Eta) }}
              </td>
            </template>
            <template #TransaRegDate="{ item }">
              <td class="text-center">
                {{ formatDateTimeWithSlash(item.TransaRegDate) }}
              </td>
            </template>
            <template #options="{ item }">
              <td class="text-center">
                <CButton
                  square
                  size="sm"
                  color="watch"
                  class="d-flex align-items-center"
                  @click="toggleContainer(item)"
                  v-c-tooltip="{
                    content: $t('label.ViewContainer'),
                    placement: 'top-start'
                  }"
                >
                  <CIcon name='cil-list' />
                </CButton>
              </td>
            </template>
          </dataTableExtended>
        </CCol>
      </CRow>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import General from '@/_mixins/general';
import { DateFormater } from "@/_helpers/funciones";

//data
function data() {
  return {
  };
}

//methods
function getYardWContainerCargoReception() {
  this.$store.state.yardManagement.loading = true;
  this.$http.get('YardWContainerCargoReception-by-YardId', { YardId: this.YardId })
    .then(response => {
      let List = response.data.data;
      this.$store.state.yardManagement.ListReceivedContainers = List&&List.length!=0 ? List : [];
    })
    .catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
    .finally(() => {
      this.$store.state.yardManagement.loading = false;
    });
}

async function getYardCargo(item) {
  this.$store.state.yardManagement.loading = true;
  await this.$http.get('YardCargo-by-Id', { YardCargoId: item.YardCargoId, YardId: item.YardId })
    .then(response => {
      let res = response.data.data;
      if (res && res?.length>0) {
        this.$store.state.yardManagement.ContainerTabIndex = -1;
        this.$store.state.yardManagement.dataContainer = res[0];
        this.$store.state.yardManagement.yardCollapse = 2;
        setTimeout(()=>{this.$store.state.yardManagement.ContainerTabIndex = 0;},100)
      }
    })
    .catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
      this.$store.state.yardManagement.loading = false;
    })
}

function toggleContainer(item) {
  this.getYardCargo(item);
}

function formatDateTimeWithSlash(date) {
  return date ? DateFormater.formatDateTimeWithSlash(date) : 'N/A'
}

//computed
function computedListContainer() {
  let _lang = this.$i18n.locale;
  return this.ListReceivedContainers.map((item, index) => {
    let BlNro = '';
    let TpBlName = '';
    let DocumentCode = '';

    item.BlJson.map((e, i)=>{
      BlNro = `${BlNro}${ (((i+1)<item.BlJson.length) && (i>0)) ? ',' : ''} ${e.BlNro??''}`
      TpBlName = `${TpBlName}${ (((i+1)<item.BlJson.length) && (i>0)) ? ',' : ''} ${e.TpBlName??''}`
      DocumentCode = `${DocumentCode}${ (((i+1)<item.BlJson.length) && (i>0)) ? ',' : ''} ${e.DocumentCode??''}`
    })

    return {
      ...item,
      Nro: index+1,
      VesselVoyage: item.VesselName ? `${item.VesselName} - ${item.VoyageArrival}` : '',
      DaysInYard: _lang=='en' ? item.DaysInYardEn||'' : item.DaysInYardEs||'',
      BlNro: BlNro,
      TpBlName: TpBlName,
      DocumentCode: DocumentCode,
      _classes: 'color-gradient',
      _style: 'background:'+item.TpTransacEirColor,
    };
  })
}

function fields(){ 
  return [
    { key: 'options', label: '', _style: 'min-width:45px;', sorter: false, filter: false },
    { key: 'Nro', label: '#', _classes: 'text-center', filter: false },
    { key: 'ContainerCode', label: this.$t('label.container'), _classes: 'text-center', _style: 'min-width: 125px;'},
    { key: 'BlNro', label: this.$t('label.bl'),  _style:'min-width:160px; text-align:center;', _classes:" text-center" },
    { key: 'TpBlName', label: this.$t('label.blType'),  _classes: 'text-uppercase text-center', _style: 'min-width:160px; text-align:center;' },
    { key: 'DocumentCode', label: this.$t('label.Acta'),  _classes: 'text-uppercase text-center', _style: 'min-width:200px;' },
    { key: 'VesselVoyage', label: `${this.$t('label.visit')} / ${this.$t('label.itinerary')}`, _classes: 'text-center', _style: 'min-width: 235px;'},
    { key: 'AtaEta', label: 'ATA / ETA', _classes: 'text-center', _style: 'min-width: 160px;'},
    { key: 'TpCargoDetailCode', label: this.$t('label.type'), _classes: 'text-center'},
    { key: 'TpCargoStatusName', label: this.$t('label.status'), _classes: 'text-center'},
    { key: 'TpTransacEirAcronym', label: this.$t('label.transaction'), _classes: 'text-center'},
    { key: 'TpCargoConditionCode', label: `${this.$t('label.condition')}`,  _classes: 'text-center'},
    { key: 'ShippingLineCode', label: this.$t('label.newShippingLine'), _classes: 'text-center', _style: 'min-width: 135px;'},
    { key: 'ReceptionDate', label: this.$t('label.ReceptionDate'), _classes: 'text-center', _style: 'min-width: 155px;'},
    { key: 'DaysInYard', label: this.$t('label.DaysInYard'), _classes: 'text-center', _style: 'min-width: 130px;'},
    { key: 'DeviceCod', label: this.$t('label.device'), _classes: 'text-center'},
    { key: 'TransaLogin', label: this.$t('label.ReceptionUser'), _classes: 'text-center', _style: 'min-width: 170px;'},
    { key: 'TransaRegDate', label: this.$t('label.date'), _classes: 'text-center', _style: 'min-width: 130px;'},
  ];
}
    
export default{
  name: 'container-in-inventory',
  data,
  mixins: [General],
  components: {},
  methods: {
    getYardWContainerCargoReception,
    getYardCargo,
    toggleContainer,
    formatDateTimeWithSlash,
  },
  computed: {
    fields,
    computedListContainer,
    ...mapState({
      YardId: state => state.yardManagement.yardData.YardId,
      dropItemMovementList: state => state.yardManagement.dropItemMovementList,
      ListReceivedContainers: state => state.yardManagement.ListReceivedContainers,
      ListReceivedContainers: state => state.yardManagement.ListReceivedContainers,
      FgContainerWarehouse: state => state.yardManagement.FgContainerWarehouse,
    })
  },
  watch:{
    dropItemMovementList: function (val) {
      if (val==4) {
        this.getYardWContainerCargoReception();
      }
    },
    FgContainerWarehouse: function (val) {
      if (val && this.dropItemMovementList == 4) {
        this.getYardWContainerCargoReception();
      }
      this.$store.state.yardManagement.FgContainerWarehouse = false;
    }

    
  }
}
</script>
<template>
  <CModalExtended
    :title="modalTitle"
    color="dark"
    size="xl"
    :close-on-backdrop="false"
    :show.sync="modalActive"
  >
    <CTabs v-if="CollapseAreaList" variant="tabs" :active-tab="tabIndex" @update:activeTab="handleTab">
      <CTab v-if="isCoverWareHouse && !ShowContainerListOnly" :title="$t('label.bl')">
        <CCol sm="12">
          <dataTableExtended
            class="align-center-row-datatable"
            :items="computedBlList"
            :fields="Blfields"
            :items-per-page="5"
            column-filter
            pagination
            :table-filter="tableText.tableFilterText"
            :items-per-page-select="tableText.itemsPerPageText"
            :noItemsView="tableText.noItemsViewText"
            sorter
          >
            <template #Ata="{ item }">
              <td class="text-center">
                {{ formatDateTimeWithSlash(item.Ata) }}
              </td>
            </template>
            <template #QuantityReceived="{ item }">
              <td class="text-center">
                {{ formatNumber(item.QuantityReceived, 2) }}
              </td>
            </template>
            <template #Weigth="{ item }">
              <td class="text-center">
                {{ formatNumber(item.Weigth, 2) }}
              </td>
            </template>
            <template #Volumen="{ item }">
              <td class="text-center">
                {{ formatNumber(item.Volumen, 2) }}
              </td>
            </template>
            <template #FirstEventDate="{ item }">
              <td class="text-center">
                {{ formatDateTimeWithSlash(item.FirstEventDate) }}
              </td>
            </template>
            <template #LastEventDate="{ item }">
              <td class="text-center">
                {{ formatDateTimeWithSlash(item.LastEventDate) }}
              </td>
            </template>
            <template #YardCargoBlStatus="{ item }">
              <td class="text-center">
                <b>{{ item.YardCargoBlStatus }}</b>
              </td>
            </template>
            <template #BlStatus="{ item }">
              <td class="text-center">
                <b>{{ item.BlStatus }}</b>
              </td>
            </template>
          </dataTableExtended>
        </CCol>
      </CTab>
      <CTab :title="$t('label.Containers')">
        <CCol sm="12">
          <dataTableExtended
            class="align-center-row-datatable"
            :items="computedListContainer"
            :fields="fields"
            :items-per-page="5"
            column-filter
            pagination
            :table-filter="tableText.tableFilterText"
            :items-per-page-select="tableText.itemsPerPageText"
            :noItemsView="tableText.noItemsViewText"
            sorter
          >
            <template #options="{ item }">
              <td class="text-center">
                <CButton
                  square
                  size="sm"
                  color="watch"
                  class="d-flex align-items-center"
                  @click="ViewContainer(item)"
                  v-c-tooltip="{
                    content: $t('label.ViewContainer'),
                    placement: 'top-start'
                  }"
                >
                  <CIcon name='cil-list' />
                </CButton>
              </td>
            </template>
          </dataTableExtended>
        </CCol>
      </CTab>
    </CTabs>
    <CRow class="options" v-if="hasContainerData&&!CollapseAreaList">
      <CCol v-if="ShowList" sm="12" lg="12" xl="12" class="d-flex align-items-center justify-content-start">
        <CButton
          color="edit"
          class="mb-2"
          v-c-tooltip="{
            content: $t('label.return'),
            placement: 'top-start'
          }"
          size="sm"
          @click="(CollapseAreaList = true, ContainerInfo={})"
        >
          <CIcon name="cil-chevron-left-alt" />
        </CButton>
      </CCol>
      <CCol sm="12" lg="3">
        <table class="ContainerInfo-table bigger-left-column">
          <thead>
            <tr>
              <th colspan="2">
                <div class="d-flex align-items-center"> 
                    <CIcon name="cil-info"/>
                    <span class="ml-2">{{$t('label.container')}}</span>
                </div>
                </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{$t('label.containerCode')}}:</td>
              <td>{{ validateValue(ContainerInfo.CargoJson[0].ContainerCode) }}</td>
            </tr>
            <tr>
              <td>{{$t('label.size')}}:</td>
              <td>{{ validateValue(ContainerInfo.CargoJson[0].Size)+' '+validateValue(ContainerInfo.CargoJson[0].UnitMeasureSizeAbbrev, true) }}</td>
            </tr>
            <tr>
              <td>{{$t('label.class')}}:</td>
              <td>{{ validateValue(ContainerInfo.CargoJson[0].ClassInfo) }}</td>
            </tr>
            <tr>
              <td>{{$t('label.CargoStatus')}}:</td>
              <td>{{ validateValue(ContainerInfo.CargoJson[0].TpCargoStatusName) }}</td>
            </tr>
            <tr>
              <td>{{$t('label.loadPort')}}:</td>
              <td>{{ validateValue(ContainerInfo.CargoJson[0].LoadPortCode) }}</td>
            </tr>
            <tr>
              <td>{{$t('label.dischargePort')}}:</td>
              <td>{{ validateValue(ContainerInfo.CargoJson[0].DischargePortCode) }}</td>
            </tr>
            <tr>
              <td>{{$t('label.newShippingLine')}}:</td>
              <td>{{ validateValue(ContainerInfo.CargoJson[0].ShippingLineName) }}</td>
            </tr>
            <tr>
              <td>{{$t('label.commodity')}}:</td>
              <td>{{ validateValue(ContainerInfo.CargoJson[0].Commodity) }}</td>
            </tr>
            <tr>
              <td>{{$t('label.PlaceOfDelivery')}}:</td>
              <td>{{ validateValue(ContainerInfo.CargoJson[0].DeliveryPortCode) }}</td>
            </tr>
            <tr>
              <td>{{$t('label.CargoOriginPort')}}:</td>
              <td>{{ validateValue(ContainerInfo.CargoJson[0].OriginalPortLoading) }}</td>
            </tr>
            <tr>
              <td>{{`${$t('label.Seal')} 1:`}}</td>
              <td>{{ validateValue(ContainerInfo.CargoJson[0].Seal1) }}</td>
            </tr>
            <tr>
              <td>{{`${$t('label.Seal')} 2:`}}</td>
              <td>{{ validateValue(ContainerInfo.CargoJson[0].Seal2) }}</td>
            </tr>
            <tr>
              <td>{{`${$t('label.Seal')} 3:`}}</td>
              <td>{{ validateValue(ContainerInfo.CargoJson[0].Seal3) }}</td>
            </tr>
            <tr>
              <td>{{`${$t('label.Seal')} 4:`}}</td>
              <td>{{ validateValue(ContainerInfo.CargoJson[0].Seal4) }}</td>
            </tr>
            <tr>
              <td>{{`SHIPPER OWNED`}}</td>
              <td>{{ validateValue(ContainerInfo.CargoJson[0].ShipperOwn) }}</td>
            </tr>
          </tbody>
        </table>
      </CCol>
      <CCol sm="12" lg="9">
        <CRow>
          <CCol sm="12" lg="4">
            <table class="ContainerInfo-table bigger-left-column">
              <thead>
                <tr>
                  <th colspan="2">
                    <div class="d-flex align-items-center"> 
                      <CIcon name="cil-border-all"/>
                      <span class="ml-2">{{$t('label.IsoInfo')}}</span>
                    </div>
                   </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{$t('label.TypeOfContainer')}}:</td>
                  <td>{{ validateValue(ContainerInfo.CargoJson[0].IsoCode) }}</td>
                </tr>
                <tr>
                  <td>{{$t('label.length')}}:</td>
                  <td>{{ validateValue(ContainerInfo.CargoJson[0].Lenght)+' '+validateValue(ContainerInfo.CargoJson[0].UnitMeasureLenghtAbbrev, true) }}</td>
                </tr>
                <tr>
                  <td>{{$t('label.height')}}:</td>
                  <td>{{ validateValue(ContainerInfo.CargoJson[0].Height)+' '+validateValue(ContainerInfo.CargoJson[0].UnitMeasureHeigth, true) }}</td>
                </tr>
                <tr>
                  <td>{{$t('label.GroupType')}}:</td>
                  <td>{{ validateValue(ContainerInfo.CargoJson[0].TpCargoName) }}</td>
                </tr>
                <tr>
                  <td>{{$t('label.SizeFt')}}:</td>
                  <td>{{ validateValue(ContainerInfo.CargoJson[0].TpCargoDetailCode) }}</td>
                </tr>
                <tr>
                  <td>{{$t('label.description')}}:</td>
                  <td>{{ validateValue(ContainerInfo.CargoJson[0].TpCargoDetailName) }}</td>
                </tr>
              </tbody>
            </table>
          </CCol>
          <CCol sm="12" lg="4">
             <table class="ContainerInfo-table bigger-left-column">
              <thead>
                <tr>
                  <th colspan="2">
                    <div class="d-flex align-items-center"> 
                      <CIcon name="cil-warning"/>
                      <span class="ml-2">{{$t('label.DangerousInfo')}}</span>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{$t('label.imdgCode')}}:</td>
                  <td>{{ validateValue(ContainerInfo.CargoJson[0].Class) }}</td>
                </tr>
                <tr>
                  <td>{{$t('label.UnNumber')}}:</td>
                  <td>{{ validateValue(ContainerInfo.CargoJson[0].UnNumber) }}</td>
                </tr>
                <tr>
                  <td>{{$t('label.IMDGPageNO')}}:</td>
                  <td>{{ validateValue(ContainerInfo.CargoJson[0].ImgdPageNo) }}</td>
                </tr>
                <tr>
                  <td>{{$t('label.FlashPoint')}}:</td>
                  <td>{{ validateValue(ContainerInfo.CargoJson[0].FlashPoint)+' '+validateValue(ContainerInfo.CargoJson[0].UnitMeasureFlashPointAbbrev, true) }}</td>
                </tr>
                <tr>
                  <td>{{$t('label.PackagingGroup')}}:</td>
                  <td>{{ validateValue(ContainerInfo.CargoJson[0].PackagingGroupCode) }}</td>
                </tr>
                <tr>
                  <td>{{$t('label.DescriptionOfGoods')}}:</td>
                  <td>{{ validateValue(ContainerInfo.CargoJson[0].DescriptionOfGoodsDGS) }}</td>
                </tr>
              </tbody>
            </table>
          </CCol>
          <CCol sm="12" lg="4">
             <table class="ContainerInfo-table info-media">
              <thead>
                <tr>
                  <th>
                    <div class="d-flex align-items-center"> 
                      <CIcon name="cil-image"/>
                      <span class="ml-2">IMAGE</span>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <c-img-extended 
                      :src="getImgUrl" 
                      :error-options="{ text: 'N/A', width: 300, height: 150, fontSize: 40 }"
                      block
                      class="ContainerInfo-img"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </CCol>
        </CRow>
        <CRow class="mt-2">
          <CCol sm="12" lg="4">
            <table class="ContainerInfo-table bigger-left-column">
              <thead>
                <tr>
                  <th colspan="2">
                    <div class="d-flex align-items-center"> 
                      <CIcon name="cil-chart" class="rotate-icon"/>
                      <span class="ml-2">{{$t('label.OversizeInfo')}}</span>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{$t('label.DimFront')}}:</td>
                  <td>{{ validateTwoValues(ContainerInfo.CargoJson[0].DimFront, ContainerInfo.CargoJson[0].UnitMeasureDimAbbrev) }}</td>
                </tr>
                <tr>
                  <td>{{$t('label.DimBack')}}:</td>
                  <td>{{ validateTwoValues(ContainerInfo.CargoJson[0].DimBack, ContainerInfo.CargoJson[0].UnitMeasureDimAbbrev) }}</td>
                </tr>
                <tr>
                  <td>{{$t('label.DimLeft')}}:</td>
                  <td>{{ validateTwoValues(ContainerInfo.CargoJson[0].DimLeft, ContainerInfo.CargoJson[0].UnitMeasureDimAbbrev) }}</td>
                </tr>
                <tr>
                  <td>{{$t('label.DimRight')}}:</td>
                  <td>{{ validateTwoValues(ContainerInfo.CargoJson[0].DimRight, ContainerInfo.CargoJson[0].UnitMeasureDimAbbrev) }}</td>
                </tr>
                <tr>
                  <td>{{$t('label.DimTop')}}:</td>
                  <td>{{ validateTwoValues(ContainerInfo.CargoJson[0].DimTop, ContainerInfo.CargoJson[0].UnitMeasureDimAbbrev) }}</td>
                </tr>
              </tbody>
            </table>
          </CCol>
          <CCol sm="12" lg="4">
             <table class="ContainerInfo-table bigger-left-column">
              <thead>
                <tr>
                  <th colspan="2">
                    <div class="d-flex align-items-center"> 
                      <CIcon name="cil-gauge"/>
                      <span class="ml-2">{{$t('label.ReeferInfo')}}</span>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{$t('label.measureUnit')}}:</td>
                  <td>{{ validateValue(ContainerInfo.CargoJson[0].UnitMeasureRfAbbrev) }}</td>
                </tr>
                <tr>
                  <td>{{$t('label.ReeferSetting')}}:</td>
                  <td>{{ validateValue(ContainerInfo.CargoJson[0].ReeferSetting) }}</td>
                </tr>
                <tr>
                  <td>{{$t('label.MinimumRange')}}:</td>
                  <td>{{ validateValue(ContainerInfo.CargoJson[0].MinimumRangeRf) }}</td>
                </tr>
                <tr>
                  <td>{{$t('label.MaximumRange')}}:</td>
                  <td>{{ validateValue(ContainerInfo.CargoJson[0].MaximumRangeRf) }}</td>
                </tr>
              </tbody>
            </table>
          </CCol>
          <CCol sm="12" lg="4">
             <table class="ContainerInfo-table info-media">
              <thead>
                <tr>
                  <th>
                    <div class="d-flex align-items-center"> 
                      <CIcon name="cil-object-group"/>
                      <span class="ml-2">{{$t('label.icon')}}</span>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div class="item">
                      <div :class="slugName(ContainerInfo.CargoJson[0].TpCargoName)"></div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </CCol>
        </CRow>
      </CCol>
      <CCol sm="12" class="mt-2">
        <table class="extra-table">
          <thead>
            <tr>
              <th>
                <div class="d-flex align-items-center"> 
                    <CIcon name="cil-info"/>
                    <span class="ml-2">{{$t('label.yardContainerGeneralInfo')}}</span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="large-td">
                <table class="extra-table no-border">
                  <tbody>
                    <tr>
                      <td>{{$t('label.visit')}}:</td>
                      <td>{{ ContainerInfo.CargoJson[0].VesselName ? `${validateValue(ContainerInfo.CargoJson[0].VesselName)}, ${validateValue(ContainerInfo.CargoJson[0].Voyage)}`:'N/A' }}</td>
                      <td>{{$t('label.yard')}}:</td>
                      <td>{{ validateValue(ContainerInfo.YardCode) }}</td>
                    </tr>
                    <tr>
                      <td>ATA:</td>
                      <td>{{ ContainerInfo.CargoJson && ContainerInfo.CargoJson.length != 0 ? validateDateValue(ContainerInfo.CargoJson[0].Ata) : 'N/A'}}</td>
                      <td>{{$t('label.module')}}:</td>
                      <td>{{ !ShowList ? formatedPosition(ContainerInfo.CargoJson[0]) : validateValue(ContainerInfo.YardAreaName) }}</td>
                    </tr>
                    <tr>
                      <td>{{$t('label.portActivity')}}:</td>
                      <td>{{ currentLocale == 'en' ? validateValue(ContainerInfo.CargoJson[0].PortActivityNameEn) : validateValue(ContainerInfo.CargoJson[0].PortActivityNameEs) }}</td>
                      <td>{{$t('label.daysInYard')}}:</td>
                      <td>{{ currentLocale == 'en' ? validateValue(ContainerInfo.CargoJson[0].DaysInYardEn) : validateValue(ContainerInfo.CargoJson[0].DaysInYardEs) }}</td>
                    </tr>
                    <tr>
                      <td>EIR:</td>
                      <td>{{ validateValue(ContainerInfo.CargoJson[0].EirNumber) }}</td>
                      <td>{{$t('label.receptionDate')}}:</td>
                      <td>{{ ContainerInfo.CargoJson && ContainerInfo.CargoJson.length != 0 ? validateDateValue(ContainerInfo.CargoJson[0].ReceptionDate) : 'N/A' }}</td>
                    </tr>
                    <tr>
                      <td>{{$t('label.condition')}}:</td>
                      <td>{{ validateValue(ContainerInfo.CargoJson[0].TpCargoConditionCode) }}</td>
                      <td>{{$t('label.LastMovement')}}:</td>
                      <td>{{ ContainerInfo.CargoJson && ContainerInfo.CargoJson.length != 0 && ContainerInfo.CargoJson[0].LastMovementJson && ContainerInfo.CargoJson[0].LastMovementJson.length!=0
                        ? (`${ currentLocale == 'en' ? ContainerInfo.CargoJson[0].LastMovementJson[0].EventNameEn||'' : ContainerInfo.CargoJson[0].LastMovementJson[0].EventNameEs||''} 
                        - ${validateDateValue(ContainerInfo.CargoJson[0].LastMovementJson[0].TransactionDate)}`) : 'N/A' }}</td>
                    </tr>
                  </tbody>
                </table>
                <div class="bls-ContainerInfo">
                  <div
                    class="bl-ContainerInfo"
                    v-for="item in ContainerInfo.CargoJson[0].BlJson"
                    :key="item.BlCargoId"
                  >
                    <div
                      class="collapse-areas"
                      @click="setCollapse(item.BlNro)"
                    >
                      <span>BL:</span>
                      <span class="bl-nro">{{ validateValue(item.BlNro) }}</span>
                      <CIcon
                        name="cil-chevron-bottom-alt"
                        class="arrow-icon"
                        :class="{
                          'rotate': collapseBlocks != '' ? true : null
                        }"
                      />
                    </div>
                    <div>
                      <CCollapse :show="collapseBlocks == item.BlNro">
                        <div class="bls-content">
                          <table class="bl-content-table">
                            <tbody>
                              <tr>
                                <td>{{$t('label.customBroker')}}:</td>
                                <td>{{ validateValue(item.CustomBrokerName) }}</td>
                              </tr>
                              <tr>
                                <td>{{'NRO ACTA RECEPCION BOLIPUERTOS'}}:</td>
                                <td>{{ validateValue(item.ReceptionBpAct) }}</td>
                              </tr>
                              <tr>
                                <td>{{$t('label.DescriptionOfGoods')}}:</td>
                                <td>{{ validateDescriptionOfGoods(item.DescriptionOfGoods) }}</td>
                              </tr>
                              <tr>
                                <td>{{$t('label.consignee')}}:</td>
                                <td>{{ validateValue(item.ConsigneeName) }}</td>
                              </tr>
                              <tr>
                                <td>{{'DUA'}}:</td>
                                <td>{{ validateValue(item.Dua) }}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </CCollapse>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </CCol>
    </CRow>         
    <template #footer>
      <CButton
        shape="square"
        color="wipe"
        class="d-flex align-items-center"
        @click.stop="toggle(false)"
      >
        <CIcon name="x"/>
        <span class="ml-1">{{ $t('button.exit') }}</span>
      </CButton>
    </template>
  </CModalExtended>
  </template>
  
  <script>
  import CImgExtended from '@/components/CImgExtended';
  import General from '@/_mixins/general';
  import { slugify, DateFormater, NumberFormater, trimRemoveSpecialCharacters } from '@/_helpers/funciones';
  
  //Data
  function data() {
    return {
      tabIndex: 0,
      CollapseAreaList: false,
      modalActive: this.modal,
      collapseBlocks: false,
      ContainerInfo: null,
    }
  }
  
  //Methods
  function formatedPosition(item) {
      let position = '';

      item?.PositionJson?.map((json)=>{
          position = `${position} ${json.SlotCode}`
      })
      
      if( item?.PositionJson?.length>0)
          return  position
      else
          return  'N/A'
  }

  function ViewContainer(item) {
    this.ContainerInfo = {...this.ContainerList};
    this.ContainerInfo.CargoJson = [item];
    this.CollapseAreaList = false;
    
  }
  function toggle(newVal) {
    this.modalActive = newVal;
  }
  function validateValue(value, empty = false) {
    let showValue = empty ? '' : 'N/A';
  
    return value || showValue;
  }
  function validateDescriptionOfGoods(value, empty = false) {
    let showValue = empty ? '' : 'N/A';
  
    return trimRemoveSpecialCharacters(value) || showValue;
  }
  function validateTwoValues(one, two) {
    if(one == null || two == null)
      return 'N/A';
  
    return `${one} ${two}`
  }
  function slugName(name) {
    if(name)
      return slugify(name);
    else
      return null;
  }
  function validateDateValue(value) {
    let showValue = value ? DateFormater.formatDateTimeWithSlash(value) : 'N/A';
  
    return showValue;
  }
  function setCollapse(value){
    if(this.collapseBlocks == value)
      this.collapseBlocks = '';
    else
      this.collapseBlocks = value;
  }

  function handleTab(tab) {
    this.tabIndex = tab;
  }

  function formatDateTimeWithSlash(date) {
  return date ? DateFormater.formatDateTimeWithSlash(date) : 'N/A';
}

function formatNumber(number, decimal) {
  return  NumberFormater.formatNumber(number, decimal);
}
  
  //Computed
  function hasContainerData() {
    return this.ContainerInfo != null;
  }
  function modalTitle() {
    if (this.ShowList) {
      if (this.CollapseAreaList) {
        if(this.NotPositioned)
          if (this.isCoverWareHouse) {
            return `${this.$t('label.ListNotPositioned')}`;
          }else {
            return `${this.$t('label.listContainersNotPositioned')}`;
          }
        else if (this.FgInvalidPosition) {
          return `${this.$t('label.ListInvalidPositionContainer')}`;
        }else
          return `${this.ContainerList.TpYardAreaId === process.env.VUE_APP_TP_YARD_AREA_AUXILIAR_AREA ? this.$t('label.auxiliaryZone') : this.$t('label.RecognitionZone')}: ${this.ContainerList.YardAreaName||''}`;
      } else {
        return `${this.$t('label.container')}: ${this.ContainerInfo?.CargoJson[0]?.ContainerCode||''}`;
      }
    }else{
      let cod = this.ContainerInfo ? this.formatedPosition(this.ContainerInfo.CargoJson[0]) : '';
      return `YARD SLOT: ${cod}`;
    }
  }
  function getImgUrl() {
    if(!this.ContainerInfo)
      return `${this.$store.getters["connection/getBase"]}''}`;
  
    let ruta = '';
    if(this.ContainerInfo.CargoJson.length > 0){
      ruta = this.ContainerInfo.CargoJson[0].GpoCargoRoute
      ? this.ContainerInfo.CargoJson[0].GpoCargoRoute.replace('Public/', '', null, 'i')
      : '';
    }
  
    return `${this.$store.getters["connection/getBase"]}${ruta}`;
  }
  function currentLocale() {
    return  this.$i18n.locale;
  }

  function fields(){ 
    return [
      { key: 'options', label: '', _style: 'min-width:45px;', sorter: false, filter: false },
      { key: 'Nro', label: '#', _classes: 'text-center', filter: false },
      { key: 'ContainerCode', label: this.$t('label.container'), _classes: 'text-center', _style: 'min-width: 125px;'},
      { key: 'VesselVoyage', label: this.$t('label.visit'), _classes: 'text-center', _style: 'min-width: 235px;'},
      { key: 'ComputedAta', label: 'ATA', _classes: 'text-center', _style: 'min-width: 120px;'},
      { key: 'TpCargoDetailCode', label: this.$t('label.type'), _classes: 'text-center'},
      { key: 'TpCargoStatusName', label: this.$t('label.status'), _classes: 'text-center'},
      { key: 'TpCargoConditionCode', label: `${this.$t('label.condition')}`,  _classes: 'text-center'},
      { key: 'ShippingLineCode', label: this.$t('label.newShippingLine'), _classes: 'text-center', _style: 'min-width: 135px;'},
      { key: 'ComputedReception', label: this.$t('label.ReceptionDate'), _classes: 'text-center', _style: 'min-width: 155px;'},
      { key: 'DaysInYard', label: this.$t('label.DaysInYard'), _classes: 'text-center', _style: 'min-width: 150px;'},
      { key: 'DeviceCod', label: this.$t('label.device'), _classes: 'text-center'},
      { key: 'TransaLogin', label: this.$t('label.ReceptionUser'), _classes: 'text-center', _style: 'min-width: 170px;'},
      { key: 'CumputedDate', label: this.$t('label.date'), _classes: 'text-center', _style: 'min-width: 130px;'},
    ];
  }

  function computedListContainer() {
    if (this.ContainerList?.CargoJson) {
      return this.ContainerList.CargoJson.map((item, index) => {
        return {
          ...item,
          Nro: index+1,
          VesselVoyage: `${item.VesselName} - ${item.Voyage}`,
          ComputedAta: item.Ata ? DateFormater.formatDateTimeWithSlash(item.Ata) : 'N/A',
          ComputedReception: item.ReceptionDate ? DateFormater.formatDateTimeWithSlash(item.ReceptionDate) : 'N/A',
          DaysInYard: this.$i18n.locale=='en' ? item.DaysInYardEn || '' : item.DaysInYardEs || '',
          CumputedDate: item.TransaRegDate ? DateFormater.formatDateTimeWithSlash(item.TransaRegDate) : 'N/A',
        };
      })
    }
  }

  function Blfields() {
    return [
      { key: 'Nro', label: '#', _style: 'width:50px', _classes: 'text-center', filter: false },
      { key: 'BlNro', label: this.$t('label.bl'),  _style:'min-width:180px;', _classes:" text-center" },
      { key: 'MasterBlNro', label: this.$t('label.Master'),  _style:'min-width:180px;', _classes:" text-center" },
      { key: 'TpBlName', label: this.$t('label.blType'),  _classes: 'text-uppercase text-center', _style: 'min-width:140px;' },
      { key: 'DocumentCode', label: this.$t('label.Acta'),  _classes: 'text-uppercase text-center', _style: 'min-width:200px;' },
      { key: 'ClientName', label: this.$t('label.client'), _style:'min-width:200px', _classes:'text-uppercase text-center center-cell' },
      { key: 'TpCargoName',label: this.$t('label.group'), _style:'min-width:230px;', _classes:'text-center' },
      { key: 'PortActivityName',label: this.$t('label.activity'), _style:'min-width:230px;', _classes:'text-center' },
      { key: 'VesselVoyage', label: this.$t('label.visit'),  _classes: 'text-uppercase text-center', _style: 'min-width:235px;' },
      { key: 'Ata', label: 'ATA',  _classes: 'text-uppercase text-center', _style: 'min-width:150px;' },
      { key: 'QuantityReceived', label: this.$t('label.ReceivedQuantity'),  _classes: 'text-uppercase text-center', _style: 'min-width:235px;' },
      { key: 'Weigth', label: `${this.$t('label.weight')} (KGM)`,  _classes: 'text-uppercase text-center', _style: 'min-width:180px;' },
      { key: 'Volumen', label: `${this.$t('label.volume')} (M³)`, _style:'min-width: 180px;', _classes: 'text-center' },
      { key: 'DaysInYard', label: `${this.$t('label.daysInYard')}`,  _classes: 'text-uppercase text-center', _style: 'min-width:160px;' },
      { key: 'FirstEventName', label: this.$t('label.firstEvent'),  _classes: 'text-uppercase text-center', _style: 'min-width:200px;' },
      { key: 'FirstEventDate', label: this.$t('label.dateFierstEvent'),  _classes: 'text-uppercase text-center', _style: 'min-width:200px;' },
      { key: 'LastEventName', label: this.$t('label.LastEvent'),  _classes: 'text-uppercase text-center', _style: 'min-width:200px;' },
      { key: 'LastEventDate', label: this.$t('label.DateLastEvent'),  _classes: 'text-uppercase text-center', _style: 'min-width:200px;' },
      { key: 'YardCargoBlStatus', label: this.$t('label.statusYardWarehouse'),  _classes: 'text-uppercase text-center', _style: 'min-width:260px;' },  
      { key: 'BlStatus', label: this.$t('label.statusCertificate'),  _classes: 'text-uppercase text-center', _style: 'min-width:260px;' },  
    ];
  }

  function computedBlList() {
    let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
    return this.BlNotPositionJson?.map((item, index) => {
      return {
        ...item,
        Nro: index+1,
        TpCargoName: item[`TpCargoName${_lang}`] ?? 'N/A',
        PortActivityName: item[`PortActivityName${_lang}`] ?? 'N/A',
        VesselVoyage: `${item.VesselName} - ${item.Voyage}`,
        FirstEventName: item[`FirstEventName${_lang}`] ?? 'N/A',
        LastEventName: item[`LastEventName${_lang}`] ?? 'N/A',
        YardCargoBlStatus: item[`YardCargoBlStatus${_lang}`] ?? 'N/A',
        BlStatus: item[`BlStatus${_lang}`] ?? 'N/A',
        TpBlName: item.TpBlName ?? '',
        DocumentCode: item.DocumentCode ?? '',
        ClientName: item.ClientName ?? '',
        DaysInYard: item.DaysInYard ?? '',
        MasterBlNro: item.MasterBlNro ?? '',
        _classes: 'color-gradient',
        _style: `background:${item.YardCargoBlStatusColor}`
      };
    })
  }
  
  export default {
    name: 'yard-slot-modal',
    mixins: [General],
    components: { CImgExtended },
    props: {
      modal: Boolean,
      container: {
        type: Object,
        default: null,
      },
      ContainerList: {
        type: Object,
        default(){
          return {};
        },
      },
      BlNotPositionJson: {
        type: Array,
        default: () => [],
      },
      NotPositioned: {
        type: Boolean,
        default: false,
      },
      ShowList: {
        type: Boolean,
        default: false,
      },
      FgInvalidPosition: {
        type: Boolean,
        default: false,
      },
      isCoverWareHouse: {
        type: Boolean,
        default: false,
      },
      ShowContainerListOnly: {
        type: Boolean,
        default: false,
      },
    },
    data,
    methods: {
      ViewContainer,
      toggle,
      validateValue,
      validateTwoValues,
      slugName,
      validateDateValue,
      setCollapse,
      validateDescriptionOfGoods,
      formatedPosition,
      handleTab,
      formatDateTimeWithSlash,
      formatNumber,
    },
    computed: {
      hasContainerData,
      modalTitle,
      getImgUrl,
      currentLocale,
      fields,
      computedListContainer,
      Blfields,
      computedBlList,
    },
    watch: {
      modal: function(val) {
        this.tabIndex = 0;
        if (val) {
          if (!this.NotPositioned&&this.ShowList&&this.$store.state.yardsetting.search) {
            let ContaineData = this.ContainerList.CargoJson.find(CargoJson => CargoJson.ContainerCode==this.$store.state.yardsetting.search);
            if (ContaineData) {
              this.CollapseAreaList = false;
              this.ContainerInfo = {...this.ContainerList};
              this.ContainerInfo.CargoJson = [ContaineData];
              
            }
          }else{
            this.CollapseAreaList = this.ShowList;
          }
        } else {
          this.CollapseAreaList = false;
          this.ContainerInfo = null;
          this.$emit('update:ShowContainerListOnly', false);
          this.$emit('update:ContainerList', {});
          this.$emit('update:container', null);
        }
        this.toggle(val);
      },
      modalActive: function(val) {
        this.$emit('update:modal', val);
      },
      container: function (val) {
        this.ContainerInfo = val;
      }
    }
  }
</script>
<style scoped>
  .options {
    min-height: 340px;
    overflow-y: auto;
    scrollbar-width: thin;
  }
  
  .rotate-icon {
    transform: rotate(-90deg);
  }
  
  /* Container Data Table */
  .ContainerInfo-table {
    width: 100%;
    height: 100%;
    min-height: 280px;
    border: 1px solid #9e9e9e;
    border-collapse: collapse;
  }
  .ContainerInfo-table thead{
    color: #434343;
    border-bottom: 1px solid #9e9e9e;
  } 
  .ContainerInfo-table th,
  .ContainerInfo-table td{
    width: calc(100% / 2);
    padding: 5px 10px;
  }
  .ContainerInfo-table th {
    text-align: left;
  }
  .ContainerInfo-table tr td{  
    background-color: #f5f5f5;
    font-size: small;
  }
  
  .bigger-left-column td:nth-child(1){
    width: 60%;
  }
  .bigger-left-column td:nth-child(2){
    width: 40%;
  }
  
  /* Extra Table */
  .extra-table {
    width: 100%;
    height: 100%;
    /* min-height: 280px; */
    border: 1px solid #9e9e9e;
    border-collapse: collapse;
  }
  .extra-table thead{
    color: #434343;
    border-bottom: 1px solid #9e9e9e;
  } 
  .extra-table th,
  .extra-table td{
    width: calc(100% / 4);
    padding: 5px 10px;
  }
  .extra-table th {
    text-align: left;
  }
  .extra-table tr td{  
    background-color: #f5f5f5;
    font-size: small;
  }

  /* .extra-table tr td:nth-child(1),
  .extra-table tr td:nth-child(2),
  .extra-table tr td:nth-child(3),
  .extra-table tr td:nth-child(4){
    width: 15%;
  }
  .extra-table tr td:nth-child(5){
    width: 100%;
  } */

  .large-td {
    width: 100% !important;
    padding: 0 !important;
    display: flex;
  }

  .no-border {
    border: none;
  }

  /* Imagen e Icono */
  .ContainerInfo-table.info-media .ContainerInfo-img {
    width: 60%;
    margin: 5px auto;
  }
  .ContainerInfo-table.info-media .item {
    width: 140px;
    height: 140px;
    border: 1px solid #000;
    position: relative;
    background-color: #fff;
    margin: 10px auto;
  }

  /* BL Info */
  .bls-ContainerInfo {
    display: flex;
    min-height: 105px;
    width: 40%;
    flex-direction: column;
    padding: 5px 0;
  }
  .bl-ContainerInfo {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
  .collapse-areas {
    cursor: pointer;
    background-color: #fafafa;
    padding: 4px 10px;
    border: 1px solid #616161;
    border-radius: 3px;
    display: flex;
    align-items: center;
  }
  .collapse-areas .bl-nro {
    margin-left: auto;
  }
  .collapse-areas .arrow-icon {
    /* margin-left: auto; */
    transition: transform 0.15s ease-in;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  .collapse-areas .arrow-icon.rotate {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  .bls-content {
    background-color: #ffffff;
    border: 1px solid #616161;
    border-radius: 3px;
  }
  .bl-content-table {
    width: 100%;
    height: 100%;
    /* min-height: 280px; */
    /* border: 1px solid #9e9e9e; */
    border-collapse: collapse;
  }
  .bl-content-table th,
  .bl-content-table td{
    width: calc(100% / 2);
    padding: 5px 10px;
  }
  .bl-content-table th {
    text-align: left;
  }
  .bl-content-table tr td{  
    /* background-color: #f5f5f5; */
    background-color: #ffffff;
    font-size: small;
  }

  /* Iconos */
  .flat-rack,
  .ft-flat-rack {
    width: 35%;
    height: 35%;
    border: 1px solid #555;
    border-top: none;
    border-left: none;
    position: absolute;
    top: 0;
    left: 0;
  }
  .plataforma,
  .pl-plataforma {
    width: 35%;
    height: 35%;
    border: 1px solid #555;
    border-bottom: none;
    border-right: none;
    position: absolute;
    bottom: 0;
    right: 0;
  }
  .tanque,
  .tk-tanque {
    width: 25%;
    height: 25%;
    border: 1px solid #555;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    position: absolute;
    bottom: 0;
    margin: 0 0 2px 2px;
  }
  .open-top,
  .ot-open-top {
    position: absolute;
    width: 100%;
    height: 30%;
    border: 1px solid #555;
    border-top: none;
    border-right: none;
    border-left: none;
    top: 0;
  }
  .standard,
  .st-estandar,
  .estandar {
    background-image: url('/img/iconos/Standard.svg');
    position: absolute;
    width: 40%;
    height: 40%;
    top: 0;
    left: 0;
    margin-top: 2px;
    margin-left: 2px;
  }
  .high-cube {
    width: 40%;
    height: 40%;
    border: 1px solid #555;
    -moz-border-radius: 0 0 100% 0;
    -webkit-border-radius: 0 0 100% 0;
    border-radius: 0 0 100% 0;
    border-top: none;
    border-left: none;
    position: absolute;
    top: 0;
    left: 0;
  }
  .reefer-high-cube,
  .refrigerado-high-cube {
    background-image: url('/img/iconos/Reefer.svg'), url('/img/iconos/HighCube.svg');
    background-position: left top, right bottom;
    background-size: 40% 40%, 40% 40%;
    background-repeat: no-repeat;
    position: absolute;
    width: 100%;
    height:100%;
  }
  .reefer,
  .refrigerado {
    width: 40%;
    height: 40%;
    border: 1px solid #555;
    -moz-border-radius: 0 100% 0 0;
    -webkit-border-radius: 0 100% 0 0;
    border-radius: 100% 0 0 0;
    border-bottom: none;
    border-right: none;
    position: absolute;
    right: 0;
    bottom: 0;
  }
  </style>
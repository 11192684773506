<template>
  <div>
    <div v-show="showIndex==0">
      <CRow class="mb-3">
        <CCol sm="12" lg="12" class="d-flex align-items-center justify-content-start">
          <h6 class="mt-2"><CIcon name='cil-list' size='lg' class="titulo-icono" /> {{ `${this.$t('label.deconsolidatedList')}`}} </h6>
        </CCol>
      </CRow>
      <CTabs variant="tabs" :active-tab="tabIndex" @update:activeTab="handleTab">
        <CTab :title="$t('label.packaging')" class="pt-1">
          <CRow class="mb-3 mt-1">
            <CCol sm="12" lg="12" class="d-flex align-items-center justify-content-end" v-if="fgDesconsolidate  && YardId">
              <CRow>
                <CCol sm="12" xl="12" class="align-items-center">
                  <CButton
                    shape="square"
                    color="add"
                    class="d-flex align-items-center ml-auto"
                    v-c-tooltip="{
                      content: $t('label.deconsolidated'),
                      placement: 'top-end'
                    }"
                    @click="toggleAdd({})"
                  >
                    <CIcon name="cil-playlist-add"/><span class="ml-1">{{$t('label.nueva')}}</span>
                  </CButton>
                </CCol>
              </CRow>
            </CCol> 
          </CRow>
          <CRow>
            <CCol col="12" xl="12">
              <dataTableExtended
                class="align-center-row-datatable"
                hover
                sorter
                small
                details
                column-filter
                :items="formatedItems"
                :fields="fields"
                :noItemsView="tableText.noItemsViewText"
                :items-per-page="tableText.itemsPerPage"
                :table-filter="tableText.tableFilterText"
                :items-per-page-select="tableText.itemsPerPageText"
                pagination
              >
                <template #number="{ index }">
                  <td class="text-center">
                    {{index+1}}
                  </td>
                </template>
                <template #Status="{item}">
                  <td class='text-center'>
                    <CBadge :color="getBadge(item.Status)">
                        {{ $t('label.'+item.Status) }}
                    </CBadge>
                  </td>
                </template>
                <template #buttonOptions="{item, index}">
                  <td class="text-center">
                    <CButton
                      color="edit"
                      square
                      size="sm"
                      class="mr-1"
                      v-c-tooltip="{
                        content: $t('label.editReception'),
                        placement: 'top-start'
                      }"
                      @click="toggleAdd(item, true)"
                    >
                      <CIcon name="pencil" />
                    </CButton>
                  </td>
                </template>
              </dataTableExtended>
            </CCol>
          </CRow>
        </CTab>
        <CTab :title="$t('label.Containers')" class="pt-1">
          <CRow class="mb-3 mt-1">
            <CCol sm="12" lg="12" class="d-flex align-items-center justify-content-end">
              <CRow>
                <CCol sm="12" xl="12" class="align-items-center" v-if="fgDesconsolidate && ContainerformatedItems.length == 0 && YardId && dataContainers.length>0">
                  <CButton
                    shape="square"
                    color="add"
                    class="d-flex align-items-center ml-auto"
                    v-c-tooltip="{
                      content: $t('label.deconsolidated'),
                      placement: 'top-end'
                    }"
                    @click="toggleContainerAdd({})"
                  >
                    <CIcon name="cil-playlist-add"/><span class="ml-1">{{$t('label.nueva')}}</span>
                  </CButton>
                </CCol>
              </CRow>
            </CCol> 
          </CRow>
          <CRow>
            <CCol col="12" xl="12">
                <dataTableExtended
                    class="align-center-row-datatable"
                    hover
                    sorter
                    small
                    details
                    column-filter
                    :items="ContainerformatedItems"
                    :fields="Containerfields"
                    :noItemsView="tableText.noItemsViewText"
                    :items-per-page="tableText.itemsPerPage"
                    :table-filter="tableText.tableFilterText"
                    :items-per-page-select="tableText.itemsPerPageText"
                    pagination
                >
                    <template #number="{ index }">
                        <td class="text-center">
                            {{index+1}}
                        </td>
                    </template>
                    <template #Status="{item}">
                      <td class='text-center'>
                          <CBadge :color="getBadge(item.Status)">
                              {{ $t('label.'+item.Status) }}
                          </CBadge>
                      </td>
                    </template>
                    <template #buttonOptions="{item, index}">
                        <td class="text-center">
                            <CButton
                              color="edit"
                              square
                              size="sm"
                              class="mr-1"
                              v-c-tooltip="{
                                content: $t('label.editReception'),
                                placement: 'top-start'
                              }"
                              v-if="YardId"
                              @click="toggleContainerAdd(item, true)"
                            >
                              <CIcon name="pencil" />
                            </CButton>
                            <CButton
                              style="background-color: #d6d6d7;"
                              square
                              size="sm"
                              v-c-tooltip="{
                                content: $t('label.Seals'),
                                placement: 'top-start'
                              }"
                              @click="viewDetail(item, index)"
                          >
                              <CIcon name="bottomSeals"/>
                            </CButton>
                        </td>
                    </template>
                    <template #details="{item}">
                      <CCollapse
                        :show="Boolean(item._toggled)"
                        :duration="collapseDuration"
                      >
                        <CCardBody>
                          <dataTableExtended
                            class="align-center-row-datatable table-lv-2"
                            small
                            :items="formatedSeal(item.SealJson[0])"
                            :fields="level2Fields"
                            :noItemsView="tableText.noItemsViewText"
                            :items-per-page="tableText.itemsPerPage"
                        >
                        
                            <template #button="{item}">
                                <td class="text-center">
                                    <CButton
                                    v-if="item.SealDocumentJson.length!=0&&item.Seal"
                                        color="watch"
                                        square
                                        size="sm"
                                        class="mr-1"
                                        @click="ModalImagen(item)"
                                    >
                                      <CIcon name="pencil" />
                                    </CButton>
                                </td>
                            </template>
                          </dataTableExtended>
                        </CCardBody>
                      </CCollapse>
                    </template>
                </dataTableExtended>
            </CCol>
          </CRow>
        </CTab>
      </CTabs>
    </div>
    <div v-show="showIndex==1">
      <DesconsolidatedRegister
        :Active="ActiveRegister && showIndex==1"
        :HouseData="HouseData"
        :isEdit="isEdit"
        :ContainerItem="ContainerItem"
        @Close="showIndex=0, ActiveRegister=false, isEdit=false, Update()"
        @Update-list="Update"
      />
    </div>
    <div v-show="showIndex==2">
      <ContainerDesconsolidatedRegister
        :Active="ActiveRegister && showIndex==2"
        :HouseData="HouseData"
        :isEdit="isEdit"
        :ContainerItem="ContainerItem"
        @Close="showIndex=0, ActiveRegister=false, isEdit=false, Update()"
        @Update-list="Update"
      />
    </div>
    <ModalSealImages
      :modal="ModalSeal"
      :labelModal="labelModalImages"
      :SealImages="SealImages"
      @Close="(ModalSeal=false, labelModalImages='', SealImages=[])"
    />
  </div>
</template>
<script>

import GeneralMixin from '@/_mixins/general';
import CustomTabs from '@/components/tabs/CustomTabs';
import CustomTab from '@/components/tabs/CustomTab';
import DesconsolidatedRegister from './desconsolidated-register';
import ContainerDesconsolidatedRegister from '@/pages/yard-management/container/events/desconsolidated/desconsolidated-register';
import ModalSealImages from '@/pages/yard-management/container/modal-seal-images';
import { DateFormater, NumberFormater } from '@/_helpers/funciones';
import { mapState } from 'vuex';

//DATA-------------------------
function data() {
  return {
    tabIndex: 0,
    ModalSeal: false,
    labelModalImages: '',
    SealImages: [],
    dataDesconsolited: [],
    PackagingJson: [],
    ContainerJson: [],
    collapseDuration: 0,
    dataContainers: [],
    _toggled: false,
    showIndex: 0,
    ActiveRegister: false,
    isEdit: false,
    ContainerItem:{},
    HouseData: {},
  }
}

async function getBlHouseData() {
  await this.$http.get('BlHouseData-by-BlCargoId', { BlCargoId: this.dataBl.BlCargoId })
    .then(response => {
      this.HouseData = response.data.data[0] ?? {}; 
    })
    .catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
}

async function getDesconsolitedList() {

  await this.$http.get('YardEventDeconsolidation-by-BlCargoId', { BlCargoId: this.dataBl.BlCargoId})
    .then(response => {
      let List = response.data.data?.[0];
      this.PackagingJson = List?.PackagingJson ?? [];
      this.ContainerJson = List?.ContainerJson ?? [];
    })
    .catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
}

async function getInventoryInYard() {
        await this.$http.get('YardCargoInventoryInYard-by-BlCargoId', { YardId: this.YardId, BlCargoId: this.dataBl.BlCargoId})
      .then((response) => {
          this.dataContainers =  response.data.data ?? [];
      })
      .catch( err => {
          this.$notify({
              group: 'container',
              title: '¡Error!',
              text: err,
              type: "error"
          });
      })
}

async function getYardCargo(item, Edit = false) {
  await this.$http.get('YardCargo-by-Id', { YardCargoId: item.YardCargoId, YardId: this.YardId })
    .then(response => {
      let List = response.data.data;
      if (List&&List.length!=0) {
        this.ContainerItem = item;
        this.isEdit = Edit;
        this.$store.state.yardManagement.dataContainer = List[0];
        this.ActiveRegister = true;
        this.showIndex = 2;
      }else{
        this.$store.state.yardManagement.dataContainer = {};
        this.$store.state.yardManagement.ListReceivedContainers = [];
        this.$store.state.yardManagement.ListDispatchedContainers = [];
        this.$store.state.yardManagement.ListRecognitionContainers = [];
      }
    })
    .catch( err => {
      this.$store.state.yardManagement.dataContainer = {};
      this.$store.state.yardManagement.ListReceivedContainers = [];
      this.$store.state.yardManagement.ListDispatchedContainers = [];
      this.$store.state.yardManagement.ListRecognitionContainers = [];
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
}

async function getYardBlCargo() {
    await this.$http.get('BlCargo-by-Id', {BlCargoId: this.dataBl.BlCargoId})
        .then(response => {
        let res = response.data.data;
        if (res && res?.length>0) {
            this.$store.state.yardManagement.dataBl = res[0];
        }
        })
        .catch( err => {
        this.$store.state.yardManagement.loading = false;
        this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
        });
        })
}

function toggleAdd(item, Edit = false) {
    this.ContainerItem = item;
    this.ActiveRegister = true;
    this.showIndex = 1;
    this.isEdit = Edit;
}

async function toggleContainerAdd(item, Edit = false) {
  await this.getYardCargo(item, Edit);
}

function ModalImagen(item) {
  this.labelModalImages = item.Seal;
  this.SealImages = item.SealDocumentJson;
  this.ModalSeal = true;
}

async function Update() {
  this.$store.state.yardManagement.loading = true;
  await this.getYardBlCargo();
  //await this.getBlHouseData();
  await this.getDesconsolitedList();
  this.$store.state.yardManagement.loading = false;
}

function viewDetail(item){
  item._toggled = !item._toggled;
  this.collapseDuration = 300;
  this.$nextTick(() => {
    this.collapseDuration = 0;
    this.loading = false;
    this.apiStateLoading = false;
  });
}

function NumberFormat(number) {
  return NumberFormater.setNum(number);
}

function handleTab(tab) {
  this.tabIndex = tab;
}

function getBadge(status) {
    switch (status) {
        case 'INACTIVO':
          return 'danger'
        case 'INACTIVE':
          return 'danger'
        default:
          return 'success'
    }
}

//computed
function VisitOptions(){
  if(this.ListVisit.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.ListVisit.map(function(e){
      chart.push({
        value: e.IncidenceGpoId, 
        label: e.IncidenceGpoNameEn,
      })    
    })
    return chart;
  }
}

function formatedItems() {
  let _lang = this.$i18n.locale;
  return this.PackagingJson.map((item, index) => {
    return {
      ...item,
      Nro: index+1,
      YardName: item.YardName? item.YardName??'' : this.yardData.YardName ?? '',
      TransactionDate: item.TransactionDate ? DateFormater.formatDateTimeWithSlash(item.TransactionDate) : '',
      TransaRegDate: item.TransaRegDate ? DateFormater.formatDateTimeWithSlash(item.TransaRegDate) : '',
      MachineCode: item.MachineCode ?? '',
      DeviceName: item.DeviceName ?? '',
      TransaLogin: item.TransaLogin ?? '',
      ComputedInitDate: item.TransactionBegin ? DateFormater.formatDateTimeWithSlash(item.TransactionBegin) : '',
      ComputedFinishDate: item.TransactionFinish ? DateFormater.formatDateTimeWithSlash(item.TransactionFinish) : '',
      EventReasonName: _lang=='en' ? item.EventReasonNameEn || '' : item.EventReasonNameEs || '',
      EventServiceName: _lang=='en' ? item.EventServiceNameEn || '' : item.EventServiceNameEs || ''
    };
  })
}

function fields(){
  return [
      {
        key: 'buttonOptions', 
        label: '', 
        _style: 'min-width: 45px; width: 1%; text-align:center; ', 
        sorter: false, 
        filter: false
      },
      { key: 'number', label: '#',_style: 'min-width: 45px; text-align:center;', filter: false, _classes: 'text-uppercase text-center'},
      { key: 'YardName',label: this.$t('label.yard'), _style:'min-width: 200px;', _classes: 'text-uppercase text-center'},
      { key: 'EventReasonName', label: this.$t('label.motive'), _style:'min-width: 180px;', _classes: 'text-uppercase text-center'},
      { key: 'EventServiceName', label: this.$t('label.service'), _style:'min-width: 180px;', _classes: 'text-uppercase text-center'},
      { key: 'MachineCode', label: this.$t('label.machine'), _style:'min-width: 160px;', _classes: 'text-uppercase text-center' },
      { key: 'ComputedInitDate',  label: this.$t('label.dateFrom'), _classes: 'text-uppercase text-center', _style:'min-width: 180px;'},
      { key: 'ComputedFinishDate', label: this.$t('label.dateTo'), _classes: 'text-uppercase text-center', _style:'min-width: 180px;'},
      { key: 'DeviceName', label: this.$t('label.device'), _style:'min-width: 160px;', _classes: 'text-uppercase text-center'},
      { key: 'TransaLogin', label: this.$t('label.user'), _style:'min-width: 180px;', _classes:"text-center"},
      { key: 'TransaRegDate', label: this.$t('label.registerDate'),  _style:'min-width: 160px; text-align:center;', _classes:"text-center" },
      { key: 'Status', label: this.$t('label.status'),  _style:'min-width: 130px; text-align:center;', _classes:"text-center" },
      
  ];
}

function formatedSeal(SealJson) {
      if (SealJson) {
          let _lang = this.$i18n.locale;
          let SealArray = SealJson?.Seal1Json?.concat(SealJson.Seal2Json, SealJson.Seal3Json, SealJson.Seal4Json) ?? [];
          return SealArray.map((item) => {
          return {
              ...item,
              Seal: item.Seal ? item.Seal : '',
              SealConditionName: _lang=='en' ? item.SealConditionNameEn || '' : item.SealConditionNameEs || '',
              SealB: item.SealB ? item.SealB : '',
              SealAssignmentName: _lang=='en' ? item.SealAssignmentNameEn || '' : item.SealAssignmentNameEs || '',
              ObservationSeal: item.ObservationSeal ? item.ObservationSeal : '',
          };
          })
      }
}


function ContainerformatedItems() {
    let _lang = this.$i18n.locale;
    return this.ContainerJson.map((item, index) => {
      return {
            ...item,
            Nro: index+1,
            YardName: item.YardName? item.YardName : '',
            TransactionDate: item.TransactionDate ? DateFormater.formatDateTimeWithSlash(item.TransactionDate) : '',
            TransaRegDate: item.TransaRegDate ? DateFormater.formatDateTimeWithSlash(item.TransaRegDate) : '',
            MachineCode: item.MachineCode ?? '',
            DeviceName: item.DeviceName ?? '',
            TransaLogin: item.TransaLogin ?? '',
            ComputedInitDate: item.TransactionBegin ? DateFormater.formatDateTimeWithSlash(item.TransactionBegin) : '',
            ComputedFinishDate: item.TransactionFinish ? DateFormater.formatDateTimeWithSlash(item.TransactionFinish) : '',
            EventReasonName: _lang=='en' ? item.EventReasonNameEn || '' : item.EventReasonNameEs || '',
            EventServiceName: _lang=='en' ? item.EventServiceNameEn || '' : item.EventServiceNameEs || ''
        };
    })
}

function Containerfields(){
  return [
      {
        key: 'buttonOptions', 
        label: '', 
        _style: 'min-width: 90px; width: 1%; text-align:center; ', 
        sorter: false, 
        filter: false
      },
      { key: 'number', label: '#',_style: 'min-width: 60px; text-align:center;', filter: false, _classes: 'text-uppercase text-center'},
      { key: 'Serial', label: this.$t('label.container'), _style:'min-width: 120px;', _classes: 'text-uppercase text-center' },
      { key: 'EventReasonName', label: this.$t('label.motive'), _style:'min-width: 180px;', _classes: 'text-uppercase text-center'},
      { key: 'EventServiceName', label: this.$t('label.service'), _style:'min-width: 180px;', _classes: 'text-uppercase text-center'},
      { key: 'MachineCode', label: this.$t('label.machine'), _style:'min-width: 120px;', _classes: 'text-uppercase text-center' },
      { key: 'ComputedInitDate',  label: this.$t('label.dateFrom'), _classes: 'text-uppercase text-center', _style:'min-width: 180px;'},
      { key: 'ComputedFinishDate', label: this.$t('label.dateTo'), _classes: 'text-uppercase text-center', _style:'min-width: 180px;'},
      { key: 'DeviceName', label: this.$t('label.device'), _style:'min-width: 120px;', _classes: 'text-uppercase text-center'},
      { key: 'TransaLogin', label: this.$t('label.user'), _style:'min-width: 180px;', _classes:"text-center"},
      { key: 'TransaRegDate', label: this.$t('label.registerDate'),  _style:'min-width: 110px; text-align:center;', _classes:"text-center" },
      { key: 'Status', label: this.$t('label.status'),  _style:'min-width: 130px; text-align:center;', _classes:"text-center" },
      
  ];
}

function level2Fields(){
  return [
      {
        key: 'button', 
        label: '', 
        _style: 'min-width: 45px; width: 1%; text-align:center; background-color: #828c9c;', 
        sorter: false, 
        filter: false
      },
      { key: 'Seal',label: this.$t('label.Seals'), _style:'width: 20%; background-color: #828c9c; color: white;', _classes: 'text-uppercase text-center', filter: false, sorter:false,},
      { key: 'SealConditionName', label: this.$t('label.Mode'), _style:'width: 20%; background-color: #828c9c; color: white;', _classes: 'text-uppercase text-center', filter: false, sorter:false,},
      { key: 'SealB', label: this.$t('label.previous'), _style:'width: 25%; background-color: #828c9c; color: white;', _classes: 'text-uppercase text-center', filter: false, sorter:false,},
      { key: 'SealAssignmentName', label: this.$t('label.assignation'), _style:'width: 25%; background-color: #828c9c; color: white;', _classes:"text-center", filter: false, sorter:false,},
      { key: 'ObservationSeal', label: this.$t('label.observation'), _style:'width: 35%; background-color: #828c9c; color: white;', _classes:"text-center", filter: false, sorter:false,},
  ];
}

function fgDesconsolidate() {
  return (
    this.dataBl.YardCargoBlStatus != process.env.VUE_APP_YARD_CARGO_BL_STATUS_UNCONSOLIDATED && 
    process.env.VUE_APP_BL_STATUS_ID_CERTIFICATE == this.dataBl?.BlStatus?.toUpperCase() &&
    ((this.dataBl?.HouseJson?.length > 0) && this.dataBl.FgApplyDeconsolidation))
}

export default {
  name: 'desconsolidated',
  components: {
    CustomTabs,
    CustomTab,
    DesconsolidatedRegister,
    ContainerDesconsolidatedRegister,
    ModalSealImages,
   },
  data,
  mixins: [GeneralMixin],
  methods: {
    getYardCargo,
    viewDetail,
    toggleAdd,
    toggleContainerAdd,
    Update,
    getDesconsolitedList,
    getInventoryInYard,
    getBlHouseData,
    NumberFormat,
    handleTab,
    formatedSeal,
    getBadge,
    getYardBlCargo,
    ModalImagen
  },
  computed: {
    VisitOptions,
    level2Fields,
    fields,
    fgDesconsolidate,
    formatedItems,
    ContainerformatedItems,
    Containerfields,
    ...mapState({
        CompanyBranchId: state => state.auth.branch.CompanyBranchId,
        YardCargoId: state => state.yardManagement.dataBl.YardCargoId,
        yardData: state => state.yardManagement.yardData,
        YardId: state => state.yardManagement.yardData.YardId,
        GeneralList: state => state.yardManagement.GeneralList,
        dropBlMovement: state => state.yardManagement.dropBlMovement,
        dataBl: state => state.yardManagement.dataBl,
    })
  },
  watch: {
    dropBlMovement: async function (val) {
      if(val == 4){
        this.$store.state.yardManagement.loading = true;

        this.showIndex = 0;
        this.isEdit = false;
        await this.getBlHouseData();
        await this.getDesconsolitedList();
        await this.getInventoryInYard();

        this.$store.state.yardManagement.loading = false;
      }
    },
  },
};
</script>
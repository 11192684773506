<template>
  <div>
    <CCol sm="12" lg="4">
      <div class="col-sm-12 col-lg-auto d-flex justify-content-end mb-3">
        <div class="pr-2">{{ $t('label.Partial') }}</div>
        <CSwitch
          color="watch"
          variant="3d"
          type="checkbox"
          :checked.sync="vForm.GeneralData.FgTotal.$model"
          @change.native=""
          :disabled="isEdit"
        />
        <div class="pl-2">{{ $t('label.total') }}</div>
      </div>
    </CCol>
    <CRow class="m-0">
      <CCol sm="12" lg="4">
        <CSelect
          size="sm"
          v-uppercase
          :label="$t('label.Area')"
          :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
          :options="YardAreaOptions"
          addLabelClasses="text-right"
          v-model="vForm.GeneralData.YardAreaId.$model"
          :is-valid="hasError(vForm.GeneralData.YardAreaId)"
          :invalid-feedback="errorMessage(vForm.GeneralData.YardAreaId)"
        />
      </CCol>
      <CCol sm="12" lg="4">
        <div class="form-group form-row align-items-center">
          <label class="col-sm-12 col-lg-4 text-right m-0 required">{{`${$t('label.date')}`}}</label>
          <div class="input-group col-sm-12 col-lg-8">
            <vue-datepicker 
              type="datetime"
              header
              :lang="this.$i18n.locale"
              :editable="false"
              :clearable="false"
              format="DD/MM/YYYY HH:mm"
              placeholder="DD/MM/YYYY HH:mm"
              time-title-format="DD/MM/YYYY HH:mm"
              v-model.trim="vForm.GeneralData.TransactionBegin.$model"
              :disabled-date="validateDateRange"
              :append-to-body="false"
              value-type="format"
              :show-second="false"
            >
              <template #input>
                <CInput
                  v-uppercase
                  class="w-100 float-left m-0"
                  v-model="vForm.GeneralData.TransactionBegin.$model"
                  placeholder="DD/MM/YYYY HH:mm"
                  :is-valid="hasError(vForm.GeneralData.TransactionBegin)"
                  @blur="vForm.GeneralData.TransactionBegin.$touch()"
                  size="sm"                      
                >
                  <template #append-content>
                    <CIcon name="cil-calendar" />
                  </template>
                </CInput>
              </template>
              <template #icon-calendar>
                <div style="display: none"></div>
              </template>
            </vue-datepicker>
          </div>
        </div>
      </CCol>
      <CCol sm="12" lg="4">
        <div class="form-group form-row align-items-center">
          <label class="col-sm-12 col-lg-4 text-right m-0 required">{{`${$t('label.date')}`}}</label>
          <div class="input-group col-sm-12 col-lg-8">
            <vue-datepicker 
              type="datetime"
              header
              :lang="this.$i18n.locale"
              :editable="false"
              :clearable="false"
              format="DD/MM/YYYY HH:mm"
              placeholder="DD/MM/YYYY HH:mm"
              time-title-format="DD/MM/YYYY HH:mm"
              v-model.trim="vForm.GeneralData.TransactionFinish.$model"
              :disabled-date="validateDateRange"
              :append-to-body="false"
              value-type="format"
              :show-second="false"
            >
              <template #input>
                <CInput
                  v-uppercase
                  class="w-100 float-left m-0"
                  v-model="vForm.GeneralData.TransactionFinish.$model"
                  placeholder="DD/MM/YYYY HH:mm"
                  :is-valid="hasError(vForm.GeneralData.TransactionFinish)"
                  @blur="vForm.GeneralData.TransactionFinish.$touch()"
                  size="sm"                      
                >
                  <template #append-content>
                    <CIcon name="cil-calendar" />
                  </template>
                </CInput>
              </template>
              <template #icon-calendar>
                <div style="display: none"></div>
              </template>
            </vue-datepicker>
          </div>
        </div>
      </CCol>
      <CCol sm="12" lg="4">
        <CSelect
          size="sm"
          v-uppercase
          :label="$t('label.motive')"
          :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
          :options="EventReasonOptions"
          addLabelClasses="required text-right"
          v-model="vForm.GeneralData.EventReasonId.$model"
          :is-valid="hasError(vForm.GeneralData.EventReasonId)"
          :invalid-feedback="errorMessage(vForm.GeneralData.EventReasonId)"
        />
      </CCol>
      <CCol sm="12" lg="4">
        <CSelect
          size="sm"
          v-uppercase
          :label="$t('label.machine')"
          :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
          :options="MachineOptions"
          addLabelClasses="required text-right"
          v-model="vForm.GeneralData.MachineId.$model"
          :is-valid="hasError(vForm.GeneralData.MachineId)"
          :invalid-feedback="errorMessage(vForm.GeneralData.MachineId)"
        />
      </CCol>
      <CCol sm="12" lg="4">
        <CTextarea
          size="sm"
          rows="2"
          :label="$t('label.observation')"
          addLabelClasses="text-right"
          :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
          :placeholder="$t('label.observation')"
          v-model="vForm.GeneralData.Observation.$model"
          v-uppercase
          :is-valid="hasError(vForm.GeneralData.Observation)"
          :invalid-feedback="errorMessage(vForm.GeneralData.Observation)"
        />
      </CCol>
    </CRow>
  </div>
</template>
<script>
import ModalMixin from '@/_mixins/modal';
import GeneralMixin from '@/_mixins/general';
import UpperCase from '@/_validations/uppercase-directive';
import { mapState } from 'vuex';
import { Money } from "v-money";
import { DateFormater } from "@/_helpers/funciones";

function data() {
  return {
    YardAreaList: [],
    EventReasonList: [],
    MachineList: [],
    previousDate: '',
    laterDate: '',
  };
}

//-----------------------   Method    ------------------------
async function getService() {
  this.$store.state.yardManagement.loading = true;
  let requests = [
    this.$http.get('YardAreaCustomInspection-list', { YardId: this.yardData.YardId }),
    this.$http.get('YardWEventReason-list', { EventId: process.env.VUE_APP_EVENT_ID_RECOGNITION, TpCargoId: this.dataBl.TpCargoId, YardId: this.yardData.YardId}),
    this.$http.get('MachineYard-list', { CompanyBranchId: this.branch.CompanyBranchId, YardId: this.yardData.YardId }),
  ]
  await Promise.all(requests)
    .then((responses) => {
      this.YardAreaList = responses[0].data.data ?? [];
      this.EventReasonList = responses[1].data.data ?? [];
      this.MachineList = responses[2].data.data ?? [];
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .finally(() => {
      this.$store.state.yardManagement.loading = false;
    });
}

function validateDateRange(date) {
  return date < this.previousDate || date > this.laterDate;
}

function resetForm() {
  this.form.GeneralData = {
    FgTotal: false,
    YardAreaId: '',
    TransactionBegin: '',
    TransactionFinish: '',
    EventReasonId: '',
    MachineId: '',
    Observation: '',
  }
}

//-----------------------   Computed   ------------------------
function YardAreaOptions() {
  var chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
   this.YardAreaList.map(e => {
    chart.push({
      value: e.TpYardAreaId, 
      label: e.YardAreaName,
    })    
  })
  return chart;
}

function EventReasonOptions() {
  let _lang = (this.$i18n.locale)?.charAt(0)?.toUpperCase() + (this.$i18n.locale)?.slice(1);
  var chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
   this.EventReasonList.map(e => {
    chart.push({
      value: e.EventReasonId, 
      label: e[`EventReasonName${_lang}`],
    })    
  })
  return chart;
}

function MachineOptions() {
  var chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
   this.MachineList.map(e => {
    chart.push({
      value: e.MachineId, 
      label: `${e.Cod} - ${e.MachineName}`,
    })    
  })
  return chart;
}

export default {
  name: 'generalData-tab',
  data,
  props: {
    form: {
      type: Object,
      required: true,
      default: () => {},
    },
    vForm: Object,
    Tab: Boolean,
    isEdit: Boolean,
    show: Boolean,
    
  },
  mixins: [
    ModalMixin, 
    GeneralMixin
  ],
  directives: UpperCase,
  methods: {
    getService,
    validateDateRange,
    resetForm,
  },
  computed:{
    YardAreaOptions,
    EventReasonOptions,
    MachineOptions,
    ...mapState({
      branch: state => state.auth.branch,
      yardData: state => state.yardManagement.yardData,
      dataBl: state => state.yardManagement.dataBl,
    })
  },
  watch: {
    show: async function (NewVal) {
      if (!NewVal) {
       this.resetForm();
      }
    },
    Tab: async function (NewVal) {
      if (NewVal) {
        await this.getService()
      }
    },
  }
};
</script>
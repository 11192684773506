<template>
  <CRow class="justify-content-center m-0 mt-3">
    <CCol sm="12">
      <dataTableExtended
        class="align-center-row-datatable"
        :items="computedList"
        :fields="fields"
        :items-per-page="5"
        column-filter
        pagination
        :table-filter="tableText.tableFilterText"
        :items-per-page-select="tableText.itemsPerPageText"
        :noItemsView="tableText.noItemsViewText"
        sorter
      >
        <template #TransactionDate="{ item }">
          <td class="text-center">
            {{ formatDateTimeWithSlash(item.TransactionDate) }}
          </td>
        </template>
        <template #QuantityReceived="{ item }">
          <td class="text-center">
            {{ formatNumber(item.QuantityReceived, 2) }}
          </td>
        </template>
        <template #WeigthReception="{ item }">
          <td class="text-center">
            {{ formatNumber(item.WeigthReception, 2) }}
          </td>
        </template>
        <template #VolumenReception="{ item }">
          <td class="text-center">
            {{ formatNumber(item.VolumenReception, 2) }}
          </td>
        </template>
        <template #TransactionDateCertification="{ item }">
          <td class="text-center">
            {{ formatDateTimeWithSlash(item.TransactionDateCertification) }}
          </td>
        </template>
        <template #YardDocumentStatus="{item}">
          <td class='text-center'>
            <CBadge :color="getBadge(item.YardDocumentStatus)">
                {{ item.YardDocumentStatus }}
            </CBadge>
          </td>
        </template>
      </dataTableExtended>
    </CCol>
  </CRow>
</template>
<script>
import General from '@/_mixins/general';
import { DateFormater, NumberFormater } from '@/_helpers/funciones';

//data
function data() {
  return {
  };
}

//methods
function formatNumber(number, decimal) {
  return NumberFormater.formatNumber(number, decimal);
}

function formatDateTimeWithSlash(date) {
  return date ? DateFormater.formatDateTimeWithSlash(date) : 'N/A';
}

//computeds
function computedList() {
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  return this.certificateEntryList?.map((item, index) => {
    return {
      ...item,
      Nro: index+1,
      YardDocumentStatus: item[`YardDocumentStatus${_lang}`] ?? '',
      Quantity: item?.YardBlDetailJson?.[0]?.Quantity ?? 0,
      TransaLoginCertificate: item.TransaLoginCertificate ?? 'N/A',
      _style: `background: ${item?.YardDocumentStatusColor}`,
      _classes: 'color-gradient',
    };
  });
}

function fields(){
  return [
    { label: '#', key: 'Nro', _classes: 'text-center', _style: 'width:5%;', filter: false},
    { label: this.$t('label.CertificateNro'), key: 'DocumentCode', _classes: 'text-uppercase text-center' , _style:'min-width: 230px;'},
    { label: this.$t('label.CertificateDate'), key: 'TransactionDate', _classes: 'text-uppercase text-center', _style:'min-width: 190px;'},
    { label: this.$t('label.yard'), key: 'UbicationName',_classes: 'text-uppercase text-center',  _style: 'min-width: 190px;'},
    { label: this.$t('label.quantity'), key: 'QuantityReceived',  _classes: 'text-uppercase text-center', _style: 'min-width: 190px;', },
    { label: `${this.$t('label.weight')} (KGM)`, key: 'WeigthReception',  _classes: 'text-uppercase text-center',  _style:'min-width: 150px;'},
    { label: `${this.$t('label.volume')} (M³)`, key: 'VolumenReception', _classes: 'text-uppercase text-center', _style:'min-width: 150px;'},
    { label: this.$t('label.status'), key: 'YardDocumentStatus', _classes: 'text-uppercase text-center',  _style: 'min-width: 190px;' },
    { label: this.$t('label.CertificationDocumentDate'), key: 'TransactionDateCertification',_style: 'min-width: 190px;',_classes: 'text-uppercase text-center'},
    { label: this.$t('label.UserCertifies'), key: 'TransaLoginCertificate', _classes: 'text-uppercase text-center', _style:'min-width: 190px;' },
    
  ];
}

export default{
  name: 'CertificateEntry',
  data,
  mixins: [General],
  props: {
    certificateEntryList: {
      type: Array,
      default: () => [],
    }
  },
  methods: {
    formatNumber,
    formatDateTimeWithSlash,
  },
  computed: {
    computedList,
    fields,
  },
}
</script>
<template>
  <div>
    <CModalExtended
      color="dark"
      :show.sync="showModal"
      :closeOnBackdrop="false"
      size="lg"
    >
      <template #header>
        <h5 class="modal-title">{{titleModal}}</h5>
        <button type="button" aria-label="Close" class="close" @click="closeModal">×</button>
      </template>
     
          <CRow class="justify-content-center mt-2">
            <CCol sm="4" lg="4" class="mb-3" v-if="!isInfo">
              <div class="preview">
                <picture-input
                  v-if="showModal"
                  ref="imageInput"
                  width="200"
                  height="200"
                  accept="image/jpeg,image/png"
                  size="10"
                  :custom-strings="imgInputTexts"
                  @change="handleFileUpload"
                  :imgSrc="Route"
                >
                </picture-input>
              </div>
              <div class="text-invalid-feedback text-center" v-if="$v.Imagen.$error">
                {{$t('label.badImage')}}
              </div>
            </CCol>
            <CCol sm="12" lg="auto" class="mb-3 row align-items-end justify-content-end" v-if="!isInfo">
              <CButton
                  color="add"
                  @click.stop="submitImage()"
                  :disabled="isSubmit"
                >
                  <CIcon name="checkAlt"/>
                </CButton>
            </CCol>
            <CCol sm="12">
              <dataTableExtended
                class="align-center-row-datatable"
                hover
                sorter
                :items="formatedItems"
                :fields="fields"
                :items-per-page="5"
                :noItemsView="tableText.noItemsViewText"
                pagination
                column-filter
              >
                <template #Imagen="{item}">
                  <td class="center-cell text-uppercase">
                    <img
                      :src="srcImage(item.Imagen)"
                      style="height: 32px !important; width: 32px !important;"
                      @error="'/img/errorimage.jpg'"
                    />
                  </td>
                </template>
                <template #Options="{item}">
                  <td class="center-cell">
                    <CButton
                      v-if="!isInfo"
                      color="wipe"
                      size="sm"
                      class="mr-1"
                      @click="DeleteImage(item)"
                      v-c-tooltip="{
                        content: $t('label.delete')+' '+$t('label.picture'),
                        placement: 'top-end'
                      }"
                    >
                      <CIcon name="cil-trash"/>
                    </CButton>
                  </td>
                </template>
              </dataTableExtended>
            </CCol>
          </CRow>
      <template #footer>
        <CButton
          color="wipe"
          :disabled="isSubmit"
          class="d-flex align-items-center"
          @click.stop="closeModal"
        >
          <CIcon name="x"/><span class="ml-1">{{ $t('button.exit')}}</span>
        </CButton>
      </template>
    </CModalExtended>
  </div>
</template>
<script>
import ImagesListValidations  from '@/_validations/yard-management/container/ImagesListValidations';
import GeneralMixin from '@/_mixins/general';
import PictureInput from '@/components/picture-input';
import { DateFormater } from '@/_helpers/funciones';
import DataStorage from '@/_helpers/localStorage';
import moment from 'moment';

function data() {
  return {
    showModal: false,
    Imagen: '',
    Route: '',
    RouteImage: [],
    isSubmit: false,
  };
}

function submitImage() {
  try {
    this.$store.state.yardManagement.loading = true;
    this.isSubmit = true;
    this.$v.$touch();
    if (this.$v.$error || this.RouteImage.length == 4) {
      throw this.$t('label.errorsPleaseCheck');
    }
    this.$http.file('YardManagement-file', this.Imagen)
    .then((response) => {
      this.$v.$reset();
      this.$refs.imageInput.removeImage();
      this.$refs.imageInput.value = null;
      this.$refs.imageInput.file = null;
      this.Imagen = '';
      this.RouteImage.push({
        DocumentRoute: response.data.data.files[0].path,
        Date: moment(new Date).format('DD/MM/YYYY HH:mm'),
      });
      this.isSubmit = false;
      this.$store.state.yardManagement.loading = false;
    }).catch((err) => {
      this.$store.state.yardManagement.loading = false;
      this.isSubmit = false;
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    }); 
  } catch (e) {
    this.$store.state.yardManagement.loading = false;
    this.isSubmit = false;
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: e,
      type: "error"
    });
  }
}

function handleFileUpload() {
  this.Imagen = this.$refs.imageInput.file;
}

function DeleteImage(item) {
  this.$swal
      .fire(this.alertProperties({
          text: `${this.$t('label.deleteFile')}`,
      }))
    .then((result) => {
    if (result.isConfirmed) {
      this.$store.state.yardManagement.loading = true;
      this.$http.delete('files', item.DocumentRoute.replace(/public+[/]/i, ''))
        .then(async(response) => {
          if (this.isEdit && item.YardCargoEventDetailDocumentId) {
            let UpdateImages = this.RouteImage.filter(RouteImage => RouteImage.YardCargoEventDetailDocumentId 
              && RouteImage.DocumentRoute != item.DocumentRoute);
            await this.YardCargoDispatch(item, UpdateImages);
          }
          this.RouteImage = this.RouteImage.filter(RouteImage => RouteImage.DocumentRoute != item.DocumentRoute);
          this.$notify({
            group: 'container',
            title: '¡'+this.$t('label.success')+'!',
            text: response.data.data.info,
            type: "success"
          });
        })        
        .finally(() => {
          this.$store.state.yardManagement.loading = false;
        });     
    }
  });
}

async function YardCargoDispatch(item, RouteImage) {
  if (this.DocumentType === 1) {
    this.CargoJson.EirDocumentJson = this.CargoJson.EirDocumentJson.map(Document => {
      return{
        YardCargoEventDetailDocumentId: Document.YardCargoEventDetailDocumentId ?? '',
        DocumentRoute: Document.DocumentRoute ?? '',
        FgActYardCargoEventDetailDoc: Document.DocumentRoute == item.DocumentRoute ? 0 : 1,
        Status: Document.DocumentRoute == item.DocumentRoute ? 0 : 1,
      } 
    });
  }else{
    this.CargoJson.OutPassDocumentJson = this.CargoJson.OutPassDocumentJson.map(Document => {
      return{
        YardCargoEventDetailDocumentId: Document.YardCargoEventDetailDocumentId ?? '',
        DocumentRoute: Document.DocumentRoute ?? '',
        FgActYardCargoEventDetailDoc: Document.DocumentRoute == item.DocumentRoute ? 0 : 1,
        Status: Document.DocumentRoute == item.DocumentRoute ? 0 : 1,
      } 
    });
  }
  await this.$http.put( 'YardCargoDispatch-update', this.CargoJson, { root: 'CargoJson' })
    .then(async (response) => {
      if (this.DocumentType === 1){
        this.CargoJson.EirDocumentJson = RouteImage;
      }else{
        this.CargoJson.OutPassDocumentJson = RouteImage;
      }
      await this.$emit('UpdateDispatch');
    }).catch((err) => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
}

function closeModal() {
  this.$emit('UpdateImages', this.RouteImage);
  this.refreshComponent();
  this.$emit('CloseModal');
}

function srcImage(icon){
  if(icon){
    return icon;
  }else{
    return '/img/errorimage.jpg';
  }
}

function refreshComponent() {
  this.Imagen = '';
  this.RouteImage = [];
  this.$v.$reset();
}

//computed
function formatedItems() {
  return this.RouteImage.map((item, index) => {
    return {
      ...item,
      Nro: index+1,
      Imagen: `${this.$store.getters['connection/getBase']}${item.DocumentRoute.replace(/public+[/]/i, '')}`,
      TransaLogin: item.TransaLogin ?? DataStorage.getUser().Login,
      FormatedDate: item.TransaRegDate ? DateFormater.formatDateTimeWithSlash(item.TransaRegDate) : item.Date,
    }
  })
}

function fields(){ 
  return [
    { key: 'Nro', label: '#',_style: 'width:5%; text-align:center;', filter: false},
    { key: 'Imagen', label: this.$t('label.image'), _style: 'width: 50%;', sorter: false,  filter: false, _classes:'text-center'},
    { key: 'TransaLogin', label: this.$t('label.user'), _style: 'width: 20%;', _classes:'text-center'},
    { key: 'FormatedDate', label: this.$t('label.date'),_style: 'width: 20%;', _classes:"text-center"},
    { key: 'Options',  label: '', _style: 'width:1%; min-width: 45px',  sorter: false,  filter: false, _classes:'text-center' }
  ]
}


export default {
  name: 'modal-seal',
  components: {
    PictureInput,
  },
  data,
  props: {
    modal: null,
    ImagesJson: Array,
    CargoJson: Object,
    DocumentType: Number,
    isEdit: Boolean,
    isInfo: {
        type: Boolean,
        required: false,
        default: () => false,
    },
    titleModal: String,
  },
  mixins: [GeneralMixin],
  validations: ImagesListValidations,
  methods: {
    submitImage,
    handleFileUpload,
    DeleteImage,
    YardCargoDispatch,
    closeModal,
    srcImage,
    refreshComponent,
  },
  computed: {
    formatedItems,
    fields,
  },
  watch: {
    modal: async function (val) {
      this.showModal = val;
      if (val) {
        let _lang = this.$i18n.locale;
        this.Route = _lang=='en' ? '/img/uploadPhotoEn.svg' : '/img/uploadPhotoEs.svg';
        this.RouteImage = this.ImagesJson.length!=0 ? this.ImagesJson : [];
      }
    },
  }
};
</script>
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-3"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showIndex==0),expression:"showIndex==0"}]},[_c('CRow',{staticClass:"mb-3"},[_c('CCol',{staticClass:"d-flex align-items-center justify-content-start",attrs:{"sm":"12","lg":"6","xl":"6"}},[_c('h6',{staticClass:"mt-2"},[_c('CIcon',{staticClass:"titulo-icono",attrs:{"name":"cil-list","size":"lg"}}),_vm._v(" "+_vm._s(`${this.$t('label.receiptList')}`)+" ")],1)]),_c('CCol',{staticClass:"d-flex align-items-center justify-content-end",attrs:{"sm":"12","lg":"6","xl":"6"}},[_c('CButton',{staticClass:"mr-1",attrs:{"color":"excel","size":"sm"},on:{"click":function($event){return _vm.onBtnExport(true)}}},[_c('CIcon',{attrs:{"name":"cil-file-excel"}}),_vm._v("  XSLX ")],1)],1)],1),_c('CRow',{staticClass:"mb-3"},[_c('CCol',{attrs:{"sm":"12"}},[_c('CTabs',{attrs:{"variant":"tabs","active-tab":_vm.tabIndex},on:{"update:activeTab":_vm.handleTab}},[_c('CTab',{attrs:{"title":_vm.$t('label.packaging')}},[_c('CRow',{staticClass:"mt-2"},[_c('CCol',{attrs:{"sm":"12"}},[_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"items":_vm.computedListReceptionBl,"fields":_vm.blFields,"items-per-page":5,"column-filter":"","pagination":"","table-filter":_vm.tableText.tableFilterText,"items-per-page-select":_vm.tableText.itemsPerPageText,"noItemsView":_vm.tableText.noItemsViewText,"sorter":""},scopedSlots:_vm._u([{key:"Status",fn:function({item}){return [_c('td',{staticClass:"text-center"},[_c('CBadge',{attrs:{"color":_vm.getBadge(item.Status)}},[_vm._v(" "+_vm._s(_vm.$t('label.'+item.Status))+" ")])],1)]}},{key:"options",fn:function({ item }){return [_c('td',{staticClass:"text-center"},[(item.Status != 'INACTIVO' && item.Status != 'INACTIVE' && ((!_vm.fgCertificate && _vm.YardId) ||  !_vm.YardId))?_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                                                        content: _vm.$t('label.reception'),
                                                        placement: 'top-start'
                                                    }),expression:"{\n                                                        content: $t('label.reception'),\n                                                        placement: 'top-start'\n                                                    }"}],staticClass:"mr-1",attrs:{"square":"","size":"sm","color":"edit"},on:{"click":function($event){return _vm.toggleAdd(item, false)}}},[_c('CIcon',{attrs:{"name":"pencil"}})],1):_vm._e(),_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                                                        content: _vm.$t('label.OrderOfEntry'),
                                                        placement: 'top-start'
                                                    }),expression:"{\n                                                        content: $t('label.OrderOfEntry'),\n                                                        placement: 'top-start'\n                                                    }"}],attrs:{"size":"sm","square":"","color":"danger"},on:{"click":function($event){return _vm.togglePdf(item)}}},[_c('CIcon',{attrs:{"name":"cil-file-pdf"}})],1)],1)]}},{key:"Weight",fn:function({ item }){return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.NumberFormat(item.Weight, 2))+" ")])]}}])})],1)],1)],1),_c('CTab',{attrs:{"title":_vm.$t('label.Containers')}},[_c('CRow',{staticClass:"mt-2"},[_c('CCol',{attrs:{"sm":"12"}},[_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"items":_vm.computedContainerListContainer,"fields":_vm.Containerfields,"items-per-page":5,"column-filter":"","pagination":"","table-filter":_vm.tableText.tableFilterText,"items-per-page-select":_vm.tableText.itemsPerPageText,"noItemsView":_vm.tableText.noItemsViewText,"sorter":""},scopedSlots:_vm._u([{key:"Status",fn:function({item}){return [_c('td',{staticClass:"text-center"},[_c('CBadge',{attrs:{"color":_vm.getBadge(item.Status)}},[_vm._v(" "+_vm._s(_vm.$t('label.'+item.Status))+" ")])],1)]}},{key:"options",fn:function({ item }){return [_c('td',{staticClass:"text-center"},[(item.Status != 'INACTIVO' && item.Status != 'INACTIVE' && ((!_vm.fgCertificate && _vm.YardId) ||  !_vm.YardId))?_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                                                        content: _vm.$t('label.reception'),
                                                        placement: 'top-start'
                                                    }),expression:"{\n                                                        content: $t('label.reception'),\n                                                        placement: 'top-start'\n                                                    }"}],staticClass:"mr-1",attrs:{"square":"","size":"sm","color":"edit"},on:{"click":function($event){return _vm.toggleAdd(item, true)}}},[_c('CIcon',{attrs:{"name":"pencil"}})],1):_vm._e(),_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                                                        content: _vm.$t('label.OrderOfEntry'),
                                                        placement: 'top-start'
                                                    }),expression:"{\n                                                        content: $t('label.OrderOfEntry'),\n                                                        placement: 'top-start'\n                                                    }"}],attrs:{"size":"sm","square":"","color":"danger"},on:{"click":function($event){return _vm.togglePdf(item)}}},[_c('CIcon',{attrs:{"name":"cil-file-pdf"}})],1)],1)]}},{key:"Weight",fn:function({ item }){return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.NumberFormat(item.Weight, 2))+" ")])]}}])})],1)],1)],1)],1)],1)],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showIndex==1),expression:"showIndex==1"}]},[_c('BlRecepcion',{directives:[{name:"show",rawName:"v-show",value:(!this.isInternalTranfer),expression:"!this.isInternalTranfer"}],attrs:{"Active":_vm.showIndex==1 && !this.isInternalTranfer,"ContainerItem":_vm.ContainerItem,"isEdit":true},on:{"Close":function($event){_vm.showIndex=0, _vm.ContainerItem={}},"Update-list":_vm.Update}}),_c('BlInternalRecepcion',{directives:[{name:"show",rawName:"v-show",value:(this.isInternalTranfer),expression:"this.isInternalTranfer"}],attrs:{"Active":_vm.showIndex==1 && this.isInternalTranfer,"FgInternalTransfer":true,"isEdit":true,"ContainerItem":_vm.ContainerItem,"isOfContainer":false},on:{"Close":function($event){_vm.showIndex = 0, _vm.ContainerItem={}, _vm.isInternalTranfer = false, _vm.isEdit=false},"Update-list":_vm.Update}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }